import './App.css';
import {BrowserRouter,Route, Routes} from 'react-router-dom'
import React from 'react';

import Home from './pages/Home';
import NotFound404 from './pages/404';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import UserPendingActivationSetup from './pages/UserPendingActivation';
import Welcome from './pages/Welcome';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import UnAuthenticated from './pages/users/UnAuthenticated';
import RequireAuthRoutes from "./services/RequireAuthRoutes";
import PersistentLogin from './services/PersistentLogin';
import StudentUniversities from './pages/students/pages/Student_Universities';
import VisitorPage from './pages/VisitorPage';
import StudentUccEvents from './pages/students/pages/Student_UCCEvents';
import StudentInvitations from './pages/students/pages/Student_Invitations';

import CounselorUniversities from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_AllUniversities';

import SchoolAllStudentsAll from './pages/schoolGeneral/schoolMainPages/School_All_StudentsAll'

import StudentFavorites from './pages/students/pages/Student_Favorite';
import ForgotPassword from './pages/ForgotPassword';

import StudentHomeProfile from './pages/students/pages/Student_Home_Profile';

import SuperAdmin_Settings from './pages/admin/pages/mainPages/SuperAdmin_Settings';
import SuperAdmin_Home from './pages/admin/pages/mainPages/SuperAdmin_Home';
import SuperAdmin_AllSchools from './pages/admin/pages/mainPages/SuperAdmin_AllSchools';
import SuperAdmin_AllStudents from './pages/admin/pages/mainPages/SuperAdmin_AllStudents';
import SuperAdmin_AllUniversities from './pages/admin/pages/mainPages/SuperAdmin_AllUniversities'
import SuperAdmin_AllEvents from './pages/admin/pages/mainPages/SuperAdmin_AllEvents';
import SuperAdmin_AllUsers from './pages/admin/pages/mainPages/SuperAdmin_AllUsers';
import SuperAdmin_StudentProfile from './pages/admin/pages/subPages/SuperAdmin_StudentProfile';

import SuperAdmin_AllSchoolAdmin from './pages/admin/pages/mainPages/SuperAdmin_AllSchoolAdmin';
import SuperAdmin_SchoolProfile from './pages/admin/pages/subPages/SuperAdmin_SchoolProfile';
import SuperAdmin_ManageSubscription from './pages/admin/pages/mainPages/SuperAdmin_ManageSubscription';

import SuperAdminPendingTeachers from './pages/admin/pages/mainPages/SuperAdmin_PendingTeachers';
import SuperAdmin_UniversityDetails from './pages/admin/pages/subPages/SuperAdmin_UniversityDetails';
import UserDetailPage from './pages/admin/adminComponents/UserDetailPage';
import SuperAdmin_TeacherProfile from './pages/admin/pages/subPages/SuperAdmin_TeacherProfile';

import SchoolAllStudentProfile from './pages/schoolGeneral/schoolMainPages/School_All_StudentProfile';

import CounselorEventDetails from './pages/schoolGeneral/counselor/pages/Event/CounselorEvent_EventDetails';
import CounselorUniversityDetails from './pages/schoolGeneral/counselor/pages/mainPages/subPages/Counselor_UniversityDetails';

import SchoolAllStudentsByGrade from './pages/schoolGeneral/schoolMainPages/School_All_StudentsByGrade';

import CounselorMySchool from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_MySchool';

import CounselorCreateEvent from './pages/schoolGeneral/counselor/pages/CreateEvent/Counselor_CreateEvent';
import CounselorCalendar from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_Calendar';
import CounselorProfile from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_Profile';

import UserProfile from './pages/UserProfile';
import StudentAccess from './services/StudentAccess';
import CounselorAccess from './services/CounselorAccess';
import SuperAdminAccess from './services/SuperAdminAccess';
import SchoolAdminAccess from './services/SchoolAdminAccess';

import StudentEventDetails from './pages/students/pages/subpages/Student_UccEventDetails';
import StudentUniversityDetails from './pages/students/pages/subpages/Student_UniversityDetails';
import StudentCalendar from './pages/students/pages/Student_Calendar';
import { NewUniFormProvider } from './pages/admin/adminComponents/createUniversityForm/providers/CreateUniversityContext';
import RequiredFieldsProvider from './pages/admin/adminComponents/createUniversityForm/providers/RequiredFieldsContext';
import StaffActivationBuffer from './pages/StaffActivationBuffer';
import StudentActivationBuffer from './pages/StudentActivationBuffer';
import ActivationCheck from './services/ActivationCheck';

import EventFormProvider from './pages/schoolGeneral/counselor/pages/CreateEvent/providers/EventForm_Context';
import EventFormValidationProvider from './pages/schoolGeneral/counselor/pages/CreateEvent/providers/EventFormValidation_Context';
import SchoolNewEventFormProvider from './pages/schoolGeneral/School_All_CreateEvent/providers/SchoolEventFormContext';
import SchoolNewEventFormValidationProvider from './pages/schoolGeneral/School_All_CreateEvent/providers/SchoolEventFormValidationContext';

import CounselorEditEvent from './pages/schoolGeneral/counselor/pages/EditEvent/Counselor_EditEvent';
import EditEventFormProvider from './pages/schoolGeneral/counselor/pages/EditEvent/providers/EditEvent_FormContext';
import EditEventFormValidationProvider from './pages/schoolGeneral/counselor/pages/EditEvent/providers/EditEvent_FormValidationContext';
import CounselorEvents from './pages/schoolGeneral/counselor/pages/mainPages/Counselor_Events';
import AdministratorOnboardStudents from './pages/schoolGeneral/administrator/pages/Administrator_OnboardStudents';
import AdministratorArchivedStudents from './pages/schoolGeneral/administrator/pages/Administrator_ArchivedStudents';
import AdministratorStudentProfileArchived from './pages/schoolGeneral/administrator/pages/Administrator_StudentProfile_Archived';

import SuperAdmin_CreateSchool from './pages/admin/pages/mainPages/SuperAdmin_CreateSchool';

import SuperAdmin_ManageSchoolAdmin from './pages/admin/pages/subPages/SuperAdmin_ManageSchoolAdmin';
import SchoolStaffAccess from './services/SchoolStaffAccess';
import AdministratorArchive from './pages/schoolGeneral/administrator/pages/Administrator_Archive';
import SchoolAllCalendar from './pages/schoolGeneral/schoolMainPages/School_All_Calendar';
import AdministratorStaffProfile from './pages/schoolGeneral/administrator/pages/Administrator_StaffProfile';
import AdministratorStaff from './pages/schoolGeneral/administrator/pages/Administrator_Staff';
import Admin_ManageSchoolStudents from './pages/admin/pages/subPages/SuperAdmin_ManageSchoolStudents';
import UserArchiveWarning from './pages/UserArchivedWarning';
import ArchiveCheck from './services/ArchiveCheck';
import SchoolAllMySchool from './pages/schoolGeneral/schoolMainPages/School_All_MySchool';
import SchoolAllEvents from './pages/schoolGeneral/schoolMainPages/School_All_Events';
import AdministratorOnboardSuccess from './pages/schoolGeneral/administrator/pages/Administrator_OnboardSuccess';
import Subscription from './pages/schoolGeneral/administrator/pages/subpages/Subscription';
import SchoolAllEventDetails from './pages/schoolGeneral/schoolMainPages/School_All_EventDetails';
import AdministratorSettings from './pages/schoolGeneral/administrator/pages/Administrator_Settings';
import SchoolAllContactUs from './pages/schoolGeneral/schoolMainPages/School_All_ContactUs';
import SchoolAllCreateEvent from './pages/schoolGeneral/School_All_CreateEvent/School_All_CreateEvent';
import ChangePassword from './pages/ChangePassword';
import ResetPassword from './pages/ResetPassword';
import SchoolEditEventFormProvider from './pages/schoolGeneral/School_All_EditEvent/providers/SchoolEditEventFormContext';
import SchoolAllEditEvent from './pages/schoolGeneral/School_All_EditEvent/School_All_EditEvent';
import Student_SchoolEvents from './pages/students/pages/Student_SchoolEvents';
import Student_SchoolEventDetails from './pages/students/pages/subpages/Student_SchoolEventDetails';

import StudentProfile from './pages/students/pages/subpages/Student_Profile';
import SuperAdmin_EventDetails from './pages/admin/pages/mainPages/SuperAdmin_EventDetails';
import SubscriptionCheck from './services/SubscriptionCheck';
import SuperAdmin_Contact from './pages/admin/pages/mainPages/SuperAdmin_Contact';
import SchoolAllStaffProfile from './pages/schoolGeneral/schoolMainPages/School_All_StaffProfile';
import About from './pages/About';
import CoppaTerms from './pages/CoppaTerms';

function App() {

  return (
    <BrowserRouter>
      <Routes>
      {/* public routes */}
        <Route path='login' element={<Login/>}/>
        <Route path='/privacy-policies' element={<PrivacyPolicy/>} />
        <Route path='/terms-of-service' element={<TermsOfService/>} />
        <Route path='coppa-disclosure' element={<CoppaTerms/>} />

        <Route path='about' element={<About/>} />
        <Route path='signup' element={<SignUp/>}/>
        <Route path="unauthenticated" element={<UnAuthenticated />} />
        <Route path='forgotPassword' element={<ForgotPassword/>} />
        <Route path='resetpassword/:resetToken' element={<ResetPassword/>} />
        <Route path='activate/setup' element={<UserPendingActivationSetup />} />
    {/* public routes */}
        <Route path='activate/staff/:activationToken' element={<StaffActivationBuffer />} />
        <Route path='activate/student/:activationToken' element={<StudentActivationBuffer />} />
    {/* Protected Routes */}
        <Route element={<PersistentLogin/>} >
          <Route element={<RequireAuthRoutes />}>
    {/* main home regardless role */}
            <Route path='/' element={<Home/>}>
              <Route path='welcome' element={<Welcome/>} />
            </Route>
    {/* User Routes */}
            <Route path='/' >
    {/* / counselor routes */}
            <Route path='user/change-password' element={<ChangePassword />} />
            <Route element={<SchoolStaffAccess/>}>
              <Route element={<ActivationCheck />} >
                  <Route element={<ArchiveCheck />} >
                    <Route path='school' >
    {/*----------------general access to all staff members  ---------------*/}
                      <Route path='staff/profile' element={<SchoolAllStaffProfile />} />
                      <Route element={<SubscriptionCheck />} >
                        <Route path='students' element ={<SchoolAllStudentsAll/>} />
                        <Route path='students/grade/:gradelevel' element={<SchoolAllStudentsByGrade />} />
                        <Route path='students/:studentId' element={<SchoolAllStudentProfile />} />
                        <Route path='calendar' element={<SchoolAllCalendar />} />
                        
                        <Route path='events' element={<SchoolAllEvents/>} />
                        <Route path='events/:eventId' element={<SchoolAllEventDetails/>} />
                        <Route path='events/new' element={
                            <SchoolNewEventFormProvider>
                                <SchoolNewEventFormValidationProvider>
                                      <SchoolAllCreateEvent/>
                                </SchoolNewEventFormValidationProvider>
                            </SchoolNewEventFormProvider>
                          } />
                        <Route path= 'events/:eventId/edit' element={
                          <SchoolEditEventFormProvider>
                            <SchoolAllEditEvent/>
                          </SchoolEditEventFormProvider>
                        } />
                      </Route>
                      <Route path='contact' element={<SchoolAllContactUs />} />

                      {/* --------------------------- School admin access --------------------------- */}
                      <Route element={<SchoolAdminAccess />} >
                        <Route path='administrator' >
                          <Route element={<SubscriptionCheck />} >
                            <Route path='students/onboard' element={<AdministratorOnboardStudents/>} />
                            <Route path='students/onboard/success' element={<AdministratorOnboardSuccess />} />
                            <Route path='students/:studentId/archived' element={<AdministratorStudentProfileArchived/>} />
                            <Route path='students/archived' element={<AdministratorArchivedStudents/>} />
                            <Route path='staff' element={ <AdministratorStaff /> } />
                            <Route path='staff/:staffId' element={<AdministratorStaffProfile />}/>
                            <Route path='archive' element={<AdministratorArchive />} />
                            <Route path='settings' element={<AdministratorSettings/>} />
                          </Route>
                          <Route path='myschool' element={<SchoolAllMySchool />} />
                          <Route path='home' element={<SchoolAllMySchool />} />
                          <Route path='subscription' element={<Subscription />} />
                        </Route>
                      </Route>

     {/* ------------------ counselor role access -------------------  */}
                      <Route element={<CounselorAccess />} >
                      <Route element={<SubscriptionCheck />} >
                        <Route path='counselor' >  
                        {/* counselor route start /counselor/ */}
                          <Route path='profile' element={<CounselorProfile />} />
                          <Route path='universities' element ={<CounselorUniversities/>}/>
                          <Route path='universities/:universityId' element={<CounselorUniversityDetails/>} />
                          <Route path='events' element={<CounselorEvents/>}/>
                          <Route path='events/:eventId' element={<CounselorEventDetails/>} />
                          <Route path='events/:eventId/edit' element={
                            <EditEventFormProvider>
                              <EditEventFormValidationProvider>
                                <CounselorEditEvent/>
                              </EditEventFormValidationProvider>
                            </EditEventFormProvider>
                          } />
                          <Route path='new-event' element ={
                            <EventFormProvider>
                              <EventFormValidationProvider>
                                <CounselorCreateEvent/> 
                              </EventFormValidationProvider>
                            </EventFormProvider>
                          }/>
                          <Route path='calendar' element={<CounselorCalendar />} />
                        </Route>      {/* counselor access ends */}
                      </Route>
                      </Route>        {/* counselor access check ends */}
                    </Route>      {/* school access checkends */}
                  </Route>       {/* Archive check ends */}
                     {/* subscription check ends */}
              </Route>      {/* Activation check ends */}
            </Route>      {/* School staff access ends */}

 {/*---------------- / Student routes  ------------------*/}
            <Route element={<StudentAccess />}>
              <Route element={<ActivationCheck />} >
                <Route element={<ArchiveCheck />} >
                  <Route element={<SubscriptionCheck />} >
                    <Route path='student' >
                      <Route path='contact' element={<SchoolAllContactUs />} />
                    {/* need to add a role based access */}
                      <Route path='profile' element={<StudentProfile />} />
                      <Route path='profile' element={<StudentProfile/>}/>
                      <Route path='universities' element={<StudentUniversities/>}/>
                      <Route path='universities/:universityId' element={<StudentUniversityDetails />} />
                      <Route path='school-events' element={<Student_SchoolEvents />} />
                      <Route path='school-events/:eventId' element={<Student_SchoolEventDetails />} />
                      <Route path='ucc-events' element={<StudentUccEvents />} />
                      <Route path='ucc-events/:eventId' element={<StudentEventDetails />}/>
                      <Route path='calendar' element={<StudentCalendar />} />
                      <Route path='invitations' element={<StudentInvitations />} />
                      <Route path='favorites' element ={<StudentFavorites/>} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
                  {/* / Visitor routes */}
            <Route path='visitor' element={<VisitorPage/>}/>
            </Route>
  {/* ------------------  superAdmin Routes ------------------------ */}
            <Route element={<SuperAdminAccess />} >
              <Route path='admin' > 
                  {/* need to add a role based access */}
                <Route index element={<SuperAdmin_Home/>} />
                <Route path='schools' element ={<SuperAdmin_AllSchools />} />
                <Route path='schools/new' element={<SuperAdmin_CreateSchool/>} />
                <Route path='schools/:schoolId/details' element = {<SuperAdmin_SchoolProfile />} />
                <Route path='schools/:schoolId/manage-admin' element={<SuperAdmin_ManageSchoolAdmin/>}/> 
                <Route path='schools/:schoolId/manage-subscription' element={<SuperAdmin_ManageSubscription />} />
                <Route path="schools/:schoolId/manage-students" element={<Admin_ManageSchoolStudents />} />
                <Route path='students' element ={<SuperAdmin_AllStudents />} />
                <Route path='students/:studentId' element={<SuperAdmin_StudentProfile />} />
                <Route path='universities' element = {<SuperAdmin_AllUniversities />} />

                <Route path='universities/:universityId' element={<SuperAdmin_UniversityDetails />} />
                <Route path='administrators' element ={<SuperAdmin_AllSchoolAdmin />} />
                <Route path='teachers/:userId' element={<SuperAdmin_TeacherProfile />} />
                <Route path='events' element = {<SuperAdmin_AllEvents />} />
                <Route path='events/:eventId' element={<SuperAdmin_EventDetails />} />
                <Route path='users' element = {<SuperAdmin_AllUsers/>} />
                <Route path='users/pending' element={<SuperAdminPendingTeachers/>} />
                <Route path='users/:userId' element={<UserDetailPage/>} />
                <Route path='settings' element={<SuperAdmin_Settings/>} />
                <Route path='contact' element={<SuperAdmin_Contact />} />
              </Route>
            </Route>
              
          </Route> 
          
          <Route path='/*' element={<NotFound404/>}/>
        </Route>
      </Routes>

      {/* Protected Routes end */}
    </BrowserRouter>
  );
}

export default App;
