import React,{ createContext, useContext, useReducer, useState } from "react";

import { SchoolEditEventFormContext } from "./SchoolEditEventFormContext";

export const SchoolEditEventFormValidationContext = createContext(null);
export const SchoolEditEventFormValidationDispatchContext = createContext(null);

export default function SchoolEditEventFormValidationProvider({children}) {

    const eventForm = useContext(SchoolEditEventFormContext);

    const [requiredFieldsState, dispatch] = useReducer((state,action)=> eventFormValidationReducer(state,action, eventForm), initialFormValidationState);

    return (
        <SchoolEditEventFormValidationContext.Provider value={requiredFieldsState}>
            <SchoolEditEventFormValidationDispatchContext.Provider value={dispatch}>
                {children}
            </SchoolEditEventFormValidationDispatchContext.Provider>
        </SchoolEditEventFormValidationContext.Provider>
    )
}


export function eventFormValidationReducer(eventFormState, action, newEventForm){
    switch(action.type){
        case "submitCheckEmpty":{
            let eventFormStateCopy = {...eventFormState};

            if(!newEventForm[action.currentField].length >0 || newEventForm[action.currentField] === null || newEventForm[action.currentField] === undefined){
                eventFormStateCopy[action.currentField] = false;
            }else{
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        case "inputLevelCheckEmpty": {
            let eventFormStateCopy = {...eventFormState};
            if(newEventForm[action.currentField].length >0){
                
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        default:{
            throw Error('Unknown action '+action.type)
        }
    }
}

let initialFormValidationState = {
    eventName:true,
    remark:true,
    eventType:"",
    location:true,
    eventDateTime:true,
    eventEndTime:true,
    signUpDeadline:true,
    openToAll:false,
    eventCategory_id:"",
    inviteParents:false,
}