import React, { useEffect, useState,useContext } from 'react'
import Layout from '../../components/Layout'
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import SchoolAdminDeleteStudent from '../administrator/administratorComponents/DeleteStudentButton';
import SchoolAdminArchiveStudentButton from '../administrator/administratorComponents/ArchiveStudentButton';
import UserEditPhoto from '../../components/UserEditPhoto';
import ProfileAvatar from '../../components/Avatar';
import { useBanner } from '../../../services/SystemBannerMessage';
import { PencilIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import StudentsGradeOptions from '../administrator/administratorComponents/StudentsGradeOptions';

// add a feature to see categories of events student has participated

export default function SchoolAllStudentProfile() {
    const [studentInfo,setStudentInfo] = useState({});
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const location = useLocation();
    const navigateTo = useNavigate();
    const {showBanner} = useBanner();

    const {studentId} = useParams();

    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    
    const [invitations, setInvitations] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [eventCategories, setEventCategories] = useState([]);

    const getStudentInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/students/${studentId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setStudentInfo(response.data.payload);
                    // const universityNames = response.data.payload.findFavorites.map((favorite)=>{
                    //     return favorite.university.schoolName
                    // })
                    // setFavorites(response.data.payload.findFavorites);
                    setInvitations(response.data.payload.invitations);
                }
            }).catch((error)=>{
                showBanner("Student Not Found");
                console.log(error)
                // navigateTo('/school/students',{state:{from: location},replace:true})
            })
    }

    const handleDelete = async()=>{
        await useAxiosWithInterceptor.delete(`/api/user/${userId}/counselor/students/${studentId}`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                navigateTo('/user/counselor/students',{state:{from: location},replace:true})
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getStudentInfo();
        // getInvitations();
    },[]);

    useEffect(()=>{
        document.title = "Schoolley | "+studentInfo?.firstName +" "+ studentInfo?.lastName
    },[studentInfo])
  
    return (
    <>
    <Layout>
      <div className='w-full h-full'>
        <div className="bg-opacity-75 rounded-2xl p-2 lg:mx-10">
          <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl  bg-indigo-500'>
            <div id='student-basic' className=''>
              <div className='flex justify-center'>
                <ProfileAvatar user={studentInfo} size={20}/>
              </div>
              <div className='text-center'>
                <UserEditPhoto refreshUser={getStudentInfo} />
              </div>
            </div>
            <div className=''>
              <div className='my-2'>
                <p className='font-bold text-3xl text-white'>{studentInfo?.firstName} {studentInfo?.lastName}</p>
              </div>
              <div className='flex space-x-5 items-center text-white'>
                <p className='font-semibold text-lg'>Class of {calculateClassYear(studentInfo?.gradeLevel)} (Grade {studentInfo?.gradeLevel?studentInfo.gradeLevel:"N/A"})</p>
              </div>
            </div>
          </div>
                        {/* basic info */}
          <div id='student-profile' className='rounded-lg shadow-sm my-2 space-y-2 bg-white'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p >Basic Information</p>
              {
                authState.role.includes("administrator")?              <button>
                  <PencilIcon className='w-6 h-6' />
                </button>
                :null
              }
            </div>

            <div className='lg:grid grid-cols-7 px-2'>
              <div id='column-one' className='col-span-3'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Email:</p>
                  <p className='font-semibold text-blue-500'>{studentInfo?.email}</p>
                </div>

                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>School: </p>
                  <p className='font-semibold text-blue-500'>{studentInfo?.school?.schoolName}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Grade Level:</p>
                  {/* <p className='font-semibold text-blue-500'>{studentInfo?.gradeLevel}</p> */}
                  <StudentsGradeOptions studentInformation={studentInfo} refreshStudentInfo={getStudentInfo} />
                </div>
              </div>

              <div id='column-two' className='col-span-2'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Date of Birth:</p>
                  <p className='font-semibold text-blue-500'>{}</p>
                </div>

                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Nationality:</p>
                  <p className='font-semibold text-blue-500'></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Member Since:</p>
                  <p className='font-semibold text-blue-500 text-sm'>
                    {months[moment(studentInfo?.createdAt).month()]}- 
                    {moment(studentInfo?.createdAt).format("DD, YYYY")}
                  </p>
                </div>
              </div>
              <div id='column-three' className='col-span-2'>
                {/* <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                    <p className='font-semibold text-blue-500 text-sm '>Father:</p>
                    <p></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold '>
                    <p className='text-sm'>Email:</p>
                    <p className='font-semibold text-blue-500'></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold '>
                  <p className='text-sm'>Mother:</p>
                  <p className='font-semibold text-blue-500'></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold '>
                  <p className='text-sm'>Email:</p>
                  <p className='font-semibold text-blue-500'></p>
                </div> */}
              </div>
            </div>
            
          </div>
          <div className='d-flex gap-x-3 my-2 rounded'>
            {
                authState.role.includes("administrator")?
                <div className='d-flex justify-content-end my-2 space-x-5'>
                    <SchoolAdminDeleteStudent student={studentInfo} />
                    <SchoolAdminArchiveStudentButton student={studentInfo} />
                </div>
                : null
            }
            </div>
            <div className='text-xl py-2 px-3 rounded-t-md font-semibold flex justify-between'>
              <p>Activities</p>
            </div>
            <div className=" flow-root">
              <div className=" overflow-x-auto ">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Event
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Attendance Status
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Event Type
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {invitations.map((invitation) => (
                          <tr key={invitation.id}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">
                              {invitation?.event?.eventName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm t font-semibold text-blue-500">
                              <p>
                                {months[moment(invitation?.event?.eventDateTime).month()]}-
                                {moment(invitation?.event?.eventDateTime).format("DD , YYYY")}
                              </p>
                            </td>
                            <td className={`whitespace-nowrap px-3 py-2  font-semibold text-xs ${invitation?.status === "accepted"?"text-green-600":invitation?.status ==="declined"?"text-red-500":"text-amber-500"}`}>{invitation?.status?.toUpperCase()}
                            </td>
                            <td className="whitespace-nowrap px-3 text-sm text-gray-500 font-semibold ">
                              <p className='rounded-2xl bg-blue-500 text-white w-fit px-2 '>
                                {invitation?.event?.eventCategory?.categoryName}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* user information section */}
      </div>
      </div>
    </Layout>
    </>
  )
}


const calculateClassYear = (currentGrade)=>{
    let graduationGrade = 12;
    let currentYear = moment().year();
    let classYear = currentYear + (graduationGrade - currentGrade);
    return classYear;
}