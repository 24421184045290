import React from 'react'
import UnAuthLayout from '../components/UnAuthLayout'

export default function UnAuthenticated() {
  return (
    <UnAuthLayout>
      <div>UnAuthenticated</div>
    </UnAuthLayout>
  )
}
