import React, { useContext, useEffect, useState } from 'react'
import { NewEventFormDispatchContext } from '../providers/EventForm_Context';
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';

export default function EventFormEventCategory() {
    const dispatch = useContext(NewEventFormDispatchContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const [options, setOptions] = useState([]);

    const getEventCategories = async ()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/eventCategories`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                let options = response.data.payload.map((category)=>{
                    return {
                        id:category.id,
                        name:category.categoryName,
                        selected:false,
                    }
                })
                setOptions(options);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const handleSelection = (optionId)=>{
        const optionsCopy = [...options];
        optionsCopy.forEach((option)=>{
            if(optionId === option.id){
                option.selected = true;
                setOptions(option.name);
                dispatch({
                    type:"changed",
                    name:"eventCategory_id",
                    content: option.id.toLowerCase()
                })
            }else{
                option.selected = false;
            }
        });
        setOptions(optionsCopy);
    }

    useEffect(()=>{
        getEventCategories();
    }
    ,[])

  return (
    <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 '>
        <label className="block text-sm text-gray-900 font-medium px-2 py-2 my-2 ">Event Category </label>
        <div className='sm:col-span-2 py-2 sm:mt-0 flex flex-wrap'>
        {options.map((item,index)=>{
            return (
                <button 
                    key={item.id}
                    onClick={()=>handleSelection(item.id)}
                    className={`transition delay-50 hover:bg-sky-500 shadow-sm font-semibold text-sm col-span-1 py-1 px-3 mx-1 my-1 border-1 rounded ${item.selected? "text-white bg-blue-500 font-bold":null} ${index === 0 ? "rounded-l":index === options.length -1? "rounded-r":""} `} >{item.name}</button>
                )
            })
        }
        </div>
    </div>
  )
}
