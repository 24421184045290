import React, { useEffect, useState } from 'react'

export default function TrueFalseOptions({name,initialValue,handleFormChange}) {

    const [options,setOptions] = useState(
        [
            {id:"trueOption",value:true, label:"YES",selected:false},
            {id:"falseOption",value:false, label:"NO",selected:false},
        ]
    )

    const handleSelected = (optionId)=>{
        const optionsCopy = [...options];
        optionsCopy.forEach((option)=>{
            if(optionId === option.id){
                option.selected = true;
            }else{
                option.selected = false;
            }
        });
        setOptions(optionsCopy)

        handleFormChange({target:{name:name,value:optionsCopy.find(option => option.selected).value}})
    }

    useEffect(()=>{
        let copyOptions = [...options];
        copyOptions.forEach((option)=>{
            if(option.value === initialValue){
                option.selected = true;
            }else{
                option.selected = false;
            }
        })
        setOptions(copyOptions);
    },[initialValue])

  return (
    <>
    <div className='grid grid-cols-3 mx-0 '>
      {options.map((option,index)=>{
        return(
            <button 
                key={option.id} 
                onClick={()=>handleSelected(option.id)} 
                className={`transition delay-50 cursor-pointer text-xs py-1 ${index===0? "rounded-l":""} ${index === options.length-1?"rounded-r":""} ${option.selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-green-400 `}>
                {option.label}
            </button>
            )
            })
        }
    </div>
    </>
  )
}
