import React, { useContext, useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { AuthenticationContext } from '../../services/AuthContextModule';
import CalendarModal from './CalendarModal';

export default function Calendar({allEvents}) {
    const {authState} = useContext(AuthenticationContext);
    const userRole = authState.role;

    const monthsEnglish = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const currentDateObj = new Date();

    const [currentMonth, setCurrentMonth] = useState(currentDateObj.getMonth());
    const [currentYear, setCurrentYear] = useState(currentDateObj.getFullYear());
    // const [currentDate, setCurrentDate] = useState(currentDateObj.getDate());
    const [monthDays, setMonthDays] = useState([]);
    const [monthName, setMonthName] = useState(monthsEnglish[currentDateObj.getMonth()]);
    const [currentYMD, setCurrentYMD] = useState(currentDateObj.toLocaleDateString());

    // const [visits,setVisits] = useState(allEvents);

    const filterEvents = (events,currentDate)=>{
        // could be an array of items
        const event = events.filter((event)=>{
            const eventDate = new Date(event.eventDateTime).toLocaleDateString();
            return eventDate === currentDate
        })
        return event;
    }

    const combineDate = (year,month,date)=>{
        const combinedDate = new Date(year,month,date).toLocaleDateString();

        return combinedDate;
    }
    
    const toPreviousMonth = ()=>{

        if(currentMonth-1 <0){                 // if month is 0 and -1 is < than 0;
            let previousMonth = 11;      // set previousMonth to 11
            setMonthName(monthsEnglish[previousMonth]);
            setCurrentMonth(previousMonth);
            setCurrentYear(currentYear-1);
            handleMonthDays(currentYear-1,previousMonth);

        }else{
            let previousMonth = currentMonth-1;
            setMonthName(monthsEnglish[previousMonth]);
            setCurrentMonth(previousMonth);
            handleMonthDays(currentYear,previousMonth);
        }
    }
    
    const toNextMonth = ()=>{

        if(currentMonth+1>11){
            let nextMonth = 0;
            setCurrentMonth(nextMonth);
            setCurrentYear(currentYear+1);
            setMonthName(monthsEnglish[nextMonth]);
            handleMonthDays(currentYear+1,nextMonth);
        }else{
            let nextMonth = currentMonth + 1;
            setMonthName(monthsEnglish[nextMonth]);
            setCurrentMonth(nextMonth);
            handleMonthDays(currentYear,nextMonth);
        }
    }

    const handleMonthDays = (year, month)=>{ 
        const lastDayOfMonth = new Date(year,month+1,0);
        const firstDayOfMonth = new Date(year,month,1);
        const dateOfLastDay = lastDayOfMonth.getDate();
        const weekDayOfFirstDay = firstDayOfMonth.getDay();
        
        let allDates = [];

        let weekDay;

        weekDayOfFirstDay === 0? weekDay = 7 : weekDay = weekDayOfFirstDay;

        for(let i = 0; i < dateOfLastDay; i++){
            allDates.push(i+1);
        }

        for(let i = 0; i < weekDayOfFirstDay;i++){
            allDates.unshift("");
        }
        setMonthDays(allDates);
    }

    useEffect(()=>{
        handleMonthDays(new Date().getFullYear(), new Date().getMonth());
    },[])
  return (
    <div className='lg:flex lg:h-full lg:flex-col mb-5 pb-5'>
    <h4 className='text-xl font-bold mb-2'>Calendar</h4>
        <div className='flex font-semibold pb-3 my-1 justify-center '>
            <button
            onClick={toPreviousMonth}
            type="button"
            className="bg-white relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-indigo-100 focus:z-10 shadow-sm">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className='px-4 py-1 w-40 flex justify-center items-center'>
                <p className='inline-block align-middle'>{monthName}</p>
            </div> 
            <button
                onClick={toNextMonth}
                type="button"
                className="bg-white relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-10 hover:bg-indigo-100 shadow-sm">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className='px-4 py-1 w-40 flex justify-center items-center'>
            <p className='inline-block align-middle'>{currentYear}</p>
            </div> 
        </div>
        <div className='grid grid-cols-7 font-semibold text-center xs:text-sm sm:text-sm lg:text-base rounded'>
            <p className='border-r p-1 bg-amber-400 shadow-sm rounded-l-lg'>Sun</p>
            <p className='border-r p-1 bg-amber-400 shadow-sm'>Mon</p>
            <p className='border-r p-1 bg-amber-400 shadow-sm'>Tue</p>
            <p className='border-r p-1 bg-amber-400 shadow-sm'>Wed</p>
            <p className='border-r p-1 bg-amber-400 shadow-sm'>Thur</p>
            <p className='border-r p-1 bg-amber-400 shadow-sm'>Fri</p>
            <p className='p-1 bg-amber-400 shadow-sm rounded-r-lg'>Sat</p>
        </div>
        <div className='grid grid-cols-7 p-1 gap-x-1 gap-y-1 rounded '>
            {
                monthDays.map((day,index)=>{
                return (
                    <div key={index} className={`flex bg-white shadow-sm rounded lg:flex-auto ${currentYMD === combineDate(currentYear,currentMonth,day)?'bg-sky-100':''} `}>
                        <div className='hidden w-full lg:grid lg:grid-cols-1 lg:grid-rows-3 lg:gap-px'> 
                            <p className='px-2 font-semibold text-lg'>{day}</p>
                            {filterEvents(allEvents,combineDate(currentYear,currentMonth,day)).map((item,index)=>{
                                return (
                                    <CalendarModal key={index} item={item} />
                                )})
                             }
                        </div>

                        <div key={index} className="isolate grid w-full grid-cols-7 grid-rows-2 gap-px lg:hidden justify-center items-center">
                            <p className='text-center font-semibold text-sm'>{day}</p>
                        </div>
                    </div>
                )})
            }
        </div>
    </div>
  )
}
