import React from 'react'
import Layout from './components/Layout'

export default function VisitorPage() {
  return (
    <>
    <Layout>

    <div>VisitorPage</div>
    </Layout>
    </>
  )
}
