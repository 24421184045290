import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBanner } from '../../../../../services/SystemBannerMessage';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import ImportanceOptions from './ImportanceOptions';
import ClassificationOptions from './ClassificationOptions';
import InstitutionalControls from './InstitutionalControlsOptions';
export default function AdminEditBasicInformation({refreshData}) {
  const [open, setOpen] = useState(false)
  const navigateTo = useNavigate();
  const location = useLocation();
  const {showBanner} = useBanner();

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const {universityId} = useParams();

  const [originalData, setOriginalData] = useState({});

  const [message, setMessage] = useState('');
  const [warning, setWarning] = useState('');

  const [formObj, setFormObj] = useState({
    institutionalControl: '',
    undergradClassification: '',
    totalUndergrads: '',
    totalGrads: '',
    totalInternationalStudent: '',
    totalStudents: '',
    inStateTuition: '',
    outOfStateTuition: '',
    financialAid: ''
  });

  const handleFormChange = (e)=>{
      const { name, value } = e.target;
      setFormObj({
      ...formObj,
      [e.target.name]: e.target.value
      });
  }

  const handleOpen = ()=>{
      setOpen(true);
      getBasicInfo();
  }
  const handleClose = ()=>{
      setOpen(false);
      setFormObj(originalData);
  }

  const getBasicInfo = async ()=>{
      await useAxiosWithInterceptor.get(`/api/super-admin/universities/${universityId}/basic-info`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          let originalInfo = response.data.payload;
          let setFormData={
          institutionalControl: originalInfo.institutionalControl || '',
          undergradClassification: originalInfo.undergradClassification || '',
          totalUndergrads: originalInfo.totalUndergrads || '',
          totalGrads: originalInfo.totalGrads || '',
          totalInternationalStudent: originalInfo.totalInternationalStudent || '',
          totalStudents: originalInfo.totalStudents || '',
          inStateTuition: originalInfo.inStateTuition || '',
          outOfStateTuition: originalInfo.outOfStateTuition || '',
          financialAid: originalInfo.financialAid || '',
          }
          setOriginalData(setFormData);
          setFormObj(setFormData);
        }
      })
      .catch((error)=>{
          console.log("error in loading existing data",error)
        }   
      )
  }

  const modifyIfUpdated = (originalData, updatedData)=>{
    let modifiedData = {};
    Object.keys(updatedData).forEach((key)=>{
        if(updatedData[key] !== originalData[key]){
          modifiedData[key] = updatedData[key];
        }
    });
    return modifiedData;
}

  const handleUpdateUniversity = async ()=>{
    let modifiedData = modifyIfUpdated(originalData, formObj);

    await useAxiosWithInterceptor.put(`/api/super-admin/universities/${universityId}/basic-info`,modifiedData,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        showBanner("Basic Information Updated Successfully","bg-green-500");
        setOpen(false);
        setFormObj(originalData);
        refreshData()
      }
    })

    .catch((error)=>{
      console.log("error in updating data",error)
    })
  }
    
  return (
    <>
    <button 
        onClick={handleOpen}
        className='mx-3 '>
        <PencilSquareIcon className='h-6 w-6 '/>
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                  <div className='lg:w-[30rem] font-semibold'>
                    {/* content */}
                    <div className="mt-3 text-center sm:mt-5 w-full ">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            <p className='text-center text-xl py-2'>Edit Basic Information</p>
                            </Dialog.Title>
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Source of Institutional Control</label>
                          <InstitutionalControls initialValue={formObj.institutionalControl} handleFormChange={handleFormChange} />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3 '>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">UnderGrad Classification</label>
                          <ClassificationOptions initialValue={formObj.undergradClassification} setClassification={handleFormChange} />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Total Undergraduates</label>
                          <input
                              type="number"
                              name="totalUndergrads"
                              id="totalUndergrads"
                              value={formObj.totalUndergrads}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="Total Undergraduates"
                          />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Total Graduate</label>
                          <input
                              type="number"
                              name="totalGrads"
                              id="totalGrads"
                              value={formObj.totalGrads}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="Total Graduates"
                          />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Total International Students</label>
                          <input
                              type="number"
                              name="totalInternationalStudent"
                              id="totalInternationalStudent"
                              value={formObj.totalInternationalStudent}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="Total International Students"
                          />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Total Students</label>
                          <input
                              type="number"
                              name="totalStudents"
                              id="totalStudents"
                              value={formObj.totalStudents}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="Total Students"
                          />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">In-State Tuition</label>
                          <input
                              type="number"
                              name="inStateTuition"
                              id="inStateTuition"
                              value={formObj.inStateTuition}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="In-State Tuition"
                          />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Out-Of-State Tuition $</label>
                          <input
                              type="number"
                              name="outOfStateTuition"
                              id="outOfStateTuition"
                              value={formObj.outOfStateTuition}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="Out-Of-State Tuition"
                          />
                        </div>
                        <div className='grid grid-cols-3 px-2 my-3 space-x-3'>
                          <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Financial Aid</label>
                          <input
                              type="text"
                              name="financialAid"
                              id="financialAid"
                              value={formObj.financialAid}
                              onChange={handleFormChange}
                              className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                              placeholder="Financial Aid"
                          />
                        </div>
                    </div>
                <div>
                  <p className='text-green-500 font-semibold'>{message}</p>
                  <p className='text-red-500 font-semibold'>{warning}</p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={handleUpdateUniversity}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Confirm
                  </button>
                </div>
                </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  )
}
