import React from 'react';
import UnAuthLayout from './components/UnAuthLayout';
import Layout from './components/Layout';

export default function UserSubscriptionWarning() {

    return (
      <>
      <Layout>
        <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
            <div className="text-center">
              <p className="text-2xl font-bold leading-9 tracking-tight text-gray-900 ">The subscription information of your organization is currently unavailable </p>
              <p className='my-4'>please contact your administrator for more information.</p>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            </div>
        </div>
      </Layout>
      </>
    )
  }