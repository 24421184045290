import { Outlet } from "react-router-dom";
import { useState,useEffect } from "react";

import useRefreshToken from "../hooks/useRefreshToken";

import useAuthenticationContext from "../hooks/authStateHook";
import { Spinner } from "react-bootstrap";

const PersistentLogin = () =>{

    const [isLoading, setIsLoading] = useState(true);

    const doRefreshToken = useRefreshToken();

    const {authState} = useAuthenticationContext();

    const verifyRefreshToken = async ()=>{
        try {
            await doRefreshToken();
        } catch (error) {
            console.log("Persistent Login Error")
            console.log(error.config)
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    
    useEffect(()=>{
        if(!authState?.accessToken){
            verifyRefreshToken()
        } else {
            setIsLoading(false)
        }
    },[])
        
    return (
        <>
        {isLoading?
            <div className="flex justify-center items-center min-h-screen min-w-full">
                <Spinner animation="border" /> 
                <span className="ml-2">Loading...</span>
            </div>
            :
            <Outlet/>
        }
        </>
    )
}

export default PersistentLogin;