import React,{useState,useEffect,useContext} from 'react'
import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';

import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import StudentArchiveOptionBar from './components/StudentArchiveOptionBar';
import { displayArchiveStatus } from '../../../../utils/displayArchiveStatus';
import ActivationButton from '../../adminComponents/SuperAdmin_ActivationButton';

export default function SuperAdmin_ManageSchoolStudents() {

    const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const location = useLocation();
    const navigateTo = useNavigate();
    const userId = authState.user;
    const {schoolId} = useParams();
    
    const [schoolInfo,setSchoolInfo] = useState();
    const [allStudents,setAllStudents] = useState([]);

    const getSchool = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}`,{withCredentials:true})
          .then((response)=>{
            if(response.status === 200){
              setSchoolInfo(response.data.payload)
            }
          }
          ).catch((err)=>{
            navigateTo(-1);
          });
      }
    
    const getAllStudents = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/students`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            // sort by student first name
            const sortedStudents = response.data.payload.sort((a,b)=>{
              if(a.firstName < b.firstName){
                return -1;
              }
              if(a.firstName > b.firstName){
                return 1;
              }
              return 0;
            });
            setAllStudents(sortedStudents);
          }
        }).catch((error)=>{
            console.log(error)
        });
    }

    useEffect(()=>{
        getSchool();
        getAllStudents();
    },[]);

  return (
    <Layout>
    <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl bg-white px-3 py-4'>
            <p className='text-3xl font-bold py-1 px-2'>{schoolInfo?.schoolName}</p>
            <p className='text-2xl py-1 px-2'>Students</p>
          <div className='mt-2 flow-root shadow-sm rounded-2xl overflow-x-auto'>
            <div className='inline-block min-w-full align-middle'>
              <table className='min-w-full border-separate border-spacing-0'>
                <thead className=''>
                  <tr className='text-center bg-green-500'>
                    <th className='sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 py-2.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 rounded-tl-2xl'>Name</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell'>Email</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell '>Grade</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell'>Archive Status</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell '>Activation</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell rounded-tr-2xl'>Archive</th>
                  </tr>
                </thead>
                <tbody>
                {allStudents.map((student)=>{
                    return (
                    <tr key={student.id} className='shadow-sm text-center'>
                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{student.firstName} {student.lastName}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{student.email}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{student.gradeLevel}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{displayArchiveStatus(student.archived)}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>
                          <ActivationButton studentInfo={student} refreshStudents={getAllStudents} />
                        </td>
                        <td className='whitespace-nowrap py-2 px-2 font-medium text-gray-900 sm:pl-6 lg:pl-8'>
                        <StudentArchiveOptionBar studentId={student.id} refreshStudents={getAllStudents} />
                        </td>
                    </tr>
                    )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    </Layout>
  )
}
