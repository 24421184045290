import React, { useContext, useState,useEffect, useRef,useCallback } from 'react'
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';
import { NewEventFormContext, NewEventFormDispatchContext } from '../providers/EventForm_Context';
import debounce from 'lodash.debounce';
import CounselorCreateUniversityModal from '../../../counselorComponents/Counselor_CreateUniversityModal';


import Select from 'react-select';

export default function EventUniversitySelector({attributeName,inputName}) {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const eventFormState = useContext(NewEventFormContext);
    const dispatch = useContext(NewEventFormDispatchContext);

    const [universityList, setUniversityList] = useState([]);

    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState([]);

    const [searchValue, setSearchValue] = useState("");

  const handleSelectUniversity =(university)=>{
      dispatch({
        type:"selectUniversity",
        name:attributeName,
        content: university
      })
  }

  const handleDeselectUniversity = (universityId)=>{
     dispatch({
        type:"deSelectUniversity",
        name: attributeName,
        content: universityId
     })
  }

  const getUniversities = async ()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities/search`)
    .then((response)=>{
      if(response.status === 200){
        const universityList = response.data.payload.map((university)=>{
        //   console.log(university)
            return {
            value: university,
            label: university.schoolName
          }
        })
        setUniversityList(universityList)
      }
    })
    .catch((error)=>{
      console.log(error)
    })
}

const searchUniversities = async (searchQuery) => {
  setLoading(true);
  await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities/search?search=${searchQuery}`, { withCredentials: true })
  .then((response) => {
    if (response.status === 200) {
      const newUniversities = response.data.payload || [];
      // setUniversities(newUniversities);
      setSearchOptions(newUniversities.map(u => ({
          value: u.schoolName,
          label: `${u.schoolName}, ${u.city}, ${u.state}, ${u.country}`,
          id: u.id,
          content:u
      })));
    }
  })
  .catch((error) => {
      console.log(error);
  })
  .finally(() => {
      setLoading(false);
  });
};

const handleSearchChange = (selectedOption) => {

  const searchQuery = selectedOption ? selectedOption.value : '';
  setOffset(0);
  searchUniversities(searchQuery);
  dispatch({
    type:"selectUniversity",
    name:attributeName,
    content: selectedOption
  })
};

const debouncedSearch = useCallback(debounce(searchUniversities, 500), []);

const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
    debouncedSearch(inputValue);
};

useEffect(()=>{
    getUniversities();
},[])

  return (
  <>
  <div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <label htmlFor= {attributeName} className="block text-sm text-gray-900 font-medium px-2 py-1.5 my-1 ">
              {inputName}
          </label>
          <Select
            options={searchOptions}
            onInputChange={handleInputChange}
            onChange={handleSearchChange}
            className='my-1'
          />  
          <div className='flex justify-center items-center my-1'>
            <CounselorCreateUniversityModal refreshData={getUniversities} />
          </div>
      </div>
  </div>
  <div className='pb-3 pt-1'>
      {
          eventFormState[attributeName].map((university,index)=>{
          return (
              <div key={university.id} className='transition delay-100 mx-1 my-1 px-2 py-2 rounded border-2 border-sky-800 inline-flex hover:bg-sky-800 hover:text-white shadow-sm'>
                <p className='px-2 text-xs font-semibold grid content-center'>{index+1 +". "+ university.schoolName}</p>
                <div className='px-0 d-flex align-items-center'>
                  <button className='hover:cursor-pointer' 
                    onClick={()=>{handleDeselectUniversity(university.id) }} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /> 
                    </svg>
                  </button>
                </div>
              </div>
          )})
      }
  </div>
  </>
  )
}