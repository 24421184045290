import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor'
import { AuthenticationContext } from '../../../services/AuthContextModule'

import { getDateOnly,getTimeOnly } from '../../../utils/cusDateFormatter';
import { Link } from 'react-router-dom';
import StatusToggle from '../studentComponents/StatusToggle';
import moment from 'moment';
import { useBanner } from '../../../services/SystemBannerMessage';
import SkeletonTableRow from '../../pageComponents/SkeletonTableRow';

export default function StudentInvitations() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const {showBanner} = useBanner();

  const userId = authState.user;
  const schoolId = authState.schoolId;
  const [isLoading, setIsLoading] = useState(true);

  const [acceptedInvites, setAcceptedInvites] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [declinedInvites, setDeclinedInvites] = useState([]);
  const [pastInvites, setPastInvites] = useState([]);

  const [invitationTable, setInvitationTable] = useState([]);

  const [activeTabs, setActiveTabs] = useState([
    {active: true, tabName: "ACCEPTED", content: acceptedInvites},
    {active: false, tabName: "PENDING", content: pendingInvites},
    {active: false, tabName: "DECLINED", content: declinedInvites},
    {active: false, tabName: "PAST", content: pastInvites}
  ])

  const handleSetTabContent = (index, tabContent) =>{
    const newActiveTabs = [...activeTabs];
    newActiveTabs[index].content = tabContent;
    setActiveTabs(newActiveTabs);
  }

  const handleVisitTable = (index, invitationData) =>{
    let newActiveTabs = [...activeTabs];
    newActiveTabs.forEach((tab)=>{
      tab.active = false;
    });

    newActiveTabs[index].active = true;
    setActiveTabs(newActiveTabs);
    
    setInvitationTable(invitationData);
  }

  const handleAcceptedInvites = (invitesData) =>{
    const acceptedInvites = invitesData.filter((invite)=>{
      return invite.status === "accepted" && moment(invite.event.eventDateTime).isAfter(moment())
    })
    setAcceptedInvites(acceptedInvites);
    handleSetTabContent(0,acceptedInvites)
  }

  const handlePendingInvites = (invitesData) =>{
    const pendingInvites = invitesData.filter((invite)=>{
      return invite.status === "pending" && moment(invite.event.eventDateTime).isAfter(moment())
    })
    setPendingInvites(pendingInvites);
    handleSetTabContent(1,pendingInvites)
  }

  const handleDeclinedInvites = (invitesData) =>{
    const declinedInvites = invitesData.filter((invite)=>{
      return invite.status === "declined" && moment(invite.event.eventDateTime).isAfter(moment())
    });
    setDeclinedInvites(declinedInvites);
    handleSetTabContent(2,declinedInvites)
  }

  const handlePastInvites = (invitationData)=>{
    const pastInvites = invitationData.filter((invite)=>{
      return moment(invite.event.eventDateTime).isBefore(moment())
    });
    setPastInvites(pastInvites);
    handleSetTabContent(3,pastInvites);
  }

  const getAllInvitations = async()=>{
    useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/invitations`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200) {
        const sortedData = response.data.payload.sort((a,b)=>{
          return moment(a.event.eventDateTime).isAfter(moment(b.event.eventDateTime)) ? 1 : -1;
        });
        handlePendingInvites(sortedData);
        handleDeclinedInvites(sortedData);
        handleAcceptedInvites(sortedData);
        handlePastInvites(sortedData);

        const acceptedInvites = response.data.payload.filter((invite)=>{
          return invite.status === "accepted" && moment(invite.event.eventDateTime).isAfter(moment())
        });
        const pendingInvites = response.data.payload.filter((invite)=>{
          return invite.status === "pending" && moment(invite.event.eventDateTime).isAfter(moment())
        });
        const declinedInvites = response.data.payload.filter((invite)=>{
          return invite.status === "declined" && moment(invite.event.eventDateTime).isAfter(moment())
        });
        
        // find currently which tab is true, then set invites accordingly
        let tabIndex;
        activeTabs.forEach((tab,index)=>{
          if(tab.active === true){
            tabIndex = index;
          }
        });

        if(tabIndex === 0){
          setInvitationTable(acceptedInvites);
        } else if(tabIndex === 1){
          setInvitationTable(pendingInvites)
        } else if(tabIndex === 2){
          setInvitationTable(declinedInvites)
        }
      }
    })
    .catch((error)=>{
        console.log(error)
    })
    .finally(()=>{
      setIsLoading(false);
    });
  }

  const handleChangeStatus = async (invitationId,statusValue)=>{
      await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/student/invitations/${invitationId}/respond`,{studentResponse:statusValue},{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){  
            getAllInvitations();
        }
        if(statusValue === "accepted"){
          showBanner(`Invitation Accepted`, "bg-green-400")
        } else if(statusValue === "declined"){
          showBanner(`Invitation Declined`, "bg-yellow-400")
        }
      })
      .catch((error)=>{
        console.log(error)
      });
  }

  useEffect(()=>{
    document.title = "Invitations"; 
      getAllInvitations();
  },[])

  return (
  <Layout>
  <div className='md:w-full w-full'>
    <div className='bg-opacity-75 px-1 py-2 lg:mx-10 '>
      <p className='text-xl font-bold mb-2 '>Invitations</p>
      <div className='overflow-x-auto rounded-t-2xl '>
        <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5 ">
          <div className='grid grid-cols-4 '>
            {
              activeTabs.map((tab,index)=>{
                return (
                  <button key={index} 
                    className={`transition delay-100 shadow-sm flex flex-col items-baseline flex-wrap justify-between gap-x-2 border-gray-900/5 px-3 py-1 xl:px-8 hover:text-white ${tab.active?"bg-blue-500 text-white":"hover:bg-blue-400 bg-slate-200 "}`}
                    onClick={()=>{handleVisitTable(index,tab.content)}}>
                    <p className="w-full text-sm font-semibold ">{tab.tabName}</p>
                    <p className="w-full flex-none text-2xl font-medium leading-10 tracking-tight ">{tab.content.length}</p>
                </button>
                )})
              }
          </div>
        </div>
      </div>
{/* table */}
       <div className="bg-blue-600 mt-2 rounded-b-xl shadow-sm">
        <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-0 rounded-b-xl">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-white ">
              <tr className='text-center'>
                <th scope="col" className="px-3 py-2 pl-4 pr-3 text-sm font-semibold sm:pl-6 w-[300px]">Event</th>
                <th scope="col" className="hidden px-3 py-2 text-center text-sm font-semibold lg:table-cell w-[150px]"> Event Type</th>
                <th scope="col" className="py-2 text-sm font-semibold w-[180px]"> Date</th>
                <th scope="col" className="hidden px-3 py-2 text-center text-sm font-semibold lg:table-cell w-[200px]">From/To</th>
                <th scope="col" className="hidden px-3 py-2 text-center text-sm font-semibold lg:table-cell w-[180px]">Signup Deadline</th>
                <th scope="col" className="relative py-2 pl-3 pr-4 sm:pr-6 text-sm">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white ">
              {
                isLoading?
                <SkeletonTableRow colNumber={6} rowNumber={5} />
                :
                invitationTable.map((invite)=>{
                  return (
                    <tr key={invite.id} className='transition delay-50 text-center hover:bg-blue-100'>
                    <td className="text-start whitespace-nowrap px-3 py-2 pl-4 pr-3 text-sm sm:pl-6">
                        <Link 
                          className="block truncate whitespace-normal break-words max-w-xs"
                          to={`/student/${invite.event.eventCategory.categoryName ==="UCC"?"ucc-events":"school-events"}/${invite.event?.id}`}>
                          <p className='font-bold text-blue-500 text-sm'>
                            {invite.event?.eventName}
                          </p>
                        </Link>
                        <dl className="lg:hidden">
                          <p className='text-xs'>{moment(invite.event?.eventDateTime).format('LT')} ~ {moment(invite.event?.eventEndTime).format("LT")}</p>
                        </dl>
                    </td>
                    <td 
                      className="hidden px-2 py-2 text-xs lg:table-cell font-semibold ">
                      <div className='flex justify-center'>
                        <p className='w-fit px-3 py-0.5 text-white rounded-2xl border-2 bg-blue-500'>
                          {invite.event?.eventCategory?.categoryName}
                          </p>
                      </div>
  
                      </td>
                    <td 
                      className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-semibold italic">{moment(invite.event?.eventDateTime).calendar(null,momentOptions2)}</td>
                    <td 
                      className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell text-center font-semibold italic ">
                        <div className='rounded-2xl py-1 bg-red-500 text-white'>
                        {moment(invite.event?.eventDateTime).format('LT')} / {moment(invite.event?.eventEndTime).format('LT')}
                        </div>
                      </td>
                    <td 
                      className="hidden px-3 py-2 text-sm text-orange-500 lg:table-cell text-center font-semibold">{moment(invite.event?.signUpDeadline).calendar(null,momentOptions2)}</td>
                    <th 
                      className="whitespace-nowrap px-3 py-2 text-gray-500 text-sm">
                      {
                        moment(invite.event?.signUpDeadline).isBefore(moment())?
                        <p className='text-red-500'>Past</p>
                        :
                        <StatusToggle invitation={invite} handleStatusChange={handleChangeStatus} />
                      }

                    </th>
                  </tr>)
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </Layout>
  )
}


const momentOptions = {
  sameDay: '[Today] (dddd) [at] LT', // Today (Monday) at 2:30 PM
  nextDay: '[Tomorrow] (dddd) [at] LT', // Tomorrow (Tuesday) at 2:30 PM
  nextWeek: 'dddd, MM/DD/YYYY [at] LT', // Sunday, 09/20/2020 at 2:30 PM
  lastDay: '[Yesterday] (dddd) [at] LT', // Yesterday (Sunday) at 2:30 PM
  lastWeek: '[Last] dddd, MM/DD/YYYY [at] LT', // Last Monday, 09/14/2020 at 2:30 PM
  sameElse: 'MM/DD/YYYY (dddd) [at] LT' // 09/14/2020 (Friday) at 2:30 PM
};

const momentOptions2 = {
  sameDay: '[Today] (ddd)', // Today (Monday)
  nextDay: '[Tomorrow] (ddd)', // Tomorrow (Tuesday)
  nextWeek: 'MMM DD, YYYY (ddd)', // Sunday, 09/20/2020
  lastDay: '[Yesterday] (ddd)', // Yesterday (Sunday)
  lastWeek: '[Last] ddd, MMM DD, YYYY', // Last Monday, 09/14/2020
  sameElse: 'MMM DD, YYYY (ddd)' // 09/14/2020 (Friday)
};