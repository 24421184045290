import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useLocation, useNavigate } from 'react-router-dom';

import { useBanner } from '../../../../services/SystemBannerMessage';

import CountryPicker from '../../../components/formComponents/CountryPicker';

export default function CounselorCreateUniversityModal({refreshData}) {

    const [open, setOpen] = useState(false)
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const navigateTo = useNavigate();
    const location = useLocation();
    const {showBanner} = useBanner();

    const [formObj, setFormObj] = useState({
        schoolName: '',
        country: '',
        state: '',
        city: '',
        website: 'https://',
    });

    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');

    const handleFormChange = (e)=>{
        const { name, value } = e.target;
        if (name === 'website') {
            if (!value.startsWith('https://')) {
                // Prevent the user from removing the "https://"
                return;
            }
        }
        setFormObj({
        ...formObj,
        [e.target.name]: e.target.value
        });
    }

      const handleBlur = (e) => {
        const { name, value } = e.target;
        setFormObj({
            ...formObj,
            [name]: value.trim(), // Trim spaces only when the input loses focus
        });
    };

    const handleCountryChange = (selectedOption) => {
        setFormObj({
            ...formObj,
            country: selectedOption.value
        });
    }

    const handleOpen = ()=>{
        setOpen(true)
    }
    const handleClose = ()=>{
        setOpen(false)
        setFormObj({
            schoolName: '',
            country: '',
            state: '',
            city: '',
            website: 'https://',
        })
    }

  const handleCreateUniversity = async()=>{
    let emptyField = [];
    Object.keys(formObj).forEach((key)=>{
      if(formObj[key] === ""|| formObj[key]=== null){
        emptyField.push(key);
      }
    });
    if(emptyField.length > 0){
      setWarning("Please fill out all fields");
      return;
    }

    const newUniversity = {
        schoolName: formObj.schoolName,
        country: formObj.country,
        state: formObj.state,
        city: formObj.city,
        website: formObj.website
    }

    await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/counselor/universities/new`,newUniversity,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        showBanner(response.data.payload.schoolName + " Created Successfully","bg-green-500");
        setOpen(false);
        setFormObj({
            schoolName: '',
            country: '',
            state: '',
            city: '',
            website: 'https://',
        })
        refreshData();
      }
    })
    .catch((error)=>{
      console.log(error)
      setWarning("Something went wrong");
    })
}
    
  return (
    <>
    <button
      onClick={handleOpen}
      type="button"
      className="block rounded-md bg-indigo-600 lg:w-full w-3/4 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >Add New University
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                  <div>
                    <div className="mt-3 text-center sm:mt-5 w-full">
                       <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          <p className='text-center text-xl py-2'>Add a New University to Database</p>
                        </Dialog.Title>
                    </div>

                    <label htmlFor="schoolName" className="block text-xs font-medium text-gray-900 mt-2 py-2">University Name </label>
                    <input
                        type="text"
                        name="schoolName"
                        id="schoolName"
                        value={formObj.schoolName}
                        onChange={handleFormChange}
                        onBlur={handleBlur}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter University Name"
                    />

                    <label htmlFor="city" className="block text-xs font-medium text-gray-900 mt-3 py-2">City </label>
                    <input
                        type="text"
                        name="city"
                        id="city"
                        value={formObj.city}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter city name"
                    />

                    <label htmlFor="state" className="block text-xs font-medium text-gray-900 mt-3 py-2">State / Province</label>
                    <input
                        type="text"
                        name="state"
                        id="state"
                        value={formObj.state}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter State / Province"
                    />

                    <label htmlFor="country" className="block text-xs font-medium text-gray-900 mt-3 py-2">Country</label>
                    <CountryPicker handleSelect={handleCountryChange} />

                    <label htmlFor="website" className="block text-xs font-medium text-gray-900 mt-3 py-2">Website</label>
                    <input
                        type="text"
                        name="website"
                        id="website"
                        value={formObj.website}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter Website"
                    />
                  </div>
                  <div>
                      <p className='text-green-500 font-semibold'>{message}</p>
                      <p className='text-red-500 font-semibold'>{warning}</p>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        onClick={handleCreateUniversity}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
      </Dialog>
    </Transition>
    </>
  )
}