import React, { useEffect, useState, useContext } from 'react'
import Select from 'react-select';

export default function ClassificationOptions({initialValue,setClassification}) {

    const options = [
        {value:"Coeducational", label:"Coeducational"},
        {value:"Men's college", label:"Men's college"},
        {value:"Women's college", label:"Women's college"}
    ];

    const [initialOption, setInitialOption] = useState({value:"",label:"",name:""});
    
    useEffect(()=>{
        setInitialOption({value:initialValue,label:initialValue,name:"undergradClassification"})
    },[initialValue])

  return (
    <>
    <div className='w-64'>
        <Select
            value={initialOption}
            closeMenuOnSelect={true}
            options= {options}
            onChange={(option)=>setClassification({target:{value:option.value,name:"undergradClassification"}})}
        />
    </div>
    </>
  )
}
