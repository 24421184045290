import React,{useContext} from 'react'
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../services/AuthContextModule';
import moment from 'moment';

export default function CalendarModal({item}) {
    const {authState, setAuthState} = useContext(AuthenticationContext);
    const navigate = useNavigate(); // Use useNavigate hook

    const handleNavigation = ()=>{
        navigate(item.url);
    }

  return (
    <>
    <Menu as="div" className="relative w-full z-40" >
        <Menu.Button className="flex items-center w-full">
          <div className="flex items-center rounded-sm font-semibold hover:bg-slate-200 whitespace-nowrap overflow-hidden px-1 " >
            <p className='text-xs text-blue-400 overflow-hidden mx-1'>
              {moment(item.start).format("hh:mm A")} : {item.title}
            </p>
            <p className='text-xs whitespace-nowrap overflow-hidden '>{item.eventName} </p>
          </div>
        </Menu.Button>
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95" >

            <Menu.Items className="absolute right-0 z-40 mt-2.5 min-w-max w-fit origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none space-y-1 bg-opacity-60">
              <Menu.Item className=''>
                <div className='pb-2 px-3 z-40'>
                    <div className='flex items-center'>
                      <p className='text-base font-semibold'>{item.title}</p>
                    </div>
                    <div className='flex items-center text-sm my-2'>
                      <p className=''>Time: </p>
                      <p className='font-semibold'>
                        {moment(item.start).format("hh:mm A")}
                      </p>
                    </div>
                    <div className='flex items-center text-sm my-2'>
                      <p
                        onClick={handleNavigation} 
                        className='font-semibold text-blue-500'>Details</p>
                    </div>
                </div>
              </Menu.Item>
            </Menu.Items>
        </Transition>
    </Menu>
    </>
  )
}
