import React, { useContext, useState, Fragment, useRef} from 'react'
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import {AuthenticationContext } from '../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { CheckIcon,PencilIcon } from '@heroicons/react/24/outline'

export default function SuperAdmin_EditEventTypeButton({eventType,editEventType}) {

    const [open, setOpen] = useState(false);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;

    const [formObj, setFormObj] = useState({
        typeName: eventType?.typeName,
        typeDescription: eventType?.typeDescription,
    });

    const [typeName, setTypeName] = useState(eventType?.typeName);
    const [typeDescription, setTypeDescription] = useState(eventType?.typeDescription);

    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');

    const handleTypeName = (e)=>{
        setTypeName(e.target.value)
        setFormObj({...formObj, typeName: e.target.value})
    }
    const handleDescription = (e)=>{
        setTypeDescription(e.target.value)
        setFormObj({...formObj, typeDescription: e.target.value})
    }

  return (
    <>
    <button 
      onClick={()=>setOpen(true)}
      className='transition delay-100 px-3 py-2 hover:bg-slate-200 rounded-lg'>
        <PencilIcon className='h-5 w-5 ' />
    </button>
    <Transition show={open}>
        <Dialog className="relative z-10" onClose={()=>{
          setMessage('')
          setWarning('')
          setOpen(false)
        }}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                  <div>
                    <div className="mt-3 text-center sm:mt-5 w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        <p className='text-center '>Edit Event Type</p>
                      </Dialog.Title>
                      <div className="mt-2">
                      
                      </div>
 
                    </div>
                    <label htmlFor="name" className="block text-xs font-medium text-gray-900 mt-2 py-2">Event Type Name </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={typeName}
                        onChange={handleTypeName}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Jane Smith"
                      />
                    <label htmlFor="name" className="block text-xs font-medium text-gray-900 mt-3 py-2">Category Description </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={typeDescription}
                        onChange={handleDescription}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Jane Smith"
                      />
                  </div>
                  <div>
                    <p className='text-green-500 font-semibold'>{message}</p>
                    <p className='text-red-500 font-semibold'>{warning}</p>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={()=>{
                        editEventType(eventType?.id,formObj); 
                        setOpen(false);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
