import { axiosWithInterceptor } from "../api/axios";
import { useEffect } from "react";
import useAuthenticationContext from "./authStateHook";
import useRefreshToken from "./useRefreshToken";

const useAxiosWithInterceptor =  ()=>{
    const {authState} = useAuthenticationContext();
    const doRefreshToken = useRefreshToken();
    
    useEffect(()=>{

        const requestIntercept = axiosWithInterceptor.interceptors.request.use(
            config =>{
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `Bearer ${authState.accessToken}`
                }
                return config;
            },(error)=>{
                if (process.env.NODE_ENV === 'development') {
                    console.log("Request Interceptor Error:", error.message);
                } else {
                    console.log("An error occurred in the request interceptor.");
                }
                return Promise.reject(error);
                // chatgpt told me to add 'return' before Promise.reject(error)
            }
        );

        // Response interceptor to handle token refresh logic
        const responseIntercept = axiosWithInterceptor.interceptors.response.use(
            response =>response, 
            async (error)=>{
                const failedRequest = error?.config;
                if(error?.response?.status === 401 && !failedRequest?.sent){
                    failedRequest.sent = true;
                    try{
                        const newAccessToken = await doRefreshToken();
                        failedRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                        return axiosWithInterceptor(failedRequest);
                    }catch(refreshError){

                        if (process.env.NODE_ENV === 'development') {
                            console.log("Refresh Token Error:", refreshError.message);
                        } else {
                            console.log("An error occurred while refreshing the token.");
                        }
                        // Propagate the refresh error to the calling function
                        return Promise.reject(refreshError);
                    }
                }
                if (process.env.NODE_ENV === 'development') {
                    console.log("Response Interceptor Error:", error.message);
                } else {
                    console.log("An error occurred in the response interceptor.");
                }
                return Promise.reject(error)
            }
        )

    //cleanup function react will execute this so to clear the interceptor
        return ()=>{
            axiosWithInterceptor.interceptors.request.eject(requestIntercept);
            axiosWithInterceptor.interceptors.response.eject(responseIntercept);
        }

    },[authState, doRefreshToken])

// by the time this returns axiosWithInterceptor, tokens will be attached
    return axiosWithInterceptor
}

export default useAxiosWithInterceptor;