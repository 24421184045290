import React from "react";
import { Outlet } from "react-router-dom";
import useAuthenticationContext from "../hooks/authStateHook";
import UserPendingActivationSetup from "../pages/UserPendingActivation";

export default function ActivationCheck(){

    const {authState} = useAuthenticationContext();

    return (
        authState.isActivated
        ===true?<Outlet/>: <UserPendingActivationSetup/>
    )
}