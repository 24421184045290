import React, { useContext, useState, useEffect } from 'react';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { Dialog, Transition } from '@headlessui/react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useBanner } from '../../../services/SystemBannerMessage';
import moment from 'moment';

export default function RenewSubscriptionYear({ schoolId, getAllSubscriptions }) {
  const [open, setOpen] = useState(false);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const { authState } = useContext(AuthenticationContext);
  const userId = authState.user;
  const { showBanner } = useBanner();
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [latestSubscription, setLatestSubscription] = useState(null);
  const [warning, setWarning] = useState("");

  const [subscriptionObj, setSubscriptionObj] = useState({
    startingDate: null,
    endingDate: null,
    price: 0
  });

  const getSchoolSubscriptions = async () => {
    try {
      const response = await useAxiosWithInterceptor.get(`api/super-admin/schools/${schoolId}/subscriptions`, { withCredentials: true });
      if (response.status === 200) {
        const actives = response.data.payload.filter(sub => sub.status === "active");
        setActiveSubscription(actives);

        const allSubs = response.data.payload;
        let latestSub;
        allSubs.forEach(sub => {
          if (!latestSub || new Date(sub.endingDate) > new Date(latestSub?.endingDate)) {
            latestSub = sub;
          }
        });

        let latestActiveSub;
        actives.forEach(sub => {
          if (!latestActiveSub || new Date(sub.endingDate) > new Date(latestActiveSub?.endingDate)) {
            latestActiveSub = sub;
          }
        });
        setLatestSubscription(latestSub);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscriptionInfo = (e) => {
    const copyObj = { ...subscriptionObj };
    copyObj[e.target.name] = e.target.value;

    if (e.target.name === "startingDate") {
      copyObj['endingDate'] = moment(e.target.value).add(1, 'year').toDate();
    }

    setSubscriptionObj(copyObj);
  };

  const handleOpen = () => {
    setOpen(true);
    getSchoolSubscriptions();
  };

  const handleClose = () => {
    setOpen(false);
    setSubscriptionObj({
      startingDate: null,
      endingDate: null,
      price: 0
    });
    setWarning("");
  };

  const createNewSubscription = async () => {
    if (!subscriptionObj.startingDate) {
      return setWarning("Starting Date can not be empty");
    }
    if (!subscriptionObj.price) {
      return setWarning("Price is invalid");
    }

    try {
      const response = await useAxiosWithInterceptor.post(`api/super-admin/schools/${schoolId}/subscriptions/renew`, { subscriptionObj }, { withCredentials: true });
      if (response.status === 201) {
        getAllSubscriptions();
        handleClose();
        showBanner("New subscription created", 'bg-green-400');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button 
        onClick={handleOpen}
        className='rounded-lg px-4 py-2 bg-green-500 shadow-sm text-white font-semibold hover:bg-green-600'>
        Renew Annual Subscription
      </button>

      <Transition show={open}>
        <Dialog className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full h-[480px] ">
                  <div>
                    <div className="mt-1 text-center sm:mt-5 w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        <p className='text-center '>Add a New Event Type</p>
                      </Dialog.Title>
                    </div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-900 mt-2 py-2">Starting Date</label>
                    <ReactDatePicker 
                      className='block py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 font-semibold text-center sm:leading-6 bg-slate-100 w-96'
                      selected={subscriptionObj.startingDate}
                      onChange={(date) => handleSubscriptionInfo({ target: { name: 'startingDate', value: date } })}
                      minDate={moment(latestSubscription?.endingDate).subtract(30, 'day').toDate()}
                    />
                  </div>
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-900 mt-2 py-2">Ending Date</label>
                    <p className='font-semibold text-blue-600 text-center'>{
                      subscriptionObj.endingDate?
                      moment(subscriptionObj.endingDate).format("MM-DD-YYYY"):
                      "Select a starting date"
                    }
                    </p>
                  </div>
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-900 mt-2 py-2">Price $</label>
                    <input
                      type='number'
                      name="price"
                      id="price"
                      value={subscriptionObj.price}
                      onChange={handleSubscriptionInfo}
                      className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                      placeholder="Enter University Name"
                    />
                  </div>

                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={createNewSubscription}
                    >
                      Confirm
                    </button>
                  </div>
                  <div className='mt-4 w-fit'>
                    <p className='my-3 font-semibold text-blue-500'>This will create a new subscription for a full year</p>
                    <p className='font-bold text-red-500'>{warning}</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
