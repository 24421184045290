import React, { useEffect,useContext, useState, useRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { SchoolNewEventFormContext, SchoolNewEventFormDispatchContext } from '../providers/SchoolEventFormContext';
import { SchoolNewEventFormRequiredContext, SchoolNewEventFormRequiredDispatchContext } from '../providers/SchoolEventFormValidationContext';
import moment from 'moment';


export default function SchoolEventFormDeadlinePicker() {
    const eventFormState = useContext(SchoolNewEventFormContext);
    const dispatch = useContext(SchoolNewEventFormDispatchContext);
    const requiredFields = useContext(SchoolNewEventFormRequiredContext);
    const formCheckDispatch = useContext(SchoolNewEventFormRequiredDispatchContext);

    const [deadlineOptions, setDeadlineOptions] = useState([
        {id:"option1", value: 1, label:"1 Hour Before", selected:false},
        {id:"option2", value: 24, label:"1 Days Before", selected:false},
        {id:"option3", value: 72, label:"3 Days Before", selected:false},
        {id:"option4", value: 168, label:"1 Week Before", selected:false},
    ]);

    const handleSelection = (optionId)=>{

        let eventDateTime = new Date(eventFormState.eventDateTime);
        let updateTime = new Date(eventDateTime);

        const optionsCopy = [...deadlineOptions];

        optionsCopy.forEach((option)=>{
            if(optionId === option.id){
                let setTime = new Date(updateTime.setHours(updateTime.getHours() - option.value));
                let today = new Date();
                if(setTime > today){
                    option.selected = true;
                    dispatch({
                        type:"changed",
                        name:"signUpDeadline",
                        content: setTime
                    })
                }
            }else{
                option.selected = false;
            }
        });
        setDeadlineOptions(optionsCopy);
    }

    const presetDeadline = (dateTime)=>{
        let optionsCopy = [...deadlineOptions];
        optionsCopy[0].selected = true;
        dispatch({
            type:"changed",
            name:"signUpDeadline",
            content: dateTime
        })
    }

    useEffect(()=>{
        if(!eventFormState.signUpDeadline){
            presetDeadline(moment().subtract(1,"hour").toDate())
        }
    },[])

    useEffect(() => {
        // use deadline in state to calculate against event date, then use the result to select the tab?
        let selectedDeadline = deadlineOptions.find((option)=>option.selected === true);
        // the result goes into subtract
    }, [eventFormState.eventDateTime]);

  return (
    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 py-3">
        <label htmlFor= "" className="block text-sm text-gray-900 px-2 py-1.5 text-red-500 font-semibold">
            Sign Up Deadline
        </label>
        <div className='sm:col-span-3 sm:mt-0 flex'>
        {
            deadlineOptions.map((option,index)=>{
                return (
                    <button 
                        key={option.id} 
                        value={option.value} 
                        onClick={()=>handleSelection(option.id)}
                        className={`transition delay-50 inline-flex items-center px-2 py-1 my-0 mx-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-green-600 hover:text-slate-300 focus:z-10 rounded ${option.selected? "bg-green-600 text-white":""}`}>{option.label.toUpperCase()}</button>
                )
            })
        }
        </div>
        <p className='px-2 text-red-500 text-xs' hidden={requiredFields['signUpDeadline']}> Sign Up Deadline can not be empty</p>
        <p className='px-2 text-red-500 text-xs' hidden={true}> Deadline can not be empty</p>
    </div>
  )
}
