import React, { useContext, useEffect, useState } from 'react'
import { NewEventFormContext,NewEventFormDispatchContext } from '../providers/EventForm_Context';

export default function EventType ({inputName,attributeName }) {
    const [selectedOption, setSelectedOption] = useState();
    const dispatch = useContext(NewEventFormDispatchContext);

    const [options, setOptions] = useState([
        {id:"option1",name:"IN PERSON", color:"bg-green-500",selected: false},
        {id:"option2",name:"ONLINE", color:"bg-teal-500",selected: false},
    ]);

    const handleSelection = (optionId)=>{
        const optionsCopy = [...options];
        optionsCopy.forEach((option)=>{
            if(optionId === option.id){
                option.selected = true;
                setSelectedOption(option.name);
                dispatch({
                    type:"changed",
                    name:attributeName,
                    content: option.name.toLowerCase()
                })
            }else{
                option.selected = false;
            }
        });
        setOptions(optionsCopy);
    }
    useEffect(()=>{
        handleSelection("option1")
    },[])

  return (
    <>
    <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 '>
        <label className="block text-sm text-gray-900 font-medium px-2 py-2 my-2 ">{inputName} </label>
        <div className='sm:col-span-2 py-2 sm:mt-0 flex'>
        {options.map((item,index)=>{
            return (
                <button 
                    key={item.id}
                    onClick={()=>handleSelection(item.id)}
                    className={`shadow-sm font-semibold text-xs col-span-1 py-2 w-1/3 ${item.selected?" text-white "+item.color:""} ${index === 0 ? "rounded-l":index === options.length -1? "rounded-r":""} `} >{item.name}</button>
                )
            })
        }
        </div>
    </div>
    </>
  )
}
