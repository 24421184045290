import axios from "axios";

let BASE_URL ="https://api.schoolley.com";

if(process.env.REACT_APP_ENV === 'development'){
    // console.log("Environment:", process.env.REACT_APP_ENV);
    BASE_URL = "http://localhost:8080";
}
// const BASE_URL = "https://api.schoolley.com";

export default axios.create({
    baseURL: BASE_URL,
    headers:{
        "Content-Type":"application/json",
    },
    withCredentials: true // Include cookies in requests
});

// Axios instance with interceptors for handling specific scenarios (e.g., token refresh)

export const axiosWithInterceptor = axios.create({
    baseURL: BASE_URL,
    headers:{'Content-Type':'application/json'},
    withCredentials:true
});