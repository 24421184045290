import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useBanner } from '../../../../../services/SystemBannerMessage';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function EditAdmissionInfo({refreshData}) {
    const [open, setOpen] = useState(false)
    const navigateTo = useNavigate();
    const location = useLocation();
    const {showBanner} = useBanner();

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {universityId} = useParams();
 
    const [originalData, setOriginalData] = useState({});
    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');

    const [formObj, setFormObj] = useState({
      academicGPA: '',
      admissionClosingDate: null,
      applicantReplyDate: null,
      applicationUrl: '',
      earlyDecision: null,
      notificationSentDate: null,
      satScore: '',
      toeflScore: '',
      ieltsScore:'',
  });

    const handleFormChange = (e)=>{
        const { name, value } = e.target;
        setFormObj({
        ...formObj,
        [e.target.name]: e.target.value
        });
    }

    const handleOpen = ()=>{
        setOpen(true)
        getAdmissionInfo();
    }
    
    const handleClose = ()=>{
        setOpen(false);
        setFormObj(originalData);
    }

    const getAdmissionInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities/${universityId}/admissions`,{withCredentials:true})
        .then((response)=>{
            let originalInfo = response.data.payload;
            let setFormData = {
                academicGPA: originalInfo.academicGPA || '',
                admissionClosingDate: originalInfo.admissionClosingDate || '',
                applicantReplyDate: originalInfo.applicantReplyDate || '',
                applicationUrl: originalInfo.applicationUrl || '',
                earlyDecision: originalInfo.earlyDecision || '',
                notificationSentDate: originalInfo.notificationSentDate || '',
                satScore: originalInfo.satScore || '',
                toeflScore: originalInfo.toeflScore || '',
                ieltsScore: originalInfo.ieltsScore || '',
            }
            setOriginalData(setFormData);
            setFormObj(setFormData);
        })
        .catch((error)=>{
          console.log(error)
          }   
        )
    }

    const modifyIfUpdated = (originalData, updatedData)=>{
        let modifiedData = {};
        Object.keys(updatedData).forEach((key)=>{
            if(updatedData[key] !== originalData[key]){
              modifiedData[key] = updatedData[key];
            }
        });
        return modifiedData;
    }

    const handleUpdateUniversity = async ()=>{

      let modifiedData = modifyIfUpdated(originalData,formObj);

      if(Object.keys(modifiedData).length === 0 && modifiedData.constructor === Object){
        showBanner("No changes made","bg-green-300");
        setOpen(false);
        return;
      }

      await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/counselor/universities/${universityId}/admissions`,modifiedData,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          showBanner("Admission Info Updated Successfully","bg-green-400");
          setOpen(false);
          refreshData()
        }
      })
      .catch((error)=>{
        console.log("error in updating admission info",error)
      })
  }


  return (
    <>
    <button 
      onClick={handleOpen}
      className='mx-1 text-slate-700'>
      <PencilSquareIcon className='h-6 w-6 '/>
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                <div className='lg:w-[30rem] font-semibold'>
                {/* content */}
                  <div className="mt-3 text-center sm:mt-5 w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          <p className='text-center text-xl py-2'>Edit Admission Information</p>
                      </Dialog.Title>
                  </div>
                  
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="satScore" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">SAT</label>
                    <input
                    type="number"
                    name="satScore"
                    id="satScore"
                    value={formObj.satScore}
                    onChange={handleFormChange}
                    className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                    placeholder="Enter SAT Score"
                    />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="toeflScore" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">TOEFL</label>
                    <input
                    type="number"
                    name="toeflScore"
                    id="toeflScore"
                    value={formObj.toeflScore}
                    onChange={handleFormChange}
                    className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                    placeholder="Enter TOEFL Score"
                    />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="toeflScore" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">IELTS</label>
                    <input
                      type="number"
                      name="ieltsScore"
                      id="ieltsScore"
                      value={formObj.ieltsScore}
                      onChange={handleFormChange}
                      className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                      placeholder="Enter IELTS Score"
                      />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="academicGPA" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Academic GPA</label>
                    <input
                    type="number"
                    name="academicGPA"
                    id="academicGPA"
                    value={formObj.academicGPA}
                    onChange={handleFormChange}
                    className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                    placeholder="Enter Academic GPA"
                    />
                  </div>
                  
                  <div className='grid grid-cols-3 px-2 my-1 space-x-3'>
                      <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Closing Date</label>
                      <ReactDatePicker
                        className='block py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64'
                        selected={formObj.admissionClosingDate}
                        onChange={(date)=>handleFormChange({target:{name:'admissionClosingDate',value:date}})}
                      />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-1 space-x-3'>
                      <label htmlFor="earlyDecision" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Early Decision</label>
                      <ReactDatePicker
                        className='block py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64'
                        selected={formObj.earlyDecision}
                        onChange={(date)=>handleFormChange({target:{name:'earlyDecision',value:date}})}
                      />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-1 space-x-3'>
                      <label htmlFor="notificationSentDate" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Notification Date</label>
                      <ReactDatePicker
                        className='block py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64'
                        selected={formObj.notificationSentDate }
                        onChange={(date)=>handleFormChange({target:{name:'notificationSentDate',value:date}})}
                      />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="applicantReplyDate" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Applicant Reply Date</label>
                    <ReactDatePicker
                      className='block py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64'
                      selected={formObj.applicantReplyDate}
                      onChange={(date)=>handleFormChange({target:{name:'applicantReplyDate',value:date}})}
                    />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="applicationUrl" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Application Website</label>
                    <input
                    type="text"
                    name="applicationUrl"
                    id="applicationUrl"
                    value={formObj.applicationUrl}
                    onChange={handleFormChange}
                    className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                    placeholder="Application Website"
                    />
                  </div>
                </div>
                <div>
                  <p className='text-green-500 font-semibold'>{message}</p>
                  <p className='text-red-500 font-semibold'>{warning}</p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={handleUpdateUniversity}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Confirm
                  </button>
                </div>
                </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  )
}
