import React,{useState,useContext,useEffect} from 'react'
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { useLocation,useNavigate, useParams } from 'react-router-dom';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import Layout from '../../../../components/Layout';
import EventEditGeneralInfo from './steps/Event_EditGeneralInfo';
import EventEditAttendance from './steps/Event_EditAttendance';
import { EditEventFormContext,EditEventFormDispatchContext } from './providers/EditEvent_FormContext';
import { EditEventFormRequiredFieldsDispatchContext } from './providers/EditEvent_FormValidationContext';
import { Link } from 'react-router-dom';
import { useBanner } from '../../../../../services/SystemBannerMessage';
import { XCircleIcon } from '@heroicons/react/20/solid';

export default function CounselorEditEvent() {
  
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const navigate = useNavigate();
  const location = useLocation();
  const {showBanner} = useBanner();

  const {eventId} = useParams()

  const eventFormState = useContext(EditEventFormContext);
  const validationDispatch = useContext(EditEventFormRequiredFieldsDispatchContext);

  const [emptyFields, setEmptyFields] = useState([]);

  const [currentActive,setCurrentActive] = useState("");
  // console.log(eventId)

  const [steps, setSteps] = useState([
    {id:0, name:"General Information",selected:true, isCompleted:false,fields:['eventName','remark','location','eventDateTime','eventEndTime','signUpDeadline'],emptyFieldsCount:0},
    {id:1, name:"Attendance",selected:true, isCompleted:false,fields:['selectedUniversities','selectedStudents'],emptyFieldsCount:0},
])

  const handleChangePage = (pageIndex)=>{
    let copySteps = [...steps];             //switch to that step index
    copySteps.forEach((step)=>{
      step.selected = false
    })
    copySteps[pageIndex].selected = true;   // find existing current and reset 
    setCurrentActive(copySteps[pageIndex].name);
    setSteps(copySteps)
  }

  const handleFormChange = (pageName)=>{
    switch (pageName) {
      case "General Information":
        return <EventEditGeneralInfo />
      case "Attendance":
        return <EventEditAttendance />
      default:
        return null;
    }
  }
  const getCurrentPageIndex = (currentPage)=>{
    let currentIndex;
    steps.forEach((page)=>{ 
      if(currentPage === page.name) currentIndex = page.id;
    })
    return currentIndex
  }

  const handleNextPage = (currentPage) =>{
    let currentIndex= getCurrentPageIndex(currentPage);
    if(currentIndex < 1) handleChangePage(currentIndex+1);
  }

  const handlePreviousPage = (currentPage) =>{
    let currentIndex= getCurrentPageIndex(currentPage);
    if(currentIndex >0) handleChangePage(currentIndex-1);
   }

   const checkEmptyFields = (step)=>{
    let hasEmptyFields = emptyFields.some((field)=>{
      return step['fields'].includes(field)
    })
    // console.log("has empty fields: "+hasEmptyFields)
    return hasEmptyFields
  }

  const checkState =()=>{
    console.log(eventFormState)
    console.log(emptyFields)
  }
  const handleSubmit = async ()=>{ 
    for(const [key,value] of Object.entries(eventFormState)){
      validationDispatch({
        type:"submitCheckEmpty",
        currentField: key
      })
    }
    let formHasEmptyValues = true;
    let copyEmptyFields = [...emptyFields];

    for(const [key,value] of Object.entries(eventFormState)){
      if(value === "" && (key !== "selectedUniversities" && key !== "selectedStudents")){
        formHasEmptyValues = true;         
          // if there's empty field, this would be true, 
        if(!copyEmptyFields.includes(key)){     
          //if the empty field isn't in the emptyFields array, add it
          copyEmptyFields.push(key)
        }
        setEmptyFields(copyEmptyFields);      
          // update the emptyFields array
        break;

      } else {        // if there's no empty field
        if(copyEmptyFields.includes(key)){          
          let reducedFields = copyEmptyFields.filter((field)=>field !== key);  
          // if the empty field is in the emptyFields array, remove it
          setEmptyFields(reducedFields);        
          // update the emptyFields array
        }
          formHasEmptyValues = false;
      }
    }

    if(!formHasEmptyValues){
      let form = new FormData();
      for(const [key,value] of Object.entries(eventFormState)){
        if(key === "selectedUniversities"|| key === "selectedStudents"){
          let ids = value.map((item)=>{
            return item.id
          })
          form.append(key,ids)
        }else{
          form.append(key,value)
        }
      }
      await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/counselor/events/${eventId}`,form,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          navigate(`/school/counselor/events/${eventId}`)
        }
        showBanner("Event Updated","bg-green-400")
      })
      .catch((error)=>{
        console.log(error)
      })
    }else{
      console.log("form is not ready to proceed");
      console.log(emptyFields)
    }
  }

  useEffect(()=>{
    
    setCurrentActive("General Information")
    handleChangePage(0)
    console.log(emptyFields)
  },[])
 
  return (
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 bg-white p-1 '>
        <nav aria-label="Progress">
          <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            <li className="relative md:flex md:flex-1">
            <Link to={`/school/counselor/events/${eventId}`} >
              <div className="group flex w-36 items-center px-2 py-2 text-sm font-medium hover: cursor-pointer ">
                <XCircleIcon className='w-12 h-12 text-orange-400 transition delay-50 group-hover:rotate-90 group-hover:text-red-500' />
                <span className="ml-1 text-sm font-semibold text-grey-600">Cancel</span>
              </div>
            </Link>
              <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none" >
                <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke"
                  stroke="currentcolor" strokeLinejoin="round" />
                </svg>
              </div>
            </li>
            {
              steps.map((step, stepIdx) => {
              return (
              <li 
                  key={step.name}  onClick={()=>handleChangePage(stepIdx)} 
                  className="relative md:flex md:flex-1">
                <div className="group flex w-full items-center px-2 py-2 text-sm font-medium hover: cursor-pointer ">
                  {
                  step.selected?
                  <>
                      <span className={`transition delay-150 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-green-500 ${checkEmptyFields(step)?"border-red-500":""} hover:rotate-180`}>
                        <span className={`text-green-600 font-semibold `}>{step.id +1}</span>
                        {/* {step.id +1} */}
                      </span> 
                      <span className="ml-1 text-lg font-semibold text-green-600">{step.name.toUpperCase()}</span>
                  </>
                  :
                  <>
                      <span className={`transition delay-150 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400 ${checkEmptyFields(step)?"border-red-500":""}`}>
                      <span className={`font-semibold `}>{step.id +1}</span>
                      {/* {step.id +1} */}
                      </span>
                      <span className="ml-1 text-lg font-medium tex . . t-gray-600">{step.name}</span>
                  </>
                  }
                </div>
                {stepIdx !== steps.length - 1 ? (
                    <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                        <svg  className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none"  preserveAspectRatio="none" >
                          <path  d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke"
                            stroke="currentcolor" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    </>
                  ) : null}
              </li>
              )}) 
            }
            </ol>
        </nav>
        {/* actual form here */}
        <div className="space-y-6 sm:space-y-4 p-3">
        {/* lg:h-[580px] md:h-[480px] */}
          {handleFormChange(currentActive) }
        </div>  

        {/* Previous and Next button */}
        <div className="space-y-6 sm:space-y-1 p-3">
            <div className='flex gap-x-10 justify-center items-center'>

            <button className='transition delay-100 w-64 font-semibold px-5 py-2 rounded-lg border hover:bg-green-500 shadow-sm' onClick={()=>handlePreviousPage(currentActive)}>Previous</button>
            {
                getCurrentPageIndex(currentActive) === steps.length -1?
                <button className='transition delay-100 w-64 font-semibold px-5 py-2 rounded-lg border hover:bg-blue-500 hover:text-white shadow-sm' onClick={handleSubmit} >Submit</button> :
                <button className='transition delay-100 w-64 font-semibold px-5 py-2 rounded-lg border hover:bg-sky-500 shadow-sm ' onClick={()=>handleNextPage(currentActive)}>Next</button>
            }
            </div>
        </div>
      </div>
      {/* <button className='w-64 font-semibold px-5 py-2 rounded-sm border hover:bg-green-500 shadow-sm' onClick={checkState}>Check State</button> */}
     </div>
    </Layout>
  )
}