import React,{useState,useEffect,useContext} from 'react';
import { useLocation,useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';
import Layout from '../../../../../components/Layout';
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';
import Counselor_EditSchoolInformation from '../../../counselorComponents/EditUniversity/Edit_SchoolInformation';
import BasisForSelection from './BasisForSelection';
import EditAdmissionInfo from '../../../counselorComponents/EditUniversity/Edit_AdmissionInfo';
import moment from 'moment';
import EditAdmissionStats from '../../../counselorComponents/EditUniversity/Edit_AdmissionStats';
import { useBanner } from '../../../../../../services/SystemBannerMessage';
import EditNonAcademicBasis from '../../../counselorComponents/EditUniversity/Edit_NonAcademicBasis';
import EditAcademicBasis from '../../../counselorComponents/EditUniversity/Edit_AcademicBasis';
import EditBasicInformation from '../../../counselorComponents/EditUniversity/Edit_BasicInformation';
import SeparatorLine from '../../../../schoolComponents/SeparatorLine';
import Counselor_UploadUniversityLogo from '../../../counselorComponents/Counselor_UploadUniversityLogo';
import { InformationCircleIcon,ChartBarIcon,AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid';

export default function CounselorUniversityDetails() {
  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {universityId} = useParams();
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const location = useLocation();
  const navigateTo = useNavigate();
  const {showBanner} = useBanner();

  const [university, setUniversity] = useState({});
  const [acceptanceRate , setAcceptanceRate] = useState(0);

  const calculateAcceptanceRate = (university)=>{
    const totalApplicants = university.university_admission?.totalApplicants;
    const totalAdmitted = university.university_admission?.totalAdmitted;
    if(totalApplicants && totalAdmitted){
      const rate = (Math.round(totalAdmitted/totalApplicants*100));
      setAcceptanceRate(rate); 
    }
  }
  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
};

  const getUniversityDetails = async()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities/${universityId}`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setUniversity(response.data.payload);
        calculateAcceptanceRate(response.data.payload);
      }
    })
    .catch((error)=>{
      navigateTo('/school/counselor/universities',{state:{from: location},replace:true})
      showBanner("University not found","bg-yellow-400")
    })
  }


  const admissionClosingDate = university.university_admission?.admissionClosingDate? moment(university.university_admission?.admissionClosingDate).format("MMM Do"): "N/A";

  const earlyDecisionDate = university.university_admission?.earlyDecision?moment(university.university_admission?.earlyDecision).format("MMM Do"): "N/A"

  const notificationSentDate = university.university_admission?.notificationSentDate?moment(university.university_admission?.notificationSentDate).format("MMM Do") : "N/A"

  useEffect(()=>{
    getUniversityDetails(); 
  },[]);

  useEffect(()=>{
    document.title = university.schoolName;
  },[university])

  return (
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-lg px-3 lg:mx-10'>
        <div className="lg:grid grid-cols-3 lg:space-x-3">
          <div className="divide-y divide-gray-100 rounded-lg col-span-2">
            <div id='basic-profile-section' className=' bg-white shadow-sm '>
              <div className="sm:px-0 bg-blue-500 rounded-t-lg py-1 flex">
                <p className="text-lg font-semibold text-white inline-block items-center mx-3 w-full">School Info</p>
                <Counselor_EditSchoolInformation refreshData={getUniversityDetails}/>
              </div>
              <div id='basic-info' className='px-3 py-2 sm:grid grid-cols-3'>
              <div className='sm:col-span-2'>
                  <div className="px-2 py-1 sm:gap-4 sm:px-3">
                    <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-3xl my-2 text-indigo-500">{university?.schoolName}</p>
                  </div>
                  
                  <div className="px-2 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">City</p>
                    <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{university?.city}</p>
                  </div>
                  <div className="px-2 py-1 sm:grid grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">State</p>
                    <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{university?.state}</p>
                  </div>
                  <div className="px-2 py-1 sm:grid grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">Country</p>
                    <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{university?.country}</p>
                  </div>
                  <div className="px-2 py-1 sm:grid grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">Address</p>
                    <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{university?.streetAddress}</p>
                  </div>
                  <div className="px-2 py-1 sm:grid grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">Zip</p>
                    <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{university?.zipCode}</p>
                  </div>
                  <div className="px-2 py-1 sm:grid grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">motto</p>
                    <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {university?.motto}
                    </p>
                  </div>
                  <div className="px-2 py-1 sm:grid grid-cols-3 sm:gap-4 sm:px-3">
                    <p className="font-medium leading-6 text-gray-900">Website</p>
                    <p className=" font-semibold  leading-6 text-blue-500 sm:col-span-2 sm:mt-0 text-blue-500">
                      <a href={university?.website}>
                        {university?.website}
                      </a>
                    </p>
                  </div>
                </div>
                <div className='sm:col-span-1 grid justify-items-center '>
                  <div className='h-48 w-48 my-2'>
                    <img
                      className={`inline-block object-cover rounded-full h-full w-full shadow-md border-2 border-teal-50`}
                      src={university?.profilePicture}           
                      alt=""
                    />
                  </div>
                  <Counselor_UploadUniversityLogo refreshSchool={getUniversityDetails} />
                </div>
              </div>
            </div>
            <div id='admission-section' >
            {/* admission and statistics */}
              <div className='flex justify-between gap-x-3 justify-center mt-2'>
                  <p className='font-semibold text-lg mx-2 text-blue-600'>Admission</p>
              </div>
{/* scores */}
              <div className='grid lg:grid-cols-2 md:grid-cols-2 gap-x-3'> 
                <div className='shadow-sm rounded-lg bg-white my-2 border-gray-100 '>
                  <div className="px-2 sm:px-0 rounded-t-lg py-2 flex justify-between bg-lime-400/75">
                    <InformationCircleIcon className="h-7 w-7 "/>
                    <p className="text-lg font-semibold inline-block items-center w-full"> Information</p>
                    <EditAdmissionInfo refreshData={getUniversityDetails}/>
                  </div>
                  <div className='divide-y divide-gray-100 shadow-sm rounded-lg bg-white p-2'>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">SAT</p>
                      <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.satScore || "N/A"}</p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">TOEFL</p>
                      <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.toeflScore || "N/A"}</p>
                    </div>
                    <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">IELTS</p>
                      <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.ieltsScore || "N/A"}</p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Academic GPA</p>
                      <p className=" font-semibold leading-6 text-blue-500 sm:col-span-2 sm:mt-0 text-center ">{university.university_admission?.academicGPA || "N/A"}</p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Closing Date</p>
                      <p className="font-semibold leading-6 text-blue-500 sm:col-span-2 sm:mt-0 text-center">
                        {   admissionClosingDate   }
                      </p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Early Decisions</p>
                      <p className="font-semibold leading-6 text-blue-500 sm:col-span-2 sm:mt-0 text-center ">
                        {   earlyDecisionDate   }
                      </p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Notification Date</p>
                      <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">
                        {   notificationSentDate  }
                      </p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Applicant Reply Date</p>
                      <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">
                        {
                          university.university_admission?.applicantReplyDate?
                          moment(university.university_admission?.applicantReplyDate).format("MMM Do") : "N/A"
                        }
                      </p>
                    </div>
                    <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                      <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Application Website</p>
                      <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.applicationUrl || "N/A"}</p>
                    </div>
                  </div>
                </div>

                <div className='shadow-sm rounded-lg bg-white my-2'>
                  <div className="divide-y divide-gray-100">
                    <div className="py-2 px-3 rounded-t-lg sm:px-0 flex justify-between bg-sky-300">
                      <ChartBarIcon className="h-7 w-7"/>
                      <p className="text-lg font-semibold inline-block items-center w-full">Statistics</p>
                      <EditAdmissionStats refreshData={getUniversityDetails}/>
                    </div>
                    <div className='p-2 bg-white rounded-b-lg divide-y divide-gray-100'>
                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Total Applicants</p>
                        <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.totalApplicants || "N/A"}</p>
                      </div>

                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Total Admitted </p>
                        <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.totalAdmitted || "N/A"}</p>
                      </div>
                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Wait List</p>
                          <p className="font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.waitList?"Yes":"No"}</p>
                      </div>
                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Wait List Ranked</p>
                          <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.waitListRanked? "Yes":"No"}</p>
                      </div>
                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Wait List Admitted</p>
                          <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.waitListAdmitted || "N/A"}</p>
                        </div>
                      {/* <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">International students</p>
                        <p className=" text-sm font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_admission?.totalApplicants || "N/A"}</p>
                      </div> */}
                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Application Fee</p>
                          <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center" >{university.university_admission?.applicationFee || "N/A"}</p>
                      </div> 

                      <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Acceptance Rate</p>
                        <p className=" font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{acceptanceRate} %</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
{/* Admission info end----------------------------> */}
{/* Basis for Selection info start----------------------------> */}

            <div id='basis-selection-section' >
              <div className='flex justify-between gap-x-3 justify-center '>
                <p className='font-semibold text-lg mx-2 text-blue-600'>Basis For Selection</p>
              </div>
              <div className='grid lg:grid-cols-2 md:grid-cols-2 gap-x-3'> 
                <div className='shadow-sm rounded-lg bg-white my-2'>
                  <div className="divide-y divide-gray-100">
                    <div className='py-2 px-3 rounded-t-lg sm:px-0 flex justify-between bg-cyan-300'>
                      <AdjustmentsHorizontalIcon className="h-7 w-7 "/>
                      <p className="text-lg font-semibold inline-block items-center w-full">Academic Factors</p>
                      <EditAcademicBasis refreshData={getUniversityDetails} />
                    </div>
                    <div className='border-gray-100 p-2 divide-y divide-gray-100'>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2 ">Rigor Of Secondary School Record</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.rigorOfRecord} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Class Rank</p>
                          <BasisForSelection basisForSelection={university.university_requirement?.classRank} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium leading-6 text-gray-900 sm:col-span-2">Academic GPA</p>
                          <BasisForSelection basisForSelection={university.university_requirement?.academicGPAImportance} />
                      </div>                
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Standardized Test Scores</p>
                          <BasisForSelection basisForSelection={university.university_requirement?.satImportance} />
                      </div>                
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Application Essay</p>
                          <BasisForSelection basisForSelection={university.university_requirement?.applicationEssay} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                          <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Recommendations</p>
                          <BasisForSelection basisForSelection={university.university_requirement?.recommendations} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='shadow-sm rounded-lg bg-white my-2'>
                  <div className="border-gray-100 ">
                    <div className='py-2 px-3 rounded-t-lg sm:px-0 flex justify-between bg-emerald-300'>
                      <AdjustmentsVerticalIcon className="h-7 w-7 "/>
                      <p className="text-lg font-semibold inline-block items-center w-full">Non Academic Factors</p>
                      <EditNonAcademicBasis refreshData={getUniversityDetails} />
                    </div>
                    
                    <div className='p-2 bg-white rounded-b-lg divide-y divide-gray-100'>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Interview</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.interview} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Extracurricular Activities</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.extracurricular} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Talent / Ability</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.talentAbility} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Character Personal Qualities</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.characterPersonalQuality} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">First Generation </p>
                        <BasisForSelection basisForSelection={university.university_requirement?.firstGeneration} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Volunteer Work</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.volunteerWork} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Alumni Relation</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.alumniRelation} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">State Residency</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.stateResidency} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Geographical Residency</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.geographicalResidence} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Work Experience</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.workExperience} />
                      </div>
                      <div className="px-2 py-2 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Level Of Applicants Interest</p>
                        <BasisForSelection basisForSelection={university.university_requirement?.levelOfInterest} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div id='side-column' className="divide-y divide-gray-100 rounded-xl col-span-1 ">
            <div className="sm:px-0 rounded-t-lg py-1 flex">
              <p className="text-xl font-semibold inline-block items-center px-4 w-full text-blue-600 bg-">
              Basic Information</p>
              <EditBasicInformation refreshData={getUniversityDetails}/>
            </div>
            <div className='px-3 py-2 space-y-3'>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                  <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Source of Institutional Control</p>
                  <p className="text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_basic_info?.institutionalControl ||"N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                  <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">UnderGrad Classification</p>
                  <p className="text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_basic_info?.undergradClassification ||"N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Total Undergraduates</p>
                <p className="text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{formatNumber(university.university_basic_info?.totalUndergrads) ||"N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Total Graduate</p>
                <p className=" text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{formatNumber(university.university_basic_info?.totalGrads) ||"N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Total International Student</p>
                <p className=" text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{formatNumber(university.university_basic_info?.totalInternationalStudent) ||"N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Total Students</p>
                <p className=" text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{formatNumber(university.university_basic_info?.totalStudents) || "N/A"}</p>
              </div>
              {/* <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Degree Offered</p>
                <p className="text-sm font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_basic_info?.institutionalControl ||"N/A"}</p>
              </div> */}
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">In-State</p>
                <p className="text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">${formatNumber(university.university_basic_info?.inStateTuition)|| "N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Out-Of-State</p>
                <p className="text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">${formatNumber(university.university_basic_info?.outOfStateTuition)|| "N/A"}</p>
              </div>
              <div className="px-2 py-1 grid grid-cols-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                <p className="font-medium font-roboto text-sm leading-6 text-gray-900 sm:col-span-2">Financial Aid</p>
                <p className="text-base font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-center">{university.university_basic_info?.financialAid?"YES":"No"}</p>
              </div>
            </div>
            <SeparatorLine margin={2} />
            <div className='px-3 py-2 space-y-3'>
                <p className='font-semibold'>
                  This school is liked by 300 students
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}
