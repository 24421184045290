import React from 'react'
import {Outlet } from "react-router-dom";
import useAuthenticationContext from "../hooks/authStateHook";
import NotFound404 from '../pages/404';

export default function SuperAdminAccess() {
    const {authState} = useAuthenticationContext();

  return (
    authState.role === "superAdmin"?<Outlet/> :<NotFound404 />
  )
}
