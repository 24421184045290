import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import UnAuthLayout from './components/UnAuthLayout';
import axios from '../api/axios';

export default function StudentActivationBuffer() {
    const {activationToken} = useParams();
    const navigateTo = useNavigate();
    const [loadSpinner, setLoadSpinner] = useState('none');
    const [success, setSuccess] = useState(false);
    const [activationMessage, setActivationMessage] = useState('');

    const handleActivation = async ()=>{
        setLoadSpinner('block');
        
        await axios.get(`/api/auth/activate/student/${activationToken}`)
        .then((response)=>{
            if(response.status === 200){
                setSuccess(true);
                setActivationMessage('Your account is activated')
            }
            setTimeout(()=>{
                navigateTo('/');
            },3000)
            
        })
        .catch((error)=>{
            console.log(error)
        })
        .finally(()=>{
            setLoadSpinner('none')
        })
    }
    useEffect(()=>{
        handleActivation();
    },[])
  return (
    <>
    <UnAuthLayout>
    <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {success?
                <div>
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Your account is activated
                    </h2>
                    <p>You will be redirected to the home page in 3 seconds</p>
                    <p>if not, please click here</p>
                    <Link to={'/'}>
                        <p>Home</p>
                    </Link>
                </div>
            :   
                <div>
                    <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-blue-500'>
                        Activation failed. Please contact the administrator
                    </h2>
                </div>
            }
            <svg display={loadSpinner} className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
            </svg>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        </div>
    </div>
    </UnAuthLayout>
    </>
  )
}
