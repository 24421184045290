import React,{useState, useContext, useEffect} from 'react'
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { useLocation,useNavigate,Link } from 'react-router-dom';

import Layout from '../../../../components/Layout';

import EventGeneralInformation from './steps/EventStep_GeneralInformation';
import EventAttendance from './steps/EventStep_Attendance';

import { NewEventFormContext, NewEventFormDispatchContext } from './providers/EventForm_Context';

import { EventFormRequiredFieldsDispatchContext } from './providers/EventFormValidation_Context';

export default function CounselorCreateEvent() {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const navigate = useNavigate();
    const location = useLocation();

    const eventFormState = useContext(NewEventFormContext);
    const eventFormDispatch = useContext(NewEventFormDispatchContext);
    const validationDispatch = useContext(EventFormRequiredFieldsDispatchContext);

    const [emptyFields, setEmptyFields] = useState([]);

    const [currentActive,setCurrentActive] = useState("");

    const [steps, setSteps] = useState([
        {id:0, name:"General Information",selected:true, isCompleted:false, fields:['eventName','remark','location','eventDateTime','eventEndTime','signUpDeadline'],emptyFieldsCount:0},
        {id:1, name:"Attendance",selected:true, isCompleted:false, fields:['selectedUniversities','selectedStudents'],emptyFieldsCount:0},
    ])

    const handleChangePage = (pageIndex)=>{
        let copySteps = [...steps];             //switch to that step index
        copySteps.forEach((step)=>{
          step.selected = false
        })
        copySteps[pageIndex].selected = true;   // find existing current and reset 
        setCurrentActive(copySteps[pageIndex].name);
        setSteps(copySteps)
      }
 
      const handleFormChange = (pageName)=>{
        switch (pageName) {
          case "General Information":
            return <EventGeneralInformation />
          case "Attendance":
            return <EventAttendance />
          default:
            return null;
        }
      }

      const getCurrentPageIndex = (currentPage)=>{
        let currentIndex;
        steps.forEach((page)=>{ 
          if(currentPage === page.name) currentIndex = page.id;
        })
        return currentIndex
      }

      const handleNextPage = (currentPage) =>{
        let currentIndex= getCurrentPageIndex(currentPage);
        if(currentIndex === 0) handleChangePage(currentIndex+1);
      }
      const handlePreviousPage = (currentPage) =>{
        let currentIndex= getCurrentPageIndex(currentPage);
        if(currentIndex === 1) handleChangePage(currentIndex-1);
       }

      const checkState =()=>{
        console.log(eventFormState)
      }

      const checkEmptyFields = (step)=>{            // this is just for css styling
        let hasEmptyFields = emptyFields.some((field)=>{
          return step['fields'].includes(field)
        })
        return hasEmptyFields
      }

    const handleSubmit = async ()=>{
      for(const [key,value] of Object.entries(eventFormState)){
        validationDispatch({
          type:"submitCheckEmpty",
          currentField: key
        })
      }

      let formHasEmptyValues = true;
      let copyEmptyField = [...emptyFields];

      for(const [key,value] of Object.entries(eventFormState)){
        formHasEmptyValues = false;
        if(value === "" && (key !== "selectedUniversities" && key !== "selectedStudents")){
          formHasEmptyValues = true;

          if(!copyEmptyField.includes(key)){
            copyEmptyField.push(key)
          }
          setEmptyFields(copyEmptyField)
          break;

        } else {
          if(copyEmptyField.includes(key)){
            let reducedFields = copyEmptyField.filter((field)=>field !== key);
            setEmptyFields(reducedFields);
          }
          formHasEmptyValues = false;
        }
    }

    if(!formHasEmptyValues){
      const form = new FormData();
      for(const [key,value] of Object.entries(eventFormState)){
          if(key ==="selectedUniversities" || key ==="selectedStudents"){
              let ids = value.map((item)=>{
                  return item.id
              })
              form.append(key,ids)
          } else{
              form.append(key,value)
          }
          // console.log(key,value)
      }
      await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/counselor/new-event`,form,{withCredentials:true})
        .then((response)=>{
            if(response.status === 201){
              const eventId = response.data.payload.id
                navigate(`/school/counselor/events/${eventId}`,{state:{from: location},replace:true})
            }
      })
      .catch((error)=>{
            console.log(error)
        })
      }
    }

useEffect(()=>{
    setCurrentActive("General Information")
    handleChangePage(0);
    eventFormDispatch({type:"clearAll"})
},[])

useEffect(()=>{
  document.title = "Schoolley | Create Event"
})

  return (
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl px-1 mx-10'>
        <nav aria-label="Progress">
            <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {
                steps.map((step, stepIdx) => {
                return (
                <li 
                    key={step.name}  onClick={()=>handleChangePage(stepIdx)} 
                    className="relative md:flex md:flex-1">
                  <div className="group flex w-full items-center px-2 py-2 text-lg font-medium hover: cursor-pointer bg-white">
                    {
                    step.selected?
                    <>
                        <span className={`transition delay-100 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-green-500 ${checkEmptyFields(step)?"border-red-500":""}`}>
                        <span className={`transition delay-100 text-green-600 font-semibold`}>{step.id +1}</span>
                        {/* {step.id +1} */}
                        </span> 
                        <span className="ml-1 text-base font-semibold text-green-600">{step.name.toUpperCase()}</span>
                    </>
                    :
                    <>
                        <span className={`transition delay-100 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400 ${checkEmptyFields(step)?"border-red-500":""}`}>
                        <span className={`font-semibold `}>{step.id +1}</span>
                        {/* {step.id +1} */}
                        </span>
                        <span className="ml-1 text-lg font-medium text-gray-600 ">{step.name}</span>
                    </>
                    }
                  </div>
                {
                  stepIdx !== steps.length - 1 ? (
                    <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                        <svg  className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none"  preserveAspectRatio="none" >
                        <path  d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke"
                            stroke="currentcolor" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    </>
                  ) : null
                }
                </li>
                )}) 
            }
            </ol>
        </nav>

        {/* actual form here */}
        <div className="space-y-6 sm:space-y-4 py-3 ">
          {handleFormChange(currentActive) }
        </div>  

        {/* Previous and Next button */}
        <div className="space-y-6 sm:space-y-1 p-3">
          <div className='transition delay-100 flex gap-x-10 justify-center items-center'>
            {
              getCurrentPageIndex(currentActive) === 0?
              <Link to={`/school/counselor/events`} >
                <button className='transition delay-100 w-full font-semibold px-5 py-3 rounded-sm border bg-gray-300 hover:bg-gray-200 shadow-sm' >CANCEL</button> 
              </Link>
              :
              <button className='transition delay-100 w-full font-semibold px-5 py-3 rounded-sm border bg-yellow-300 hover:bg-yellow-400 shadow-sm' onClick={()=>handlePreviousPage(currentActive)}>PREVIOUS</button>
            }
            {
              getCurrentPageIndex(currentActive) === steps.length -1?
              <button className='transition delay-100 w-full font-semibold px-5 py-3 rounded-sm border bg-indigo-500 text-white hover:bg-indigo-600 shadow-sm' onClick={handleSubmit} >SUBMIT</button> :
              <button className='transition delay-100 w-full font-bold px-5 py-3 rounded-sm border bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm' onClick={()=>handleNextPage(currentActive)}>NEXT</button>
            }
          </div>
        </div>
      </div>
      {/* <button className='w-64 font-semibold px-5 py-2 rounded-sm border hover:bg-green-500 shadow-sm' onClick={checkState}>Check State</button> */}
     </div>
    </Layout>
  )
}