import React,{ createContext, useContext, useReducer, useState,useEffect } from "react";
import axiosWithInterceptor from "../../../../hooks/axiosInterceptor";
import { AuthenticationContext } from "../../../../services/AuthContextModule";

import { useParams } from 'react-router-dom';
import moment from 'moment';

export const SchoolEditEventFormContext = createContext(null);
export const SchoolEditEventFormDispatchContext = createContext(null);

export default function SchoolEditEventFormProvider({children}) {

    const [formState, dispatch] = useReducer(formReducer, initialForm);
 
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {eventId} = useParams();

    const loadEventData = async ()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/events/${eventId}`,{withCredentials:true})
       .then((response)=>{
            if(response.status === 200){
                dispatch(
                    {
                        type:'initialize',
                        content: response.data.payload
                    }
                )}
            }
       )
       .catch((error)=>{
           console.log("error in loading existing data",error)
           }   
       )
     }


    useEffect(()=>{
        loadEventData()
    },[])

    return (
        <SchoolEditEventFormContext.Provider value={formState}>
            <SchoolEditEventFormDispatchContext.Provider value={dispatch}>
                {children}
            </SchoolEditEventFormDispatchContext.Provider>
        </SchoolEditEventFormContext.Provider>
  )
}

export function formReducer(eventForm, action){

    switch (action.type){
        case 'initialize':{
            let eventFormCopy = {...eventForm}
            let originalData = action.content;
         
            const generalInfo = originalData;
            const invitations = originalData.invitations;
            
            eventFormCopy.eventName = generalInfo.eventName;
            eventFormCopy.remark = generalInfo.remark;
            eventFormCopy.location = generalInfo.location;
            eventFormCopy.eventType = generalInfo.eventType;
            eventFormCopy.eventCategory_id = generalInfo.eventCategory_id;
            eventFormCopy.eventDateTime = moment(generalInfo.eventDateTime).format(); 
                // Convert to local timezone
            eventFormCopy.eventEndTime = moment(generalInfo.eventEndTime).format(); 
                // Convert to local timezone
            eventFormCopy.signUpDeadline = moment(generalInfo.signUpDeadline).format(); 
                // Convert to local timezone

            eventFormCopy.selectedStudents = invitations.map((invitation)=>{return invitation.student});
            
            eventFormCopy.openToAll = generalInfo.openToAll;
            eventFormCopy.inviteParents = generalInfo.inviteParents;
            eventFormCopy.addGoogleCalendar = generalInfo.addGoogleCalendar;

            return eventFormCopy
        }
        case 'changed':{
            let eventFormCopy = {...eventForm}
            eventFormCopy[action.name] = action.content
            return eventFormCopy
        }
        case 'clear':{
            let eventFormCopy = {...eventForm}
            eventFormCopy[action.name] = ""
            return eventFormCopy
        }
        case 'toggleGoogleCalendar':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['addGoogleCalendar'] =!eventFormCopy['addGoogleCalendar']
            return eventFormCopy
        }
        case 'toggleInviteParent':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['inviteParents'] = !eventFormCopy['inviteParents']
            return eventFormCopy
        }
        case 'toggleOpenToAll':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['openToAll'] = !eventFormCopy['openToAll']
            return eventFormCopy
        }

        case 'clearAll':{
            let eventFormCopy = {...eventForm}
            eventFormCopy["eventName"] = "";
            eventFormCopy["remark"] = "";
            eventFormCopy["eventType"] = "";
            eventFormCopy["location"] = "";
            eventFormCopy["eventDateTime"] = "";
            eventFormCopy["eventCategory_id"] = "";
            eventFormCopy["eventEndTime"] = "";
            eventFormCopy["signUpDeadline"] = "";
            eventFormCopy["eventEndTime"] = "";
            eventFormCopy["inviteParents"] = false;
            eventFormCopy["addGoogleCalendar"] = false;
            eventFormCopy["openToAll"] = false;
            return eventFormCopy
        }
        case 'dateTimeChanged':{
            let eventFormCopy = {...eventForm}
            // let dateTimeInfo = new Date(action.content);
            let dateTimeInfo =   moment(action.content).format(); ;
     
            // compare to other
            if(action.name === "eventDateTime"){
                if(dateTimeInfo > moment().format()){
                    eventFormCopy[action.name] = action.content;
                    if(dateTimeInfo > eventForm.eventEndTime){
                        eventFormCopy.eventEndTime = ""
                    }
                }else{
                    console.log("visit date time not correct")
                }
            }else if(action.name === "eventEndTime"){
                if(dateTimeInfo > moment(eventForm.eventDateTime).format()){
                    eventFormCopy[action.name] = action.content;
                }else{
                    console.log("visit end time not right")
                }
            }else if(action.name === "signUpDeadline"){
                if(dateTimeInfo < moment(eventForm.eventDateTime).format()){
                    eventFormCopy[action.name] = action.content;
                }else{
                    console.log("sign up deadline time not rite")
                }
            }
            return eventFormCopy
        }
        case 'inviteStudents':{
            let eventFormCopy = {...eventForm};
            let newStudents = action.content;
            newStudents.forEach((newStudent)=>{
                if(!eventFormCopy.selectedStudents.some(prevStudent=>prevStudent.id === newStudent.id)){
                    eventFormCopy.selectedStudents.push(newStudent)
                }
            })
            return eventFormCopy
        }
        case 'unInviteStudents':{
            let eventFormCopy = {...eventForm};
            let removingStudents = action.content;

            eventFormCopy.selectedStudents = eventFormCopy.selectedStudents.filter((prevStudent)=>{
                return !removingStudents.some((student)=>{
                    return student.id === prevStudent.id
                })
            })
            return eventFormCopy
        }
        default:{
            throw Error('Unknown action '+ action.type)
        }
    }
}

const initialForm = {
    eventName:"",
    remark:"",
    eventType:"",
    location:"",
    eventDateTime:"",
    eventEndTime:"",
    signUpDeadline:"",
    inviteParents:false,
    addGoogleCalendar:false,
    openToAll:false,
    eventCategory_id:"",
    selectedStudents:[],
}