import React, { useEffect,useContext, useState, useRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css';

import { EditEventFormContext, EditEventFormDispatchContext } from '../providers/EditEvent_FormContext';
import { EditEventFormRequiredFieldsContext,EditEventFormRequiredFieldsDispatchContext } from '../providers/EditEvent_FormValidationContext';
import moment from 'moment';

export default function EditEventDeadlinePicker() {
 
    const editEventFormState = useContext(EditEventFormContext);
    const dispatch = useContext(EditEventFormDispatchContext);
    const requiredFields = useContext(EditEventFormRequiredFieldsContext);
    const formCheckDispatch = useContext(EditEventFormRequiredFieldsDispatchContext);
    const [deadlineOptions, setDeadlineOptions] = useState([
        {id:"option1", value: 1, label:"1 Hour Before", selected:false},
        {id:"option2", value: 24, label:"1 Days Before", selected:false},
        {id:"option3", value: 72, label:"3 Days Before", selected:false},
        {id:"option4", value: 168, label:"1 Week Before", selected:false},
    ]);

    const presetOptions = ()=>{
        let originalDeadline = new Date(editEventFormState.signUpDeadline);
        let eventDateTime = new Date(editEventFormState.eventDateTime);
        let timeDifference = (eventDateTime - originalDeadline)/60/60/1000;
           
        let optionsCopy = [...deadlineOptions];
        if(timeDifference === 1){
            optionsCopy[0].selected = true;
        } else if (timeDifference === 24){
            optionsCopy[1].selected = true;
        }else if (timeDifference === 72){
            optionsCopy[2].selected = true;
        }else if (timeDifference === 168){
            optionsCopy[3].selected = true;
        }
        setDeadlineOptions(optionsCopy);
    }

    const handleSelection = (optionId)=>{

        let eventDateTime = new Date(editEventFormState.eventDateTime);
        let updateTime = new Date(eventDateTime);

        const optionsCopy = [...deadlineOptions];

        optionsCopy.forEach((option)=>{

            if(optionId === option.id){
                let setTime = new Date(updateTime.setHours(updateTime.getHours() - option.value));
                let today = new Date();
                if(setTime > today){
                    option.selected = true;
                    dispatch({
                        type:"changed",
                        name:"signUpDeadline",
                        content: moment(setTime).format()
                    })
                }
            }else{
                option.selected = false;
            }
        });
        setDeadlineOptions(optionsCopy);
    }
    
    useEffect(()=>{
        presetOptions()
    },[editEventFormState]);
     
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2 py-3">
        <label htmlFor= "" className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            Sign Up Deadline
        </label>
        <div className='sm:col-span-2 sm:mt-0 flex'>
        {
            deadlineOptions.map((option,index)=>{
                return (
                    <button 
                        key={option.id} 
                        value={option.value}
                        onClick={()=>handleSelection(option.id)}
                        className={`transition delay-100 inline-flex items-center px-2 py-1 my-0 mx-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-green-600 hover:text-slate-300 focus:z-10 rounded ${option.selected? "bg-green-600 text-white":""}`}>{option.label.toUpperCase()}</button>
                )
            })
        }
        </div>
        <p className='px-2 text-red-500 text-xs' hidden={requiredFields['signUpDeadline']}> Sign Up Deadline can not be empty</p>
        <p className='px-2 text-red-500 text-xs' hidden={true}> Deadline can not be empty</p>
    </div>
  )
}
