import React, { useEffect,useContext, useState, useRef} from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { EditEventFormContext, EditEventFormDispatchContext } from '../providers/EditEvent_FormContext';
import moment from 'moment';

export default function EditEventFormDatePicker() {
    const editEventFormState = useContext(EditEventFormContext);
 
    const [startDate, setStartDate] = useState();

    const dispatch = useContext(EditEventFormDispatchContext);

    const handleSelectDate = (date)=>{
      let formattedDate = moment(date).format();
      setStartDate(date)
      handleContextDateTime(formattedDate)
    }
    const handleContextDateTime = (dateTime)=>{
        dispatch({
            type:"dateTimeChanged",
            name:"eventDateTime",
            content: dateTime
        });
        // formCheckDispatch({
        //     type:"submitCheckEmpty",
        //     currentField: "eventDateTime"
        // })
      }

      useEffect(() => {
        if (editEventFormState.eventDateTime) {
            setStartDate(new Date(editEventFormState.eventDateTime));
        }
    }, [editEventFormState.eventDateTime]);

  return (
    <>
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2 ">
       <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            Event Date Time
        </label>
      <ReactDatePicker className='px-3 py-1 font-semibold text-sky-800 rounded-2xl bg-teal-500 text-white'
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
          selected={startDate}
          onChange={(date) => handleSelectDate(date)}
          monthsShown={2}
          minDate={new Date()}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={10}
          timeCaption="time"
          dateFormat="MMM d, yyyy h:mm aa"
        />
    </div>
    </>
  )
}
