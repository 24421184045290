import React, {  Fragment,useContext, useEffect, useState,useRef } from 'react'
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor'
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { PlusIcon, XMarkIcon,CheckCircleIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition } from '@headlessui/react'

export default function CounselorAddMoreStudents({existingInvitations,eventId, refreshVisitDetails}) {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  // search and select
  const [searchText, setSearchText] = useState("");

  const [allStudentsList, setAllStudentsList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  const [addedStudents, setAddedStudents] = useState([]);
  
  const [selectedIndex, setSelectedIndex] = useState(-1);

  // 
  const resultCard = useRef(null);
  const inputRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [listHidden, setListHidden] = useState(true);

  // methods

  const handleOpenModal = ()=>{
    setOpenModal(true);
  }

  const handleCloseModal = ()=>{
    setOpenModal(false);
    setAddedStudents([]);
  }

  const handleClearSelection =()=>{
    setAddedStudents([]);
  }

  const handleSearchText = (e)=>{
    const text = e.target.value;
    setSearchText(e.target.value);
    
    const tempList = allStudentsList.filter((student) =>
      student?.firstName.toLowerCase().includes(text.toLowerCase())
    )
    setListHidden(text === '');
    setFilteredList(tempList)
  }

  const selectAStudent = (newStudent)=>{
    const tempList = [...addedStudents];
    const ifAlreadyInvited = existingInvitations.some((student)=> student.invitee_id === newStudent.id);
    // check if student already exist in the list.
    if(!ifAlreadyInvited){
      !addedStudents.some(student => student.id === newStudent.id) && tempList.push(newStudent);

      setAddedStudents(tempList);
      setSearchText("");
      setListHidden(true);
    }
  }

  const checkIfInvited = (newStudent)=>{
    return existingInvitations.some((student)=> student.invitee_id === newStudent.id);
  }

  const removeSelectedStudent = (aStudent) => {
    let tempList = [...addedStudents];
    let removedList = tempList.filter((student)=>{
      return student.id !==aStudent.id;
    })
    setAddedStudents(removedList)
  }

  const getAllStudents = async()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/students`)
    .then((response)=>{
      if(response.status === 200){
        setAllStudentsList(response.data.payload);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  const handleSubmit = async()=>{
    if(addedStudents.length >0){
      let studentIds = [];
      addedStudents.forEach((student)=>{
        studentIds.push(student.id);
      });

      const form = new FormData();

      form.append("addedStudents",studentIds)
  
      await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/counselor/events/${eventId}/add-students`,form,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          refreshVisitDetails();
          setAddedStudents([]);
          setSearchText("");
          setOpenModal(false)
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  }

const handleKeydown = (e) => {
  const { key } = e;
  let newIndex;

  if (key === "ArrowUp") {
    e.preventDefault();
    newIndex = (selectedIndex - 1 + filteredList.length) % filteredList.length;
    setSelectedIndex(newIndex);
  } else if (key === "ArrowDown") {
    e.preventDefault();
    newIndex = (selectedIndex + 1) % filteredList.length;
    setSelectedIndex(newIndex);
  } else if (key === "Enter") {
    e.preventDefault();
    if (selectedIndex >= 0 && selectedIndex < filteredList.length) {
      selectAStudent(filteredList[selectedIndex]);
    }
  } else if(key === "Escape"){
    e.preventDefault();
    setListHidden(true)
    setSearchText("")
  }
};

  useEffect(()=>{
    getAllStudents();

  },[])

  useEffect(()=>{
    if(openModal){
      inputRef.current?.focus();
    }
  },[openModal]);

  useEffect(() => {
    const resultElement = resultCard.current;
    if (resultElement) {
      resultElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selectedIndex]);

  return (
   <>
   <div className='px-0'>
    <button className="h-7 w-7 mx-2 p-1 rounded relative font-semibold" onClick={handleOpenModal}>
      <PlusIcon />
    </button>

    <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseModal}
      // initialFocus={cancelButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 py-4 text-left shadow-xl transition-all w-[600px]">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                  <div className='my-2 flex justify-end'>
                    <button className='w-5 h-5' onClick={handleCloseModal}>
                        <XMarkIcon />
                    </button>
                  </div>
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Search Students
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='flex flex-wrap px-3 py-1 gap-x-4 gap-y-2'>
                        {
                          addedStudents.map((student)=>{
                            return (
                              <div key={student.id} className='flex justify-between items-center px-2 py-1 border-1 border-cyan-500 rounded text-xs font-semibold gap-x-3 shadow-sm hover:bg-slate-200'>
                                <div>
                                  <p  className=''>{student?.firstName} {student.lastName}</p>
                                </div>
                                <div className='cursor-pointer h-4 w-4 flex justify-center items-center' onClick={()=>{removeSelectedStudent(student)}}>
                                  <XMarkIcon />
                                </div>
                              </div>
                            )})
                        }
                      </div>
                      <div 
                        onKeyDown={handleKeydown} 
                        className='relative mt-6 flex items-center py-2 px-3 gap-x-4 '>
                        <input
                          ref={inputRef}
                          className="text-sm w-full rounded border-0 py-1.5 px-2 bg-slate-50"
                          value={searchText}
                          onChange={handleSearchText}
                          placeholder='Student name'
                          />
                        <ul 
                          hidden = {listHidden} className="absolute top-full bg-white border-2 rounded lg:w-2/3 h-48 overflow-y-auto z-10">
                            {
                              filteredList.length > 0? filteredList.map((student,index)=>{
                                const itemClasses = index === selectedIndex?'bg-slate-200':'';
                                return (
                                  <li
                                    key={student.id} 
                                    ref={index === selectedIndex ? resultCard : null}
                                    // className={`list-group-item-hover list-group-item-action`}
                                    className={`p-2 text-left cursor-pointer border-b hover:bg-teal-200 text-xs font-semibold ${itemClasses}`}
                                    onClick={()=>selectAStudent(student)}
                                    >
                                    <div className='flex justify-between'>
                                      <p>
                                      {student?.firstName} {student?.lastName}
                                      </p>
                                      {checkIfInvited(student)? <div className='h-4 w-4 text-green-500'>
                                      <CheckCircleIcon />
                                      </div>:null}
                                    </div>
                                  </li>
                                )} ): <li className='p-2 text-left cursor-pointer border-b hover:bg-teal-200 text-xs font-semibold'>No student found</li>
                            }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={handleSubmit}
                  >
                    Invite
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={handleClearSelection}
                    // ref={cancelButtonRef}
                  >
                    Clear
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   </div>
    
   </>
  )
}
