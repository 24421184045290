import React,{useState,useEffect,useContext} from 'react';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { useBanner } from '../../../../services/SystemBannerMessage';

export default function StudentsGradeOptions({studentInformation,refreshStudentInfo}) {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const staffId = authState.user;
    const schoolId = authState.schoolId;
    const {showBanner} = useBanner();
    const currentUserRoles = authState.role;

    const [gradeLevels, setGradeLevels] = useState([
        {id:"grade4",gradeLevel:4,selected:false},
        {id:"grade5",gradeLevel:5,selected:false},
        {id:"grade6",gradeLevel:6,selected:false},
        {id:"grade7",gradeLevel:7,selected:false},
        {id:"grade8",gradeLevel:8,selected:false},
        {id:"grade9",gradeLevel:9,selected:false},
        {id:"grade10",gradeLevel:10,selected:false},
        {id:"grade11",gradeLevel:11,selected:false},
        {id:"grade12",gradeLevel:12,selected:false},
    ]);

    const handleGradeSelection = (value)=>{
        let copyGradeLevels = [...gradeLevels];
        copyGradeLevels.forEach((grade)=>{
            if(grade.gradeLevel === value){
                grade.selected = true;
            }else {
                grade.selected = false;
            }
        });
        setGradeLevels(copyGradeLevels);
    } 

    const handleUpdateGrade = async(value)=>{
        await useAxiosWithInterceptor.put(`api/user/${staffId}/school/${schoolId}/administrator/students/${studentInformation.id}`,{gradeLevel:value},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner(`GradeLevel of ${studentInformation.firstName} has been updated`,'bg-green-400');
                refreshStudentInfo();
                handleGradeSelection(value);
            }
        })
        .catch((error)=>{
            console.log(error);
            showBanner("an error has occurred","bg-yellow-400");
        })
    }

    useEffect(()=>{
        handleGradeSelection(studentInformation?.gradeLevel);
    },[studentInformation])
  return (
    <div>
    {
        gradeLevels.map((grade)=>{
            return (
                <button 
                key={grade.id}
                onClick={()=>handleUpdateGrade(grade.gradeLevel)}
                className={`transition delay-50 rounded-sm ring-1 ring-inset h-6 w-7 m-0.5 font-semibold ${grade.selected? "bg-blue-500 text-white":""} hover:bg-blue-400 hover:text-white`}
                >
                {grade.gradeLevel}
                </button>
            )
        })
    }
    </div>
  )
}
