import React, { useState,useContext, useEffect } from 'react';
import Layout from '../../../components/Layout';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Papa from 'papaparse';
import { Link, useLocation,useNavigate } from 'react-router-dom';

import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { useBanner } from '../../../../services/SystemBannerMessage';
import { set } from 'react-hook-form';

export default function AdministratorOnboardStudents() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const {showBanner} = useBanner();
  const navigate = useNavigate();
  const location = useLocation();

  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileName, setFileName] = useState('Upload a CSV file');
  const [existingStudents, setExistingStudents] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [hideForm,setHideForm] = useState(true);
  const [hideBatchForm,setHideBatchForm] = useState(true);


  const [gradeLevels, setGradeLevels] = useState([
    {id:"grade4",gradeLevel:4,selected:false},
    {id:"grade5",gradeLevel:5,selected:false},
    {id:"grade6",gradeLevel:6,selected:false},
    {id:"grade7",gradeLevel:7,selected:false},
    {id:"grade8",gradeLevel:8,selected:false},
    {id:"grade9",gradeLevel:9,selected:false},
    {id:"grade10",gradeLevel:10,selected:false},
    {id:"grade11",gradeLevel:11,selected:false},
    {id:"grade12",gradeLevel:12,selected:false},
]);

  const handleGradeSelection = (value)=>{
    let copyGradeLevels = [...gradeLevels];
    copyGradeLevels.forEach((grade)=>{
        if(grade.gradeLevel === value){
            grade.selected = true;
            setNewStudentInfo({...newStudentInfo,gradeLevel:value})
            console.log(newStudentInfo)
        }else {
            grade.selected = false;
        }
    });
    setGradeLevels(copyGradeLevels);
  } 

  const clearGradeSelection = ()=>{
    let copyGradeLevels = [...gradeLevels];
    copyGradeLevels.forEach((grade)=>{
        grade.selected = false;
    });
    setGradeLevels(copyGradeLevels);
  }

  // single onboard
  const [newStudentInfo, setNewStudentInfo] = useState({
    firstName:"",
    lastName:"",
    email:"",
    gradeLevel:"",
    school_id:schoolId,
    role:'student'
  })

  const handleShowForm = ()=>{
    setHideForm(!hideForm);
    setHideBatchForm(true);
  }

  const handleShowBatchForm = ()=>{
    setHideBatchForm(!hideBatchForm);
    setHideForm(true);
  }

  const [studentExistAlert,setStudentExistAlert] = useState(true);

  const handleNewStudentInfo = (e)=>{
    e.preventDefault();
    setStudentExistAlert(true)
    setNewStudentInfo({...newStudentInfo,[e.target.name]:e.target.value})
  }

  const createNewStudent = async(e)=>{
    e.preventDefault();
    for (const [key, value] of Object.entries(newStudentInfo)) {
      if(value === ""){
        alert(`Please fill in ${key} field`);
        return;
      }
      if(key === "email"){
        if(!value.includes("@")){
          alert("Please enter a valid email address");
          return;
        }
      }
    }

    if(existingStudents.find((teacher)=>teacher.email === newStudentInfo.email)){
      setStudentExistAlert(false)
      return;
      // alert(`Staff with email ${newAdminInfo.email} already exist`);
    }

    await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/administrator/students/new-student`,{newStudentInfo},{withCredentials:true})
    .then((response)=>{
      if(response.status === 201){
        setHideForm(true);
        setNewStudentInfo({
          firstName:"",
          lastName:"",
          email:"",
          password:"",
          school_id:schoolId,
          gradeLevel:"",
        });
        clearGradeSelection();
        showBanner("New Student Created","bg-green-500")
        getExistingStudents();
      }
    }).catch((error)=>{
      console.log(error)
    })
}

  // batch on board

  const handleFileChange = (e)=>{
    const file = e.target.files[0];

    if(file){
      const validFileTypes = ['text/csv', 'application/vnd.ms-excel']; // Add valid MIME types for CSV files
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (!validFileTypes.includes(file.type) || fileExtension !== 'csv') {
        showBanner("Please upload a valid CSV file.","bg-yellow-500");
        return;
      }
      
      const reader = new FileReader();
      reader.onload = (e)=>{
        const text = e.target.result;
        Papa.parse(text, { 
          header: true,
          complete: (result)=>{
            let cleanStudentData = [];
            let studentData = result.data;
            for(let i = 0; i < studentData.length; i++){
              if(!studentData[i].Email|| !studentData[i].FirstName|| !studentData[i].LastName|| !studentData[i].Grade){
                continue;
              }
              studentData[i].completed = false;
              studentData[i].exist = false;
              studentData[i]['uploaded'] = false;

              for(let j = 0; j < existingStudents.length; j++){
                if(studentData[i].Email === existingStudents[j].email){
                  studentData[i]['exist'] = true;
                }
              }
              cleanStudentData.push(studentData[i]);
            }
            setCsvData(cleanStudentData);
          },
        });
      }
      reader.readAsText(file);      
      setFileName(file.name);       // the name that appears when uploaded
    }
  }

  const handleRemoveStudent = (email)=>{
    let updatedData = csvData.filter((student)=>{
      return student.Email !== email;
    });
    setCsvData(updatedData);
  }

  const handleCsvUpload = async ()=>{
    if(csvData.length === 0){ 
      console.log("data 0")
      return; 
    }       // if it's empty

    if(!authState.role.includes("administrator")){ return ;} // if it's not a school admin

    await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/administrator/students/onboard`, csvData,{withCredentials: true})
    .then((response)=>{
      if(response.status === 200){
        setUploadComplete(true);

        let newlyAddedStudents = response.data.payload;
        let copyCsvData = [...csvData];
        copyCsvData.forEach((csvStudent)=>{
          for(let i = 0; i < newlyAddedStudents.length; i++){
            if(csvStudent.Email === newlyAddedStudents[i].email){
              csvStudent.uploaded = true;
            }
          }
          csvStudent.completed = true;
        });
        setCsvData(copyCsvData);
        // navigate('/school/schoolAdmin/students/onboard/success',{state:{newlyAddedStudents},replace:true})
        showBanner("Students onboard successful","bg-green-500");
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  const getExistingStudents = ()=>{
    useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/students`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setExistingStudents(response.data.payload);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    document.title = "Onboard Students";
    getExistingStudents();
  }
  ,[]);
    
  return (
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
      <div id='individual-onboard' className='mt-2 flow-root shadow rounded-2xl'>
        <p className={`transition ease delay-100 w-full px-3 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold cursor-pointer ${hideForm?"rounded-xl":"rounded-t-xl"}`} onClick={handleShowForm}>
          Create New Student Account <span className='text-red-500 mx-3 bg-white rounded px-3 py-1' hidden={studentExistAlert}>Student With This Email {newStudentInfo.email} Already Exist</span>
        </p>
        <div className='transition ease-in-out delay-150 p-3' hidden={hideForm}>
          <form >
            <div className='py-4 lg:grid grid-cols-2 '>
              <div className='col-span-1 flex flex-col w-full '>
                <input type='text' name='firstName' onChange={handleNewStudentInfo} value = {newStudentInfo['firstName']} placeholder='First Name' className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                <input type='text' name='lastName' onChange={handleNewStudentInfo} value={newStudentInfo['lastName']} placeholder='Last Name' className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                <input type='email' name='email' onChange={handleNewStudentInfo} value={newStudentInfo['email']} placeholder='Email' className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                <input type='password' name='password' onChange={handleNewStudentInfo} value={newStudentInfo['password']} placeholder='Password' className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                
                <div className='flex space-x-2 my-2'>
                  <p className='font-semibold'>Grade Level:</p>
                  <div className='flex'>
                  {
                      gradeLevels.map((grade)=>{
                          return (
                              <p key={grade.id}
                              onClick={()=>handleGradeSelection(grade.gradeLevel)}
                              className={`transition delay-50 cursor-pointer rounded-sm ring-1 ring-inset h-6 w-7 m-0.5 font-semibold text-center ${grade.selected? "bg-blue-500 text-white":""} hover:bg-blue-400 hover:text-white`}
                              >
                              {grade.gradeLevel}
                              </p>
                          )
                      })
                  }
                  </div>
                </div>

                <button onClick={createNewStudent} className='transition ease-in-out delay-50 bg-indigo-500 hover:bg-blue-700 text-white font-bold py-2 sm:w-3/4 w-full rounded my-2'>Create</button>
              </div>

              <div className='col-span-1 px-3 space-y-2 font-semibold'>
                <p className='text-xl '>New Student Information</p>
                <p >First Name: <span className='mx-5 text-lg text-indigo-600'>{newStudentInfo.firstName}</span></p>
                <p>Last Name: <span className='mx-5 text-lg text-indigo-600'>{newStudentInfo.lastName}</span></p>
                <p>Email: <span className='mx-5 text-lg text-indigo-600'>{newStudentInfo.email}</span></p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={`w-full border-t border-gray-300 my-5`}  /> 

      <div id='batch-onboard' className='mt-2 flow-root shadow rounded-2xl'>
        <p className={`transition ease delay-100 w-full px-3 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold cursor-pointer ${hideBatchForm?"rounded-xl":"rounded-t-xl"}`} onClick={handleShowBatchForm}>Onboard Students</p>
        <div className='transition ease-in-out delay-150 p-3' hidden={hideBatchForm}>
        
        <div className='bg-white rounded-lg px-3 py-2 mb-4 space-y-2' >
            <p className='font-semibold text-xl'>Instruction:</p>
            <div className='pl-3 space-y-2 '>
              <p>Step 1: Download this <a href='/downloads/onboard.csv' className='font-semibold text-blue-500' download>CSV File</a></p>
              <p>Step 2: Fill in students' first name, last name, email addresses</p>
              <p>Step 3: Upload the completed csv file</p>
              <p>Step 4: Click Upload</p>
            </div>
        </div>
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="col-span-full">
              {/* <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Upload Spreadsheet 
              </label> */}
            <div className="mt-2 flex justify-center rounded-lg  px-6 py-10 bg-white ">
              <div className="text-center ">
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600 ">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>{fileName}</span>
                      <input 
                        id="file-upload" 
                        name="file-upload" 
                        type="file" accept='.csv' className="sr-only" 
                        onChange={handleFileChange} />
                    </label>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">CSV File</p>
              </div>
            </div>
          </div>
        </form>
        <div className='px-3 '>
          <p className='font-semibold text-sm text-indigo-500'>count: {csvData.length} users</p>
        </div>
        <div className="rounded-lg shadow-sm border-1 my-2 overflow-x-auto bg-white">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    First Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Last Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Grade
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {csvData.map((student,index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-2.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {student.FirstName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-500">{student.LastName}</td>
                    <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-500">{student.Email} {student.exist?<p className='text-xs font-semibold text-red-500'>Already Exist</p>:""}</td>
                    <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-500">{student.Grade}</td>
                    <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-500">
                    <div className='flex '>
                      {
                        student.completed? null :
                        <button className="px-2 rounded-sm transition ease delay-100 text-orange-500 font-semibold hover:scale-[1.05] " onClick={()=>handleRemoveStudent(student.Email)}>Remove</button>
                      }
                      {
                        student.uploaded?
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-green-500 mx-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          :null
                      }
                    </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='my-2 sm:mt-0 sm:flex-none'> 
          {
            uploadComplete ? 
            <div className='flex justify-between my-3 p-3'>
              <p className='font-semibold text-teal-500'>Upload Completed!</p>
              <Link to='/school/students'>
                <p className='font-semibold text-amber-600 px-3 py-2 rounded ring-2'> Back to Students Page</p>
              </Link>
            </div>: 
            <button className={`w-44 block rounded-md px-8 py-2 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${csvData.length === 0 ? "bg-gray-500 ":"bg-indigo-600 hover:bg-indigo-500"}`} onClick={handleCsvUpload} disabled={csvData.length === 0 }>Upload</button>
          }
        </div>
        </div>
      </div>
      </div>
    </div>
    </Layout>
  )
}
