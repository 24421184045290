import React from 'react'
import Select from 'react-select';
import {getNames } from 'country-list';
import makeAnimated from 'react-select/animated';

export default function CountryPicker({handleSelect}) {
    const formattedOptions = getNames().map((country)=>{
        return {value:country,label:country}
    })
    return (
      <Select
          closeMenuOnSelect={true}
          options= {formattedOptions}
          onChange={handleSelect}
      />
    )
}
