import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBanner } from '../../../../../services/SystemBannerMessage';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import EditCountryPicker from './Edit_CountryPicker';

export default function Admin_EditSchoolInformation({refreshData}) {
    const [open, setOpen] = useState(false)
    const navigateTo = useNavigate();
    const location = useLocation();
    const {showBanner} = useBanner();

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {universityId} = useParams();

    const [formObj, setFormObj] = useState({
        schoolName: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        streetAddress: '',
        website: 'https://',
        motto: '',
    });

    const [originalData, setOriginalData] = useState({});

    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');

    const handleFormChange = (e)=>{
        const { name, value } = e.target;
        if (name === 'website') {
            if (!value.startsWith('https://')) {
                // Prevent the user from removing the "https://"
                return;
            }
        }
        setFormObj({
        ...formObj,
        [e.target.name]: e.target.value
        });
    }


    const handleCountryChange = (selectedOption) => {
        setFormObj({
            ...formObj,
            country: selectedOption.value
        });
    }

    const handleOpen = async ()=>{
        await getUniversityInfo();
        setOpen(true);
    }
    const handleClose = ()=>{
        setOpen(false);
        setFormObj(originalData);
    }

    const modifyIfUpdated = (originalData, updatedData)=>{
        let modifiedData = {};
        Object.keys(updatedData).forEach((key)=>{
            if(updatedData[key] !== originalData[key]){
              modifiedData[key] = updatedData[key];
            }
        });
        return modifiedData;
    }

    const handleUpdateUniversity = async ()=>{
        if(formObj.schoolName === '' || formObj.country === '' || formObj.state === '' || formObj.city === '' || formObj.website === '' ){
            setWarning('Please fill all fields')
            return;
        }

        let modifiedData = modifyIfUpdated(originalData, formObj);

        // const updatedUniversityInfo = {
        //     schoolName: formObj.schoolName,
        //     country: formObj.country,
        //     state: formObj.state,
        //     city: formObj.city,
        //     zipCode: formObj.zipCode,
        //     streetAddress: formObj.streetAddress,
        //     website: formObj.website,
        //     motto: formObj.motto,
        // }

        await useAxiosWithInterceptor.put(`/api/super-admin/universities/${universityId}/profile`,modifiedData,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("University information updated successfully","bg-green-400");
                setTimeout(()=>{
                    setOpen(false);
                },1000);
                refreshData();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getUniversityInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/universities/${universityId}/profile`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                let originalInfo = response.data.payload;
                let setFormData = {
                    schoolName: originalInfo.schoolName || '',
                    country: originalInfo.country,
                    state: originalInfo.state || '',
                    city: originalInfo.city || '',
                    zipCode: originalInfo.zipCode || '',
                    streetAddress: originalInfo.streetAddress || '',
                    website: originalInfo.website || '',
                    motto: originalInfo.motto || '',
                }
                setFormObj(setFormData);
                setOriginalData(setFormData);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }


  return (
    <>
    <button 
        onClick={handleOpen}
        className='mx-3 text-white'>
        <PencilSquareIcon className='h-6 w-6 '/>
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                <div>
                {/* content */}
                    <div className="mt-3 text-center sm:mt-5 w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        <p className='text-center text-xl py-2'>Edit University Information</p>
                        </Dialog.Title>
                    </div>

                    <label htmlFor="schoolName" className="block text-xs font-medium text-gray-900 mt-1 py-2">University Name </label>
                    <input
                        type="text"
                        name="schoolName"
                        id="schoolName"
                        value={formObj.schoolName}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter University Name"
                    />

                    <label htmlFor="city" className="block text-xs font-medium text-gray-900 mt-1 py-2">City </label>
                    <input
                        type="text"
                        name="city"
                        id="city"
                        value={formObj.city}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter city name"
                    />

                    <label htmlFor="city" className="block text-xs font-medium text-gray-900 mt-1 py-2">Street Address </label>
                    <input
                        type="text"
                        name="streetAddress"
                        id="streetAddress"
                        value={formObj.streetAddress}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter city name"
                    />

                    <label htmlFor="city" className="block text-xs font-medium text-gray-900 mt-1 py-2">Zip Code </label>
                    <input
                        type="text"
                        name="zipCode"
                        id="zipCode"
                        value={formObj.zipCode}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter city name"
                    />
                    
                    <label htmlFor="city" className="block text-xs font-medium text-gray-900 mt-1 py-2">Motto</label>
                    <input
                        type="text"
                        name="motto"
                        id="motto"
                        value={formObj.motto}
                        onChange={handleFormChange}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter city name"
                    />

                    <label htmlFor="state" className="block text-xs font-medium text-gray-900 mt-1 py-2">State / Province</label>
                    <input
                        type="text"
                        name="state"
                        id="state"
                        value={formObj.state}
                        onChange={handleFormChange}

                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter State / Province"
                    />

                    <label htmlFor="country" className="block text-xs font-medium text-gray-900 mt-1 py-2">Country</label>
                    <EditCountryPicker handleSelect={handleCountryChange} originalCountry={formObj.country} />

                    <label htmlFor="website" className="block text-xs font-medium text-gray-900 mt-1 py-2">Website</label>
                    <input
                        type="text"
                        name="website"
                        id="website"
                        value={formObj.website}
                        onChange={handleFormChange}

                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100"
                        placeholder="Enter Website"
                    />
                </div>
                <div>
                    <p className='text-green-500 font-semibold'>{message}</p>
                    <p className='text-red-500 font-semibold'>{warning}</p>
                </div>
                <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        onClick={handleUpdateUniversity}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Confirm
                    </button>
                </div>
                </Dialog.Panel>
            </Transition.Child>
            </div>
        </div>
      </Dialog>
    </Transition>
   </>
  )
}
