import React,{ createContext, useContext, useReducer, useState } from "react";

import { NewEventFormContext } from "./EventForm_Context";

export const EventFormRequiredFieldsContext = createContext(null);
export const EventFormRequiredFieldsDispatchContext = createContext(null);

export default function EventFormValidationProvider({children}) {
    const eventForm = useContext(NewEventFormContext);
    
    const [requiredFieldsState, dispatch] = useReducer((state,action)=> eventFormValidationReducer(state,action, eventForm), initialFormValidationState);

  return (
    <EventFormRequiredFieldsContext.Provider value={requiredFieldsState}>
        <EventFormRequiredFieldsDispatchContext.Provider value={dispatch}>
            {children}
        </EventFormRequiredFieldsDispatchContext.Provider>
    </EventFormRequiredFieldsContext.Provider>
  )
}

export function eventFormValidationReducer(eventFormState, action, newEventForm){
    switch(action.type){
        case "submitCheckEmpty":{
            let eventFormStateCopy = {...eventFormState};

            // console.log(action.currentField)
            // console.log(typeof newEventForm[action.currentField])
            // console.log(newEventForm[action.currentField].length)
            if(!newEventForm[action.currentField].length >0 || newEventForm[action.currentField] === null || newEventForm[action.currentField] === undefined){
                eventFormStateCopy[action.currentField] = false;
            }else{
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        case "inputLevelCheckEmpty": {
            let eventFormStateCopy = {...eventFormState};
            if(newEventForm[action.currentField].length >0){
                
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        default:{
            throw Error('Unknown action '+action.type)
        }
    }
}

let initialFormValidationState = {
    eventName:true,
    remark:true,
    // eventType:true,
    location:true,
    eventDateTime:true,
    eventEndTime:true,
    signUpDeadline:true,
    // selectedUniversities:true,
    // selectedStudents:true,
    // inviteParents:false,
}