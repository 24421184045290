import React, {  useState} from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { Dialog, Transition} from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function SuperAdmin_DeleteEventTypeButton({eventType, deleteEventType}) {

    const [open, setOpen] = useState(false)

  return (
    <>
      <button 
        onClick={()=>setOpen(true)}
        className='transition delay-100 px-3 py-2 hover:bg-slate-200 rounded-lg'>
          <XMarkIcon className='h-5 w-5 ' />
      </button>
      <Transition show={open}>
          <Dialog className="relative z-10" onClose={setOpen}>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:w-screen w-screen sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex items-center">
                        <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="my-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-red-500">
                            DELETE Event Type : <span className='text-blue-500 text-xl'> {eventType?.typeName}</span> 
                          </Dialog.Title>
                          <div className="my-3">
                            <p className="text text-gray-500">
                              Are you sure you want to delete event type
                              <span className='text-lg font-semibold text-blue-500'> {eventType?.typeName}</span> ?
                            </p>
                            <p className="text text-gray-500">
                              This action cannot be undone.
                            </p>
                          </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          deleteEventType(eventType?.id);
                          setOpen(false)}}
                      >
                        Delete Division
                      </button>
                      <button
                        type="button"
                        className=" inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        data-autofocus
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
    </>
  )
}
