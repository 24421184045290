import moment from 'moment';
import React, { useEffect } from 'react';
import {useState } from 'react';
import { Link } from 'react-router-dom';

export default function StudentListPopOver({event, status}) {
  const [show, setShow] = useState(false)
  const [studentList, setStudentList] = useState([])

  const handleStudentList = (visitData,status)=>{
    let copyList = [...studentList];
    visitData.invitations.forEach((invite)=>{
      if(invite.status === status){
        if(!copyList.some(item => item.id === invite.id)){
          copyList.push(invite)
        }
      } else if(status === "all"){
        if(!copyList.some(item => item.id === invite.id)){
          copyList.push(invite)
        }
      } 
    })
    setStudentList(copyList)
  }

  const getBgColor = (status, date) => {

    if (moment(date).isBefore(moment())) {
      return "bg-gray-500";
    }
  
    switch (status) {
      case "all":
        return "bg-blue-500";
      case "accepted":
        return "bg-green-500";
      case "declined":
        return "bg-red-600";
      case "pending":
        return "bg-amber-400";
      default:
        return ""; // or a default background color if needed
    }
  };
  
  // const gePastBgColor = (status)=>{
  //   if(status === "all") return "bg-blue-500";
  //   if(status === "accepted") return "bg-green-500";
  //   if(status === "declined") return "bg-red-600";
  //   if(status === "pending") return "bg-amber-400";
  // }
  
  useEffect(()=>{
    handleStudentList(event,status)
  },[])

  return (
  <>
  <div onMouseLeave={() => setShow(false)} >
    <button className={`transition delay-100 px-3 py-0.5 ${getBgColor(status,event?.eventDateTime)} $ rounded text-sm font-bold text-white`} onMouseEnter={()=>setShow(true)} >
      {
        status === "all" ? event.invitations.length :
        event.invitations.filter((invite)=>invite.status ===status).length
      }
    </button>
   
    <div className="mt-1 flex flex-col items-center space-y-4 sm:items-end">
      <div 
        hidden={!show}  
        className="absolute max-w-sm bg-white bg-opacity-90 overflow-hidden rounded shadow-lg ring-1 ring-black ring-opacity-5">
        <p className={`text-xs text-white ${getBgColor(status)} py-1 `}>{status.toUpperCase()}</p>
        <ul className='px-3 max-h-80 overflow-y-auto'>
          {
            studentList.length > 0 ? studentList.map((student,index)=>{
            return (
              <li className='flex py-1 px-2' key={index} >
                <Link to={`/school/students/${student?.student.id}`}>
                  <p className='text-xs text-start text-blue-400'>{student?.student.firstName} {student?.student.lastName}</p>
                </Link>
                <hr/>
              </li>
            )}) :(
            <p className='py-2 text-xs'>No {status} found.</p> 
            )
          }
        </ul>
      </div>
    </div>
  </div>
  </>
  )
}
