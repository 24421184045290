import React,{useContext} from 'react';
import { EditEventFormContext } from '../providers/EditEvent_FormContext';

import EditEventInputBox from '../components/EditEvent_InputBox';
import EditEventFormDatePicker from '../components/EditEvent_DatePicker';
import EditEventEndDatePicker from '../components/EditEvent_EndDatePicker';
import EditEventDeadlinePicker from '../components/EditEvent_DeadlinePicker';
import EditEventForm_OpenTwoAllToggle from '../components/EditEvent_OpenToAllToggle';
import EditEventGoogleToggle from '../components/EditEvent_GoogleToggle';
import EditEventParentsToggle from '../components/EditEvent_ParentsToggle';

export default function EventEditGeneralInfo() {
  
  const editEventForm = useContext(EditEventFormContext);

  return (
    <>
      <div className='flex justify-center'>
      </div>
      <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0 shadow-sm rounded-2xl px-3">
        <EditEventInputBox inputName={"Event Name"} inputType={"text"} attributeName={"eventName"} value={editEventForm?.eventName} />
        <EditEventInputBox inputName={"Remark"} inputType={"text"} attributeName={"remark"} value={editEventForm?.remark} rows={4} />
        <EditEventInputBox inputName={"Location"} inputType={"text"} attributeName={"location"} value={editEventForm?.location} />
        <EditEventFormDatePicker />
        <EditEventEndDatePicker />
        <EditEventDeadlinePicker />
        <EditEventGoogleToggle />
        <EditEventParentsToggle />
        <EditEventForm_OpenTwoAllToggle />
      </div>
    </>
  )
}
