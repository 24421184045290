import React,{useState,useEffect,} from 'react';
import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { useNavigate,useLocation,Link } from 'react-router-dom';

import useAuthenticationContext from '../../../../hooks/authStateHook';
import ProfileAvatar from '../../../components/Avatar';
import UserEditPhoto from '../../../components/UserEditPhoto';
import moment from 'moment';

export default function StudentProfile() {

    const [userInfo, setUserInfo] = useState();
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useAuthenticationContext();  
    const userId = authState.user;
    const navigateTo = useNavigate();
    const location = useLocation();

    const getUser = async ()=>{
        
        await useAxiosWithInterceptor.get(`/api/user/${userId}/profile`,{withCredentials:true})
          .then((response)=>{
            if(response.status === 200){
              console.log("hello")
              setUserInfo(response.data.payload);
            }
          }
          ).catch((err)=>{
            // setAuthState({})
            console.log(err)
            // navigateTo('/login',{state:{from: location},replace:true});
          });
      }
    
      useEffect(()=>{
        getUser();
      },[]);
  return (
    <Layout>
    <div className='md:w-full w-full '>
      <div className='bg-opacity-75 px-1 py-2 lg:mx-10'>
        <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl shadow-md bg-indigo-500'>
            <div id='student-basic' className=''>
              <div className='flex justify-center'>
                <ProfileAvatar user={userInfo} size={20}/>
              </div>
              <div className='text-center'>
                <UserEditPhoto refreshUser={getUser} />
              </div>
            </div>
            <div className=''>
              <div className='my-2'>
                  <p className='font-bold text-3xl text-white'>{userInfo?.firstName} {userInfo?.lastName}</p>
              </div>
              <div className='flex space-x-5 items-center text-white'>
                <p className='font-semibold text-lg'>Class of {calculateClassYear(userInfo?.gradeLevel)} (Grade {userInfo?.gradeLevel?userInfo.gradeLevel:"N/A"})</p>
              </div>
            </div>
          </div>
                    {/* basic info */}
          <div id='student-profile' className='rounded-lg shadow my-2 space-y-2 bg-white'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p >Basic Information</p>
            </div>

            <div className='lg:grid grid-cols-3 px-4'>
              <div id='column-one' className=''>
                <div className='flex space-x-5 items-center px-3 py-2'>
                  <p>Email:</p>
                  <p className='font-semibold'>{userInfo?.email}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2'>
                  <p>Member Since:</p>
                  <p className='font-semibold'>{userInfo?.createdAt.split("T")[0]}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2'>
                  <p className='font-semibold'>{userInfo?.school?.schoolName}</p>
                </div>
              </div>

              <div id='column-two'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p>Date of Birth:</p>
                  <p className='font-semibold'></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2'>
                  <p>Nationality:</p>
                  <p className='font-semibold'></p>
                </div>
              </div>
              <div id='column-three'>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                    <p>Father:</p>
                    <p></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                    <p>Email:</p>
                    <p></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                  <p>Mother:</p>
                  <p></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 font-semibold'>
                  <p>Email:</p>
                  <p></p>
                </div>
              </div>
            </div>
            
          </div>
          <div className='flex space-x-5 items-center px-3 py-2 '>
            <Link to={`/user/change-password`} className='px-2 py-1 bg-blue-500 rounded-lg text-white font-semibold text-sm'>Change Password</Link>
          </div>
          <div className='shadow-md rounded'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p>Activities</p>
            </div>
            <div className='px-3 py-2'>
                <table>
  
                </table>
            </div>
          </div>
        </div>
    </div>
    </Layout>
  )
}

const calculateClassYear = (currentGrade)=>{
    let graduationGrade = 12;
    let currentYear = moment().year();
    let classYear = currentYear + (graduationGrade - currentGrade);
    return classYear;
}