import React,{useState,useEffect,useContext} from 'react'
import Layout from '../../components/Layout';
import { Link } from 'react-router-dom';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import FavoriteButtonSM from '../studentComponents/FavoriteButtonSM';

export default function StudentFavorites() {

  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [allFavorites, setAllFavorites] = useState([]);

  const getAllFavorites = async()=>{

    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/favorites`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setAllFavorites(response.data.payload)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
  } 
  useEffect(()=>{
    getAllFavorites();
    document.title = "Favorite"
  },[])

  return (
    <Layout>
      <div className="w-full h-max bg-opacity-75">
      <div className='bg-opacity-75 px-1 py-2 lg:mx-10'>
      <h4 className='text-xl font-bold mb-2'> My Favorites ({allFavorites?.length})</h4>
      <div className="bg-teal-500 rounded-2xl">
        <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className='text-white rounded-t-2xl'>
            <tr className='text-white'>
              <th scope="col" className="py-2 px-3 text-sm font-semibold sm:pl-0">School</th>
              <th scope="col" className="hidden px-3 py-2 text-sm font-semibold sm:pl-0 sm:table-cell">City</th>
              <th scope="col" className="hidden px-3 py-2 text-sm font-semibold sm:pl-0 sm:table-cell">State/Province</th>
              <th scope="col" className="hidden px-3 py-2 text-sm font-semibold sm:pl-0 sm:table-cell">Country</th>
              <th scope="col" className="px-3 py-2 text-sm font-semibold sm:pl-0">Website</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {allFavorites.map((item,index)=>{
                return (
                  <tr className={`text-left hover:bg-teal-100 `} key={item?.university.id} >
                    <td className={`text-sm pl-4 py-2 font-semibold ${allFavorites.length-1 === index? "rounded-bl-2xl":""}`} >
                      <div className='flex items-center space-x-2'>
                        <Link to={`/student/universities/${item?.university.id}`}>
                          <p className='inline-block align-middle text-blue-600'>
                          {item?.university.schoolName}
                          </p>
                        </Link>
                        <FavoriteButtonSM universityId={item?.university.id} refreshFavorites={getAllFavorites}/>
                      </div>
 
                      <dl className="font-normal lg:hidden">
                        <dd className="mt-1 truncate text-gray-700 text-xs">{item?.university.city}</dd>
                        <dd className="mt-1 truncate text-gray-500 text-xs">{item?.university.country}</dd>
                      </dl>
                    </td>
                      <td className='hidden text-sm px-3 py-3.5 sm:table-cell'>{item?.university.city}</td>
                      <td className='hidden text-sm px-3 py-3.5 sm:table-cell'>{item?.university.state}</td>
                      <td className='hidden text-sm px-3 py-3.5 sm:table-cell'>{item?.university.country}</td>
                      <td className={`text-sm px-3 py-3.5 ${allFavorites.length-1 === index? "rounded-br-2xl":""}`}>{item?.university.website}</td>
                  </tr>
                )})
            }
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</Layout>
  )
}
