import React,{useContext,useState} from 'react';
import { SchoolNewEventFormContext, SchoolNewEventFormDispatchContext } from '../providers/SchoolEventFormContext';

import { SchoolNewEventFormRequiredContext, SchoolNewEventFormRequiredDispatchContext } from '../providers/SchoolEventFormValidationContext';

export default function SchoolEventInputBox({inputName,inputType, attributeName, value, rows}) {
    const eventForm = useContext(SchoolNewEventFormContext);
    
    const dispatch = useContext(SchoolNewEventFormDispatchContext);

    const requiredFields = useContext(SchoolNewEventFormRequiredContext);
    const formCheckDispatch = useContext(SchoolNewEventFormRequiredDispatchContext);

    const handleInputChange = (e)=>{
        dispatch({
            type:"changed",
            name:attributeName,
            content: e.target.value
        })
        formCheckDispatch({
            type:"submitCheckEmpty",
            currentField: attributeName
        })
    }

  return (
    <>
    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
        <label htmlFor= {attributeName} className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            {inputName}
        </label>
        <div className="sm:col-span-3 sm:mt-0 flex">
            <textarea 
                rows={rows?rows:1}
                type={inputType}
                className="px-2 block w-full rounded-md border-1 border-slate-400 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-slate-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-slate-100 resize-none"
                value={value}
                onChange={(e)=>{handleInputChange(e)}}
            />
            <p className='px-2 text-red-500 text-xs' hidden={requiredFields[attributeName]}> {inputName} can not be empty</p>
        </div>
    </div>
    </>
  )
}
