import React from 'react'

export default function SchoolAvatar({school}) {

    const image64 = `data:image/png;base64,${school?.profilePicture}`;
    
    return (
        <>
            {
                school?.profilePicture? 
                    <img
                    className="inline-block h-44 w-44 rounded-full object-cover shadow-md"
                    src={school?.profilePicture}           
                    alt=""
                    />
                :
                <img
                    className="inline-block h-28 w-28 rounded-full object-cover"
                    src="/placeholders/SchoolPlaceholder.png"
                    alt=""
                    />
            }
        </>
  )
}
