import React, { useContext, useEffect, useState} from 'react'
import { XMarkIcon, PencilIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import {AuthenticationContext } from '../../../../services/AuthContextModule';
import { useLocation,useNavigate} from "react-router-dom";

export default function EditCategoryButton({category,editCategory,defaultEventTypes}) {
  const [open, setOpen] = useState(false);

  const [formObj, setFormObj] = useState({
    categoryName: category?.categoryName,
    categoryDescription: category?.categoryDescription,
    eventTypeIds: category?.categoryAssociation.map((type)=>type.eventType.id)
  });

  const [organizedEventType, setOrganizedEventType] = useState([]);

  const presetEventTypes = ()=>{
    let organized = [];

    defaultEventTypes.map((type)=>{
      let selected = false;
      category?.categoryAssociation.map((categoryType)=>{
        if(categoryType.eventType.id === type.id){
          selected = true;
        }
      })
      if(selected){
        organized.push({
          id: type.id,
          typeName: type.typeName,
          selected: true
        })
      } else {
        organized.push({
          id: type.id,
          typeName: type.typeName,
          selected: false
        })
      }
    })
    setOrganizedEventType(organized);
  }

  const handleSelectType = (id)=>{
    let organized = organizedEventType.map((type)=>{
      if(type.id === id){
        type.selected = !type.selected;
      } 
      return type;
    })
    setOrganizedEventType(organized);
    setFormObj({...formObj, eventTypeIds: organized.filter((type)=>type.selected).map((type)=>type.id)})
  }

  const [message, setMessage] = useState('');
  const [warning, setWarning] = useState('');

  const handleCategoryName = (e)=>{
    setFormObj({...formObj, categoryName: e.target.value})
  }
  const handleDescription = (e)=>{
    setFormObj({...formObj, categoryDescription: e.target.value})
  }
  const handleClose = ()=>{
    setFormObj({
      categoryName: category?.categoryName,
      categoryDescription: category?.categoryDescription,
      eventTypeIds: category?.categoryAssociation.map((type)=>type.eventType.id)
    });
    presetEventTypes();
    setOpen(false)
  }

  useEffect(()=>{
    presetEventTypes();
  }
  ,[defaultEventTypes])

  return (
    <>
    <button 
      onClick={()=>setOpen(true)}
      className='transition delay-100 px-3 py-2 hover:bg-slate-200 rounded-lg'>
        <PencilIcon className='h-5 w-5 ' />
    </button>
    <Transition show={open}>
        <Dialog className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                  <div>
                    <div className="mt-3 text-center sm:mt-5 w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        <p className='text-center '>Edit Category</p>
                      </Dialog.Title>
                      <div className="mt-2">
                      
                      </div>
 
                    </div>
                    <label htmlFor="name" className="block text-xs font-medium text-gray-900 mt-2 py-2">Category Name </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formObj.categoryName}
                        onChange={handleCategoryName}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Jane Smith"
                      />
                    <label htmlFor="name" className="block text-xs font-medium text-gray-900 mt-3 py-2">Category Description </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formObj.categoryDescription}
                        onChange={handleDescription}
                        className="block w-96 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Jane Smith"
                      />
                    <label htmlFor="name" className="block text-xs font-medium text-gray-900 mt-3 py-2">Category Types </label>
                    <div className='flex flex-wrap w-96 '>
                      {
                        organizedEventType.map((type)=>{
                          return(
                            <button 
                            key={type.id} 
                            onClick={()=>handleSelectType(type.id)}
                            className={`my-1 mx-1 px-3 py-0 text-sm font-semibold border rounded ${type.selected? "bg-indigo-500 text-white":""}`}>{type.typeName}</button>
                          )
                        })
                      }
                    </div>

                  </div>
                  <div>
                    <p className='text-green-500 font-semibold'>{message}</p>
                    <p className='text-red-500 font-semibold'>{warning}</p>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={()=>{
                        editCategory(category?.id,formObj); 
                        setOpen(false);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
