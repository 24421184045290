import React, { createContext, useContext, useState } from 'react';

const SystemBannerContext = createContext();

export const useBanner = () => {
    return useContext(SystemBannerContext);
};

export const SystemBannerProvider = ({ children }) => {
    const [banner, setBanner] = useState({ message: null, color: 'bg-yellow-400' });

    const showBanner = (message, color = 'bg-yellow-400') => {
        setBanner({ message, color });
        setTimeout(() => {
            setBanner({ message: null, color: 'bg-yellow-400' });
        }, 2000); // Adjust the timeout as needed
    };

    return (
        <SystemBannerContext.Provider value={{ showBanner }}>
            {children}
            {
              banner.message 
              && <Banner 
              message={banner.message} 
              color={banner.color}
              onClose={() => setBanner({ message: null, color: 'bg-yellow-400' })} 
              />}
        </SystemBannerContext.Provider>
    );
};

const Banner = ({ message, color, onClose }) => {
    return (
        <div className={`fixed top-0 w-full flex justify-center text-center z-50 ${color}`}>
          <div className="flex items-center p-2 font-semibold ">
            {message}
            <button onClick={onClose} className="ml-4 text-sm text-white underline font-semibold">
                Close
            </button>
          </div>
        </div>
    );
};
