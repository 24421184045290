import React from 'react'
import { ClockIcon } from '@heroicons/react/24/solid';

export default function SkeletonEventFeedCard() {
  return (
    <>
    <div id='event-card' className='text-sm bg-white rounded-b-2xl mb-4 shadow'>
        <div 
            id='event-title'     
            className='transition delay-50 flex items-center space-x-5 justify-between hover:bg-slate-700 hover:shadow-sm hover:text-white text-gray-800 px-3 py-2 mb-2 border-b-2 border-slate-500 group'>
        <div className='flex w-full h-6'>
        </div>
            <p className='min-w-fit font-semibold rounded-2xl border-2 border-slate-200 text-xs group-hover:text-white px-4 py-2.5'></p>
        </div>
        <div className='px-3 py-10'>
            <div className='flex w-full justify-between rounded space-x-4'>
                <div className='rounded flex flex-col h-full'>
                    <div className='flex px-5 py-2 justify-center'>
                        <ClockIcon className='h-6 w-6 text-blue-500 mr-2'/>
                        <p className='text-sm text-center font-semibold text-blue-700 '></p>
                    </div>
                </div>
            </div>
  {/* ----------------- separator ----------------- */}
            <div className='px-1 py-1 flex justify-between items-center ' >
                <div className='flex flex-wrap space-x-2 items-center'>
                    
                </div>
                <div className='py-0'>
                    <p className='font-semibold text-xs '>
                        <span className='text-indigo-500'>  </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
