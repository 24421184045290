import React,{useContext,useState} from 'react';
import { EditEventFormContext, EditEventFormDispatchContext } from '../providers/EditEvent_FormContext';
import { EditEventFormRequiredFieldsContext } from '../providers/EditEvent_FormValidationContext';
import { EditEventFormRequiredFieldsDispatchContext } from '../providers/EditEvent_FormValidationContext';

export default function EditEventInputBox({inputName,inputType, attributeName, value, rows}) {
  const eventForm = useContext(EditEventFormContext);
  
  const dispatch = useContext(EditEventFormDispatchContext);

  const requiredFields = useContext(EditEventFormRequiredFieldsContext);
  
  const formCheckDispatch = useContext(EditEventFormRequiredFieldsDispatchContext)


  const handleInputChange = (e)=>{
    dispatch({
        type:"changed",
        name:attributeName,
        content: e.target.value
    })
    formCheckDispatch({
        type:"submitCheckEmpty",
        currentField: attributeName
    })
}

  return (
    <>
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
        <label htmlFor= {attributeName} className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            {inputName}
        </label>
        <div className="sm:col-span-2 sm:mt-0 flex">
            <textarea 
                type={inputType}
                rows={rows?rows:1}
                className="px-2 block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 bg-slate-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 resize-none"
                value={value}
                onChange={(e)=>{handleInputChange(e)}}
            />
            <p className='px-2 text-red-500 text-xs' hidden={requiredFields[attributeName]}> {inputName} can not be empty</p>
        </div>
    </div>
    </>
  )
}
