import React,{ createContext, useContext, useReducer, useState } from "react";
import { NewUniversityFormContext } from "./CreateUniversityContext";

export const FormRequiredFieldsContext = createContext(null);
export const FormRequiredFieldsDispatchContext = createContext(null);

export default function RequiredFieldsProvider({children}) {
    const newUniversityForm = useContext(NewUniversityFormContext);

    const [requiredFieldsState, dispatch] = useReducer((state,action)=>requiredFieldReducer(state,action,newUniversityForm),initialState2);

  return (
    <FormRequiredFieldsContext.Provider value={requiredFieldsState}>
        <FormRequiredFieldsDispatchContext.Provider value={dispatch}>
            {children}
        </FormRequiredFieldsDispatchContext.Provider>
    </FormRequiredFieldsContext.Provider>
  )
}

export function requiredFieldReducer(formStates, action,newUniversityForm){
    switch(action.type){
        case "submitCheckEmpty":{
            let fieldsStateCopy = {...formStates};
            if(!newUniversityForm[action.currentField].length >0 ){
                fieldsStateCopy[action.currentField] = false;
            }else{
                fieldsStateCopy[action.currentField] = true;
            }
            return fieldsStateCopy
        }
        case "inputLevelCheckEmpty": {
            let fieldsStateCopy = {...formStates};
            if(newUniversityForm[action.currentField].length >0){
                fieldsStateCopy[action.currentField] = true;
            }
            return fieldsStateCopy
        }
        default:{
            throw Error('Unknown action '+action.type)
        }
    }
}

// const requiredFields = ['universityName','city','stateProvince','website','admissionDeadline','satScore','toeflScore','applicationFee','applicationUrl','totalApplicants','totalAdmitted','notificationSentDate','applicantReplyDate','rigorOfRecord','classRank','academicGPA','satImportance','applicationEssay','recommendations','interview','extracurricular','talentAbility','characterPersonal','volunteerWork','alumniRelation','workExperience','levelOfInterest','inStateTuition','outOfStateTuition'];

const initialState2 = {
    universityName: true,
    streetAddress: true,
    city: true,
    stateProvince: true,
    zipCode: true,
    website: true,
    institutionalControl:true,
    undergradClassification:true,

    totalUndergrads: true,
    totalGrads: true,
    totalStudents: true,

    totalApplicants: true,
    totalAdmitted: true,
    waitList:true,
    waitListAdmitted:true,
    waitListRanked:true,

    notificationSentDate: true,
    applicantReplyDate: true,
    admissionClosingDate: true,
    earlyDecision: true,
    applicationFee: true,
    applicationUrl: true,

    satScore: true,
    toeflScore: true,
    academicGPA:true,
    // totalInternationalStudent: true,

    rigorOfRecord: true,
    classRank: true,
    academicGPAImportance: true,
    satImportance: true,
    applicationEssay: true,
    recommendations: true,

    interview: true,
    extracurricular: true,
    talentAbility: true,
    characterPersonalQuality: true,
    firstGeneration:true,

    volunteerWork: true,
    alumniRelation: true,
    geographicalResidence:true,
    stateResidency:true,
    // religiousAffiliationCommitment:true,
    // racialEthnicStatus:true,
    workExperience: true,
    levelOfInterest: true,

    inStateTuition: true,
    outOfStateTuition: true,
    financialAid: true,
};
