import React, { useEffect, useState,useContext } from 'react'
import Layout from '../../../components/Layout';

import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { getDateOnly, getTimeOnly } from '../../../../utils/cusDateFormatter';
import moment from 'moment';

export default function SuperAdmin_AllEvents() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;

  const [allVisits, setAllVisits] = useState([])
  const [thisWeekVisits, setThisWeekVisits] = useState([]);
  const [thisMonthVisit, setThisMonthVisits] = useState([]);
  const [futureVisits, setFutureVisits] = useState([]);
  const [pastVisits,setPastVisits] = useState([]);
  const [visitsTable, setVisitsTable] = useState([]);

  const [visitTabs,setVisitTabs] = useState([
    {active:true,tabName:"WITHIN A WEEK",content:thisWeekVisits},
    {active:false,tabName:"WITHIN 30 DAYS",content:thisMonthVisit},
    {active:false,tabName:"30 DAYS +",content:futureVisits},
    {active:false,tabName:"PAST",content:pastVisits},
  ])

  const [searchValue,setSearchValue] = useState("");

  // Methods 
  const handleSetContent = (index,visitContent)=>{
    const newActiveTabs = [...visitTabs];
    newActiveTabs[index].content = visitContent;
    setVisitTabs(newActiveTabs);
  }

  const handleVisitTable = (visitData,index)=>{
    setVisitsTable(visitData);
    const newActiveTabs = [...visitTabs];
    newActiveTabs.forEach((tab)=>{
      tab.active = false;
    });
    newActiveTabs[index].active = true;
    setVisitTabs(newActiveTabs)
  }

  const handleThisWeek = (visitsData)=>{
    const thisWeek = visitsData.filter((visit)=>{
      return (new Date(visit.eventDateTime) - new Date() < 7 *24 * 60 *60 * 1000) && (new Date(visit.eventDateTime) - new Date() > 0)
    });
    setThisWeekVisits(thisWeek);
    handleSetContent(0,thisWeek);
  }

  const handleThisMonth = (visitData)=>{
    const thisMonth = visitData.filter((visit)=>{
      const eventDate = new Date(visit.eventDateTime);
      return (eventDate - new Date() < 30 *24 * 60 *60 * 1000) && (eventDate - new Date() > 0)
    })
    setThisMonthVisits(thisMonth);
    handleSetContent(1,thisMonth);
  }

  const handleFuture = (visitData)=>{
    const futureVisits = visitData.filter((visit)=>{
      const eventDate = new Date(visit.eventDateTime);
      return (eventDate - new Date() > 30*24*60*60*1000)
    })
    setFutureVisits(futureVisits);
    handleSetContent(2,futureVisits);
  }
  
  const handlePast = (visitData)=>{
    const pastVisits = visitData.filter((visit)=>{
      const eventDate = moment(visit.eventDateTime);
      return (eventDate.isBefore(moment()))
    })
    handleSetContent(3,pastVisits);
  }

  const handleSearch = (e)=>{
    setSearchValue(e.target.value);

    let filteredList = allVisits.filter((visit)=>{
      const searchKey = ["eventDate"];
      return searchKey.some((key)=>{
        if(typeof visit[key] === "string" && visit[key].toLowerCase().include(e.target.value.toLowerCase())){
          return visit
        }
      })
    });
    handleVisitTable(filteredList,0);
    const refreshedData = [...visitTabs];
    refreshedData.forEach((tab)=>{
      tab.active = false;
    });
    refreshedData[0].active = true;
    setVisitTabs(refreshedData)
  }

  const getAllVisits = async () =>{
    await useAxiosWithInterceptor.get("/api/super-admin/events",{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setAllVisits(response.data.payload)
        handleThisWeek(response.data.payload);
        handleFuture(response.data.payload);
        handleThisMonth(response.data.payload);
        handlePast(response.data.payload);
        const thisWeek = response.data.payload.filter((visit)=>{

          return (new Date(visit.eventDateTime) - new Date() < 7 *24 * 60 *60 * 1000) && (new Date(visit.eventDateTime) - new Date() > 0)
          
        });
        handleVisitTable(thisWeek,0);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    document.title = "Visits";
    getAllVisits()
  },[])

  return (
    <>
      <Layout>
          <div className='md:w-full w-full'>
          <div className='bg-opacity-75 rounded-2xl  px-1 py-2 lg:mx-10'>

{/* overall stats */}
            <div className='overflow-x-auto shadow-sm rounded-2xl'>
              {/* <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5"> */}
                <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ">
                  { visitTabs.map((tab,index)=>{
                    return (
                      <button key={index} className={`shadow-sm flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8 ${tab.active?"bg-green-600 text-white":"bg-white"}`}
                       onClick={()=>{handleVisitTable(tab.content,index)}}>
                          <p className={`w-full text-sm font-medium ${tab.active?"text-white":"text-gray-500"}`}>{tab.tabName}</p>
                          <p className="w-full flex-none text-3xl font-medium leading-10 tracking-tight ">{tab.content.length} </p>
                      </button>
                    )})
                  }
                </div>
              {/* </div> */}
            </div>
{/* overall stats */}

          <div className="px-0 sm:px-6 lg:px-8 sm:flex sm:items-center my-2 gap-x-2">
              <div className='flex items-center w-full py-2 '>
                <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Visits</p>
                <input 
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder='search by name, date, location' 
                  className='bg-white shadow-sm block flex-1 border-0 bg-slate-100 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
              </div>
              <div className="sm:mt-0 sm:flex-none ">
              </div>
          </div>

          <div className=" flow-root hover:shadow hover:rounded-2xl">
            <div className=" -my-2 overflow-x-auto sm:-mx-8 lg:-mx-8 ">
             <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 rounded-2xl ">
              <table className="min-w-full divide-y divide-gray-700 rounded-2xl ">
                <thead className='bg-green-500 text-white'>
                  <tr className='text-center' >
                    <th scope="col" className="py-2 px-3 text-sm font-semibold sm:pl-0 lg:w-52 md:w-48 sm:w-32"> Event</th>
                    <th scope="col" className="hidden px-3 py-2 text-sm font-semibold lg:table-cell lg:w-52 "> School  </th>
                    <th scope="col" className="hidden px-3 py-2 text-sm font-semibold sm:table-cell lg:w-48 ">Date / Time</th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold lg:w-48 ">By</th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 rounded-b-2xl bg-white ">
                  {visitsTable.map((visit,index) => (
                    <tr key={visit.id} className='text-center '>
                      <td className="w-full max-w-0 py-2 px-3 text-sm font-semibold text-blue-500 sm:w-auto sm:max-w-none sm:pl-0 ">
                      <Link to={`/admin/events/${visit.id}`} >{visit?.eventName}</Link>

                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Event</dt>
                          <dd className="mt-1 truncate text-gray-700">{visit?.school?.schoolName}</dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">{visit?.school?.schoolName}</td>
                      <td className="hidden px-3 py-2 text-xs text-gray-500 sm:table-cell">{getDateOnly(visit?.eventDateTime)} / {getTimeOnly(visit?.eventDateTime)}</td>

                      <td className="hidden px-3 py-2 text-gray-500">{getDateOnly(visit?.eventDateTime)} / {getTimeOnly(visit.eventDateTime)}</td>
                      <td className="px-3 py-2 text-sm font-semibold text-black-400">{visit?.createdBy?.firstName} {visit.teacher?.lastName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
                  </div>
                </div>
              </div>
          </div>
          </div>
        </div>
        </Layout>
    </>
  )
}
