import React, { useEffect } from 'react'
import { useState } from 'react'
import UnAuthLayout from './components/UnAuthLayout'

import {
    AcademicCapIcon,
    CheckCircleIcon,
    HandRaisedIcon,
    RocketLaunchIcon,
    SparklesIcon,
    SunIcon,
    UserGroupIcon,
  } from '@heroicons/react/20/solid'

  const coreFeatures = [
    {
      name: "Discover What's Happening:",
      description: "Stay updated with the latest events, activities, and announcements around campus. Whether it's a club meeting, a sports event, or a guest lecture, you’ll always know what’s going on.",
      icon: RocketLaunchIcon,
    },
    {
      name: 'Create and Manage Events',
      description: "Have an idea for an event? With Schoolley, you can easily create, organize, and manage events that engage the whole campus. Invite students, coordinate with teachers, and ensure your event is a success.",
      icon: HandRaisedIcon,
    },
    {
      name: 'Connect with the Community:',
      description: "Schoolley isn’t just about staying informed; it’s about fostering connections. Our platform allows everyone in the campus community to participate, collaborate, and contribute to a vibrant campus life.",
      icon: UserGroupIcon,
    },
    {
      name: 'University College Counseling:',
      description: "Navigate your path to higher education with confidence. Schoolley provides university admission data accessible to both students and teachers, allowing for informed decision-making. Use this data to prepare applications, explore admission trends, and receive personalized guidance from university counselors.",
      icon: AcademicCapIcon,
    },
  ]
  const team = [
    {
      name: 'Leslie Alexander',
      role: 'Co-Founder / CEO',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
      location: 'Toronto, Canada',
    },
    // More people...
  ]
  const benefits = [
    'Competitive salaries',
  ]

export default function About() {
  
  useEffect(() => {
    document.title ="About Us";

  }, [])
  return (
    <UnAuthLayout>
    <div className="flex min-h-full flex-1 flex-col justify-center">
        <div className="bg-gray-900">
            <main className="relative isolate">
                {/* Background */}
                <div
                aria-hidden="true"
                className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                >
                <div
                    style={{
                    clipPath:
                        'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                    }}
                    className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                />
                </div>

                {/* Header section */}
                <div className="px-6 pt-14 lg:px-8">
                <div className="mx-auto max-w-2xl pt-20 text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">We love Education</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-300 font-semibold">
                    Empowering students, teachers, and staff to stay informed, get involved, and make campus life vibrant.
                    </p>
                </div>
                </div>

                {/* Content section */}
                <div className="mx-auto mt-10 max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none">
                      <div>
                          <p>
                          Welcome to Schoolley, the hub of campus life where everyone stays in the loop. Our platform is designed to bring the entire campus community together, making it easier than ever to know what’s happening, get involved, and make a difference.
                          </p>

                      </div>
                    </div>
                </div>
                </div>

                {/* Image section */}
                <div className="mt-10 sm:mt-10 xl:mx-auto xl:max-w-7xl xl:px-8">
                <img
                    alt=""
                    src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2894&q=80"
                    className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
                />
                </div>

                {/* Values section */}
                <div className="mx-auto max-w-7xl px-6 mt-10 lg:px-8">
                  <div className="">
                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Core Features</h2>
                  </div>
                  <div className="mx-auto mt-12 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
                      {coreFeatures.map((value) => (
                      <div key={value.name} className="relative flex items-start space-x-3">
                          <div className="flex-shrink-0">
                            <value.icon aria-hidden="true" className="h-5 w-5 text-indigo-500" />
                          </div>
                          <div className="flex-1 min-w-0">
                            <dt className="font-semibold text-white">{value.name}</dt>
                            <dd className="text-gray-300">{value.description}</dd>
                          </div>
                      </div>
                      ))}
                  </div>
                </div>

                {/* Team section */}
                <div className="mx-auto mt-20 max-w-7xl px-6 sm:mt-20 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:mx-0">
                      <h2 className="text-3xl font-bold text-white sm:text-4xl">Our team</h2>
                      <p className="mt-6 text-lg leading-8 text-gray-300">
                      A group of veterans in the education industry who are passionate about making a difference in the lives of students and educators through technology.
                      </p>
                  </div>
                </div>

                {/* CTA section */}
                <div className="relative isolate -z-10 mt-20 sm:mt-20">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1519338381761-c7523edc1f46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                        className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                    />
                    <div className="w-full flex-auto">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Join us</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                        We provide a powerful platform for schools to build and manage their own vibrant campus communities. Whether you're an administrator, educator, or student, Schoolley gives you the tools to create, organize, and participate in events that bring your campus to life.
                        </p>
                        <ul
                        role="list"
                        className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
                        >
                        {benefits.map((benefit) => (
                            <li key={benefit} className="flex gap-x-3">
                            <CheckCircleIcon aria-hidden="true" className="h-7 w-5 flex-none" />
                            </li>
                        ))}
                        </ul>
                        <div className="mt-10 flex">
                        </div>
                    </div>
                  </div>
                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                >
                    <div
                    style={{
                        clipPath:
                        'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                    }}
                    className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                    />
                </div>
                </div>
            </main>

            {/* Footer */}
            </div>
        </div>
    </UnAuthLayout>
  )
}
