import React,{useContext, useState} from 'react';
import { NewEventFormContext } from '../providers/EventForm_Context';
import EventInputBox from '../components/EventForm_InputBox';
import EventType from '../components/EventForm_TypeOptions';
// import InviteParents from '../components/EventForm_InviteParentsOptions';
import EventFormDeadlinePicker from '../components/EventForm_DeadlinePicker';
import EventDatePicker from '../components/EventForm_DatePicker';
import EventFormEndDatePicker from '../components/EventForm_EndDatePicker';
import EventFormGoogleToggle from '../components/EventForm_GoogleToggle';
import EventFormParentToggle from '../components/EventForm_ParentToggle';
import EventForm_OpenTwoAllToggle from '../components/EventForm_OpenTwoAllToggle';
import EventFormEventCategory from '../components/EventForm_EventCategory';

export default function EventGeneralInformation() {

  const eventForm = useContext(NewEventFormContext);

  return (
    <>
      <div className="space-y-8 border-b bg-white border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0 shadow-sm rounded-2xl px-3">
        <EventInputBox inputName={"Event Name"} inputType={"text"} attributeName={"eventName"} value={eventForm?.eventName} />
        <EventInputBox inputName={"Remark"} inputType={"text"} attributeName={"remark"} value={eventForm?.remark} rows={4} />
        <EventType inputName={"Event Type"} attributeName={"eventType"} />
        <EventFormEventCategory />
        <EventInputBox inputName={eventForm.eventType ==="in person"?"Location":"Zoom Link or Url"} inputType={"text"} attributeName={"location"} value={eventForm?.location} />
        <EventDatePicker />
        <EventFormEndDatePicker />
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
          <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
                Duration
          </label>
          {
            eventForm.eventEndTime !== "" && eventForm.eventDateTime !== "" ? (
              <p className='font-semibold text-teal-500 py-1'> {getDuration(eventForm.eventDateTime, eventForm.eventEndTime)} </p>
              ) : (
                <></>
              )
          } 
        </div>
        <EventFormDeadlinePicker />
        <EventFormGoogleToggle />
        {/* <InviteParents inputName={"Invite Parents"} attributeName={"inviteParents"} /> */}
        <EventFormParentToggle />
        <EventForm_OpenTwoAllToggle />
      </div>
    </>
  )
}

const getDuration = (eventStart, eventEnd) => {
  const start = new Date(eventStart);
  const end = new Date(eventEnd);

  if (isNaN(start) || isNaN(end)) {
    return "Invalid date(s)";
  }

  let totalMinutes = (end - start) / 1000 / 60;
  let hours = Math.floor(totalMinutes / 60);
  let minutes = Math.floor(totalMinutes % 60); // Use Math.floor to avoid rounding up to 60

  let display = "";
  if (hours > 0) {
    display += hours + (hours > 1 ? " hours " : " hour ");
  }
  if (minutes > 0 || display === "") { // Ensure we display "0 minutes" if there are no hours
    display += minutes + (minutes !== 1 ? " minutes" : " minute");
  }

  return display.trim();
};