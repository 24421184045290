import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useBanner } from '../../../../../services/SystemBannerMessage';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TrueFalseOptions from './TrueFalseOptions';

export default function EditAdmissionStats({refreshData}) {
    const [open, setOpen] = useState(false)
    const navigateTo = useNavigate();
    const location = useLocation();
    const {showBanner} = useBanner();

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {universityId} = useParams();

    const [originalData, setOriginalData] = useState({});
    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');

    const [formObj, setFormObj] = useState({
        totalApplicants: '',
        totalAdmitted: '',
        waitList: false,
        waitListRanked: false,
        waitListAdmitted: '',
        applicationFee: '',
    })

    const handleFormChange = (e)=>{
        const { name, value } = e.target;
        setFormObj({
        ...formObj,
        [e.target.name]: e.target.value
        });
    }

    const handleOpen = ()=>{
        setOpen(true)
        getAdmissionInfo();
    }
    
    const handleClose = ()=>{
        setOpen(false);
        setFormObj(originalData);
    }

    const getAdmissionInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities/${universityId}/admissions`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                let originalInfo = response.data.payload;
                let setFormData = {
                    applicationFee: originalInfo.applicationFee || '',
                    totalAdmitted: originalInfo.totalAdmitted || '',
                    totalApplicants: originalInfo.totalApplicants || '',
                    waitList: originalInfo.waitList || false,
                    waitListAdmitted: originalInfo.waitListAdmitted || '',
                    waitListRanked: originalInfo.waitListRanked || false,
                }
                setOriginalData(setFormData);
                setFormObj(setFormData);
            }
        })
        .catch((error)=>{
            console.log("error in loading existing data",error)
            }   
        )
    }

    const modifyIfUpdated = (originalData, updatedData)=>{
        let modifiedData = {};
        Object.keys(updatedData).forEach((key)=>{
            if(updatedData[key] !== originalData[key]){
              modifiedData[key] = updatedData[key];
            }
        });
        return modifiedData;
    }

    const handleUpdateAdmissionStats = async ()=>{

        let modifiedData = modifyIfUpdated(originalData,formObj);

        if(Object.keys(modifiedData).length === 0 && modifiedData.constructor === Object){
          showBanner("No changes made","bg-green-300");
          setOpen(false);
          return;
        }
  
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/counselor/universities/${universityId}/admissions`,modifiedData,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            showBanner("Admission Info Updated Successfully","bg-green-400");
            setOpen(false);
            refreshData()
          }
        })
        .catch((error)=>{
          console.log("error in updating admission info",error)
        })
    }

  return (
    <>
    <button 
        onClick={handleOpen}
        className='mx-1 text-slate-700'>
        <PencilSquareIcon className='h-6 w-6 '/>
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                <div className='lg:w-[30rem] font-semibold'>
                {/* content */}
                  <div className="mt-3 text-center sm:mt-5 w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          <p className='text-center text-xl py-2'>Edit Statistic Information</p>
                      </Dialog.Title>
                  </div>
                  
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="satScore" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Total Applicants</label>
                    <input
                    type="number"
                    name="totalApplicants"
                    id="totalApplicants"
                    value={formObj.totalApplicants}
                    onChange={handleFormChange}
                    className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                    placeholder="Enter Total Applicants"
                    />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="toeflScore" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Total Admitted</label>
                    <input
                    type="number"
                    name="totalAdmitted"
                    id="totalAdmitted"
                    value={formObj.totalAdmitted}
                    onChange={handleFormChange}
                    className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                    placeholder="Enter Total Admitted"
                    />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="academicGPA" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Wait List</label>
                    <div className='col-span-2'>
                     <TrueFalseOptions name="waitList" initialValue={formObj.waitList} handleFormChange={handleFormChange} />
                    </div>
                  </div>
                  <div className='grid grid-cols-3 px-2 my-2 space-x-3'>
                    <label htmlFor="applicationUrl" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Wait List Ranked</label>
                    <div className='col-span-2'>
                      <TrueFalseOptions name="waitListRanked" initialValue={formObj.waitListRanked} handleFormChange={handleFormChange} />
                    </div>
                  </div>
                  <div className='grid grid-cols-3 px-2 my-1 space-x-3'>
                      <label htmlFor="schoolName" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Wait List Admitted</label>
                      <input
                        type="number"
                        name="waitListAdmitted"
                        id="waitListAdmitted"
                        value={formObj.waitListAdmitted}
                        onChange={handleFormChange}
                        className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                        placeholder="Enter Wait List Admitted"
                    />
                  </div>
                  <div className='grid grid-cols-3 px-2 my-1 space-x-3'>
                      <label htmlFor="earlyDecision" className="col-span-1 block text-sm font-medium text-gray-900 flex items-center">Application Fee $</label>
                      <input
                        type="number"
                        name="applicationFee"
                        id="applicationFee"
                        value={formObj.applicationFee}
                        onChange={handleFormChange}
                        className="block w-76 py-2 px-3 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-slate-100 w-64"
                        placeholder="Enter Wait List Admitted"
                        />
                  </div>
                </div>
                <div>
                  <p className='text-green-500 font-semibold'>{message}</p>
                  <p className='text-red-500 font-semibold'>{warning}</p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={handleUpdateAdmissionStats}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Confirm
                  </button>
                </div>
                </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  )
}
