import React,{useContext,useState} from 'react';
import { SchoolEditEventFormContext, SchoolEditEventFormDispatchContext } from '../providers/SchoolEditEventFormContext';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import {SchoolEditEventFormValidationContext, SchoolEditEventFormValidationDispatchContext} from '../providers/SchoolEditEventFormValidationContext';


export default function SchoolEditEventInputBox({inputName,inputType, attributeName, value,row}) {
    const eventForm = useContext(SchoolEditEventFormContext);
    const dispatch = useContext(SchoolEditEventFormDispatchContext);
    const requiredFields = useContext(SchoolEditEventFormValidationContext);
    const formCheckDispatch = useContext(SchoolEditEventFormValidationDispatchContext);

    const handleInputChange = (e)=>{
        dispatch({
            type:"changed",
            name:attributeName,
            content: e.target.value
        })
        // formCheckDispatch({
        //     type:"submitCheckEmpty",
        //     currentField: attributeName
        // })
    }

  return (
   <>
    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
        <label htmlFor= {attributeName} className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            {inputName}
        </label>
        <div className="sm:col-span-3 sm:mt-0 flex">
            <textarea
                rows={row?row:1} 
                
                type={inputType}
                className="px-2 block w-full rounded-md border-1 border-slate-500 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset bg-slate-100 focus:ring-indigo-600  sm:text-sm sm:leading-6 bg-slate-100 resize-none"
                value={value}
                onChange={(e)=>{handleInputChange(e)}}
            />
            {/* <p className='px-2 text-red-500 text-xs' hidden={requiredFields[attributeName]}> {inputName} can not be empty</p> */}
        </div>
    </div>
   </>
  )
}
