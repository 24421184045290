import React,{useContext} from 'react'
// import EventUniversitySearch from '../../CreateEvent/components/EventUniversitySearch'
// import EventStudentsInviter from '../../CreateEvent/components/EventStudentInviter'
import EditEventUniversitySearch from '../components/EditEvent_UniversitySearch'
import { EditEventFormContext } from '../providers/EditEvent_FormContext';
import EditEventStudentsInviter from '../components/EditEvent_StudentInviter';
import EditEvent_UniversitySelector from '../components/EditEvent_UniversitySelector';
// import EditEventStudentsInviter from '../components/EditEventStudentsInviter'

export default function EventEditAttendance() {
  
  const editEventForm = useContext(EditEventFormContext);


//   console.log(editEventForm)

  return (
    <>
      <div className='flex justify-center'>
      </div>
      <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0 shadow-sm rounded-2xl px-3">

        <EditEvent_UniversitySelector attributeName={"selectedUniversities"} inputName={"Select Universities"} />
        {/* <EditEventUniversitySearch attributeName={"selectedUniversities"} inputName={"Select Universities"} /> */}
        <EditEventStudentsInviter attributeName={"selectedStudents"} inputName={"Invite Students"} />
        {/* 
        <EventStudentsInviter attributeName={"selectedStudents"} inputName={"Invite Students"} /> */}
        {/* a progress % bar to indicate full selection or partial, or a (14/15) indication */}
      </div>

    </>
  )
}
