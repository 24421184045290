import React, { useEffect, useState } from 'react'

export default function BasisForSelection({basisForSelection}) {

  const basisForSelectionValue= [
    {value: 'Very Important', label: 'Very Important', style:"bg-red-500 text-white"},
    {value: 'Important', label: 'Important', style:"bg-blue-500 text-white"},
    {value: 'Considered', label: 'Considered', style:"bg-green-500 text-white"},
    {value: 'Not Considered', label: 'Not Considered', style:"ring-2 ring-slate-500 "}
  ];

  const [basisForSelectionColor, setBasisForSelectionColor] = useState("bg-red-500");

  const [displayValue , setDisplayValue] = useState({});

  const handleBasisForSelection = (e) => {
    let color;
    let findImportance = basisForSelectionValue.find((item) => item.value === basisForSelection);
    if(findImportance){
        setDisplayValue(findImportance);
    }else{
        setDisplayValue({value: 'unspecified', label: 'Unspecified', style:"bg-slate-100"});
    }
    // setBasisForSelectionColor(color);
  }
   
  useEffect(() => {
    handleBasisForSelection();
  }, [basisForSelection]);

  return (
    <div className='col-span-2 flex items-center justify-center px-2'>
        <p className={`mx-2 text-xs font-semibold h-fit w-full sm:col-span-2 sm:mt-0 text-center rounded-lg py-1 ${displayValue.style}`}>{displayValue.label}</p>
    </div>

  )
}
