import React,{ createContext, useContext, useReducer, useState } from "react";
import RequiredFieldsProvider from "./RequiredFieldsContext";

export const NewUniversityFormContext = createContext(null)
export const NewUniversityDispatchContext = createContext(null);

export const NewUniFormProvider =  ({children}) =>{

    const [formState, dispatch] = useReducer(formReducer,initialForm);

    return (
        <NewUniversityFormContext.Provider value={formState}>
            <NewUniversityDispatchContext.Provider value={dispatch} >
                <RequiredFieldsProvider>
                    {children}
                </RequiredFieldsProvider>
            </NewUniversityDispatchContext.Provider>
        </NewUniversityFormContext.Provider>
    )
}

// export function useNewUniversityForm(){
//     return useContext(NewUniversityFormContext) 
// }

// export function useUniversityFormDispatch(){
//     return useContext(NewUniversityDispatchContext)
// }

export function formReducer(form, action){
    switch (action.type){
        case 'changed':{
            let formCopy = {...form}
            formCopy[action.name] = action.content
            return formCopy
        }
        case 'clear':{
            let formCopy = {...form}
            formCopy[action.name] = ""
            return formCopy
        }
        default:{
            throw Error('Unknown action '+ action.type)
        }
    }
}

const initialForm = {
    universityName: "",
    streetAddress:"",
    city:"",
    stateProvince:"",
    country:"",
    zipCode:"",
    website:"",
    institutionalControl:"",
    undergradClassification:"",
    applicationUrl:"",
    // totalInternationalStudent:"",
    // enrollmentCount:"",
    totalUndergrads:"",
    totalGrads:"",
    totalStudents:"",

    totalApplicants:"",
    totalAdmitted:"",
    waitList:"",
    waitListAdmitted:"",
    waitListRanked:"",

    notificationSentDate:"",
    applicantReplyDate:"",
    admissionClosingDate:"",
    earlyDecision:"",
    applicationFee:"",

    satScore:"",
    toeflScore:"",
    academicGPA:"",

    rigorOfRecord:"",
    classRank:"",
    academicGPAImportance:"",
    satImportance:"",
    applicationEssay:"",
    recommendations:"",
    
    interview:"",
    extracurricular:"",
    talentAbility:"",
    characterPersonalQuality:"",
    firstGeneration:"",

    volunteerWork:"",
    alumniRelation:"",
    geographicalResidence:"",
    stateResidency:"",
    // religiousAffiliationCommitment:"",
    // racialEthnicStatus:"",
    workExperience:"",
    levelOfInterest:"",

    inStateTuition:"",
    outOfStateTuition:"",
    financialAid:"",
}


const initialRequiredFields = ['universityName','city','stateProvince','website','admissionDeadline','satScore','toeflScore','applicationFee','applicationUrl','totalApplicants','totalAdmitted','notificationSentDate','applicantReplyDate','rigorOfRecord','classRank','academicGPA','satImportance','applicationEssay','recommendations','interview','extracurricular','talentAbility','characterPersonal','volunteerWork','alumniRelation','workExperience','levelOfInterest','inStateTuition','outOfStateTuition'];