
class CustomDateFormatter {
    constructor(date){
        this.date = new Date(date);
    }
    getDateOnly(){
        const setDate = this.date.toISOString();
        const onlyDate = setDate.split("T")[0];
    
        return onlyDate
    }
    
    checkTime(i) {
        return (i < 10) ? "0" + i : i;
    }
    getTimeOnly(){
        var hh = this.date.getHours();
        hh = hh >=10? hh : "0"+hh;
        var mm = this.date.getMinutes();
        mm = mm >=10? mm : "0"+mm;
        var ss = this.date.getSeconds();
        ss = ss >=10? ss : "0"+ss
        return `${hh}:${mm}:${ss}`
    }
}

// class convertDateForDB{
//     constructor(dateString,timeString){
//         this.dateString = dateString;
//         this.timeString = timeString;
//     }
// }

const convertDateForDB=(dateString,timeString)=>{
    const [year, month, day] = dateString.split('-');
    const [hour, minute, second] = timeString.split(':');

    return new Date(year, month - 1, day, hour, minute, second);
}

const getDateOnly =(date)=>{
    const thisDate = new Date(date)
    const setDate = thisDate.toISOString();
    const onlyDate = setDate.split("T")[0];

    return onlyDate
}

const getTimeOnly=(date)=>{

    const thisDate = new Date(date)

    var hh = thisDate.getHours();
    hh = hh >=10? hh : "0"+hh;
    var mm = thisDate.getMinutes();
    mm = mm >=10? mm : "0"+mm;
    var ss = thisDate.getSeconds();
    ss = ss >=10? ss : "0"+ss
    return `${hh}:${mm}:${ss}`
}

module.exports = {CustomDateFormatter,convertDateForDB,getDateOnly,getTimeOnly};