import { useLocation,Navigate,Outlet } from "react-router-dom";
import useAuthenticationContext from "../hooks/authStateHook";

const RequireAuthRoutes = ()=>{

    const {authState} = useAuthenticationContext();
    const location = useLocation();

    return ( 
        authState.isAuthenticated? <Outlet/>
            : <Navigate to='/login' state={{from:location}} replace/>
    )
}

export default RequireAuthRoutes;