import React, { useEffect, useState, useContext } from 'react'
import Select from 'react-select';

export default function InstitutionalControls({initialValue,handleFormChange}) {
    
    const options = [
        {value:"Public", label:"Public"},
        {value:"Private", label:"Private"},
        {value:"Proprietary", label:"Proprietary"},
    ];

    const [initialOption, setInitialOption] = useState({value:"",label:"",name:""});
    
    useEffect(()=>{
        setInitialOption({value:initialValue,label:initialValue,name:"institutionalControl"})
    },[initialValue])

  return (
    <div className='w-64'>
        <Select
        value={initialOption}
        closeMenuOnSelect={true}
        options= {options}
        onChange={(option)=>handleFormChange({target:{value:option.value,name:"institutionalControl"}})}
        />
        
    </div>
  )
}
