import React,{useContext,useEffect,useState} from 'react';
import Layout from '../../../components/Layout';
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import CounselorDeleteStudent from '../administratorComponents/DeleteStudentButton';
import { CustomDateFormatter } from '../../../../utils/cusDateFormatter';
import ProfileAvatar from '../../../components/Avatar';
import UnArchiveStudentButton from '../administratorComponents/UnArchiveStudentButton';
import { useBanner } from '../../../../services/SystemBannerMessage';
import moment from 'moment';


export default function AdministratorStudentProfileArchived() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);

    const userId = authState.user;
    const {studentId} = useParams();
    const schoolId = authState.schoolId;

    // const location = useLocation();
    const navigateTo = useNavigate();
    const {showBanner} = useBanner();




    const [studentInfo,setStudentInfo] = useState({});
    const [invitations, setInvitations] = useState([]);
    const [favorites, setFavorites] = useState([]);
  
    const getStudentInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/students/archived/${studentId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){

                    setStudentInfo(response.data.payload.findStudent);
                    // const universityNames = response.data.payload.findFavorites.map((favorite)=>{
                    //     return favorite.university.schoolName
                    // })
                    setFavorites(response.data.payload.findFavorites);
                    // setInvitations(response.data.payload.findStudentInvitations);
                    console.log(response.data.payload.findStudentInvitations)
                }
            }).catch((error)=>{
                console.error("error ")
                showBanner("Archive Not found, back to main page")
                navigateTo('/school/administrator/archive',{replace:true})
            })
    }

    // const handleDelete = async()=>{
    //     await useAxiosWithInterceptor.delete(`/api/user/${userId}/counselor/students/${studentId}`,{withCredentials:true})
    //     .then((response)=>{
    //         if(response.status === 200){
    //             navigateTo('/user/counselor/students',{state:{from: location},replace:true})
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    useEffect(()=>{
        getStudentInfo();
    },[]);

    useEffect(()=>{
        document.title = studentInfo?.firstName +" "+ studentInfo?.lastName
    },[studentInfo])
  
    return (
    <>
    <Layout>
    <div className='w-full h-full shadow-sm bg-opacity-75 rounded-2xl px-4 py-4'>
        <div className='grid grid-cols-1 h-[95%] '>
            {/* user information section */}
            <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-1 lg:space-x-8 md:space-x-2 bg-white'>
                {/* Column 1 */}
                <div className='h-fit p-4 rounded-2xl shadow-sm my-2 md:col-span-2 lg:col-span-2 bg-white '>
                    <div className='w-full mb-2 pb-3 grid place-items-center x-3 '>
                        <ProfileAvatar user={studentInfo} size={48}/>
                    </div>
                    <div className='w-full justify-start text-sm items-start px-3 space-y-5'>
                        <p className='text-center font-semibold text-lg'>{studentInfo?.firstName} {studentInfo?.lastName}</p>
                            <hr className='w-full border border-1 mt-1 mb-2' />
                        <p>Grade {studentInfo?.gradeLevel}</p>
                            <hr className='w-full border border-1 mt-1 mb-2' />
                        <p className='font-semibold'>{studentInfo?.email}</p>
                            <hr className='w-full border border-1 mt-1 mb-2' />
                        <p>Nationality</p>
                        <p>Region interested</p>
                        <p>Parents</p>
                        <p>Parents</p>
                        {studentInfo?.activated?<p className='font-semibold text-green-500'>ACTIVATED</p>:<p className='font-semibold text-red-600'>INACTIVE</p>}
                        <p className='text-lg font-semibold border border-2  rounded bg-gray-500 text-white text-center'>{studentInfo.archived?"ARCHIVED":""}</p>
                    </div>
                </div>
                {/* Column 2 */}
                <div className='h-[90%] md:col-span-2 lg:col-span-3 '>
                    {/* Row 1 */}
                    <div className='md:col-span-2 lg:col-span-3 rounded-2xl shadow-sm my-2 bg-white '>
                        <div className='py-2 text-center font-semibold text-white bg-teal-500 rounded-t-2xl'>
                            <p>Events</p>
                        </div>
                        <div>
                            <div className='text-center shadow-sm rounded-2xl'>
                            <table className='min-w-full divide-y '>
                                <thead className='bg-gray-50 text-sm'>
                                    <tr className=''>
                                        <th>#</th>
                                        <th className='px-2 py-1 font-semibold text-gray-900 '>Name</th>
                                        <th className='px-2 py-1 font-semibold text-gray-900 '>Date</th>
                                        <th className='px-2 py-1 font-semibold text-gray-900 '>Attendance Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 {invitations.map((invite,index)=>{
                                    return (
                                      <tr key={invite.id} >
                                        <td className='text-sm px-2'>{index+1}</td>
                                        <td className={`font-semibold text-sm py-2 ${index %2 === 0?"bg-slate-100":""} text-blue-500 `}>
                                        <Link to={`/counselor/events/${invite.event.id}`}>
                                            {invite.event.name}
                                        </Link>
                                        </td>
                                        <td className={`font-semibold text-xs py-2 ${index %2 === 0?"bg-slate-100":""}`}>
                                            <em>
                                            {moment(invite?.event?.eventDateTime).format("MM - DD - YYYY")}
                                            </em>
                                        </td>
                                        <td className={`font-semibold text-xs py-2 ${index %2 === 0?"bg-slate-100":""} ${invite.status ==="accepted"?"text-green-600":invite.status==="declined"?"text-orange-500":"text-red-600"} ${index === invitations.length -1?"rounded-br-2xl":""}`}>{invite.status}</td>
                                      </tr>
                                    )})
                                  }
                                </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                    {/* Row 1 END */}

                    {/* Row 2 */}
                    <div className='md:col-span-2 lg:col-span-3 rounded-2xl shadow-sm bg-white h-max my-4'>
                        <div className='py-2 text-center font-semibold text-white bg-amber-500 rounded-t-2xl'>
                            <p>FAVORITES</p>
                        </div>
                        <div className='text-center '>
                            <table className='min-w-full divide-y '>
                                <thead className='bg-gray-50 text-sm'>
                                    <tr className=''>
                                        <th className='px-2 py-1 font-semibold text-gray-900 '>#</th>
                                        <th className='px-2 py-1 font-semibold text-gray-900 '>University</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    favorites.map((favorite,index)=>{
                                      
                                        return (
                                            <tr key={favorite.id} >
                                                <td className='px-2 text-sm'>{index+1}</td>
                                                <td className={`font-semibold text-sm py-2 ${index %2 === 0?"bg-slate-100":""} ${index === favorites.length -1? "rounded-br-2xl":""}`}>{favorite.university.schoolName}</td>
                                            </tr>
                                        )})
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Row 2 END*/}
                </div>
                {/* Column 2 END */}
            </div>
            <div className='flex space-x-5 h-fit '>
                <div className='d-flex justify-content-end my-2 '>
                    <CounselorDeleteStudent student={studentInfo} />
                </div>
                <div className='d-flex justify-content-end my-2 '>
                    <UnArchiveStudentButton student={studentInfo} />
                </div>
            </div>
        </div>
    </div>
    </Layout>
    </>
  )
}
