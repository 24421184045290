import React, { useEffect, useState } from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import Layout from '../../../components/Layout';

export default function SuperAdmin_AllStudents() {

  const [allStudents,setAllStudents] = useState([]);
  const [studentsTable,setStudentsTable] = useState([]);
  const useAxiosWithInterceptor = axiosWithInterceptor();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e)=>{
    setSearchValue(e.target.value);
    let filteredList = allStudents.filter((student)=>{
      const searchKey = ["firstName","lastName","email"];
      return searchKey.some((key)=>{
        if(typeof student[key] === "string" && student[key].toLowerCase().includes(e.target.value.toLowerCase())){
          return student
        }
      });
    });
    setStudentsTable(filteredList);
  }

  const getAllStudents = async () =>{
    await useAxiosWithInterceptor.get("/api/super-admin/students",{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setAllStudents(response.data.payload);
        setStudentsTable(response.data.payload);
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    getAllStudents();
    document.title = "Students"
  },[])

  
  return (
    <>
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
          <p className='text-3xl font-bold py-1 px-2'>Students</p>
          <div className='my-1 py-1 flex align-middle'>
            <div className='flex items-center w-full lg:w-3/5 md:w-4/5 sm:w-4/5'>
              <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Student</p>
            <input 
              value={searchValue}
              onChange={handleSearch}
              placeholder='find student' 
              className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
            </div>
          </div>

          <div className="flow-root hover:shadow hover:rounded-2xl">
            <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-8 ">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl">
                  <table className='min-w-full divide-y divide-gray-700 rounded-2xl' >
                    <thead className='bg-green-500 text-white'>
                      <tr className='text-center text-sm'>
                        <th scope="col"  className="px-3 py-2 font-semibold sm:pl-6 w-48">Name</th>
                        <th scope="col" className="px-3 py-2 font-semibold w-48">Email</th>
                        <th scope="col"  className="px-3 py-2 font-semibold w-44">School</th>
                        <th scope="col"  className="px-3 py-2 font-semibold w-44">Grade</th>
 
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-300'>
                        {
                          studentsTable.map((student)=>{
                            return (
                              <tr key={student.id} className='hover:bg-gray-100 text-center bg-white'>
                                <td className='whitespace-nowrap py-2 pl-4 pr-3 text-xs text-blue-600 font-semibold '>
                                  <div className="flex items-center">
                                    <div className="h-8 w-8 flex-shrink-0">
                                      {
                                        student.profilePicture?
                                          <img className="h-8 w-8 rounded-full" src={student.profilePicture} alt="" />
                                          :
                                          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                            <span className="text-sm font-medium leading-none text-white">{student.firstName[0]}{student.lastName[0]}</span>
                                          </span>
                                      }
                                    
                                    </div>
                                    <div className="ml-4">
                                      <div className=" text-gray-900">{student.firstName} {student.lastName}</div>
                                    </div>
                                  </div>
                                </td>
                                <td className='whitespace-nowrap px-3 text-xs'>{student.email}</td>
                                <td className='text-sm whitespace-nowrap px-3'>
                                  {student.school?.schoolName}
                                </td>
                                <td>{student.gradeLevel}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  )
}
