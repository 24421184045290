import React, { useContext, useState,useEffect } from 'react'
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';

import { EditEventFormContext, EditEventFormDispatchContext } from '../providers/EditEvent_FormContext';
import { XCircleIcon ,CheckCircleIcon} from '@heroicons/react/24/solid'

// import { EventFormRequiredFieldsContext, EventFormRequiredFieldsDispatchContext } from '../providers/EventFormValidationContext';

export default function EditEventStudentsInviter({attributeName,inputName}){

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
    // contexts
  const eventFormState = useContext(EditEventFormContext);
  const dispatch = useContext(EditEventFormDispatchContext);
  
//   const requiredFields = useContext(EventFormRequiredFieldsContext);
//   const formCheckDispatch = useContext(EventFormRequiredFieldsDispatchContext);

  const [gradeLevels, setGradeLevels] = useState([]);
  const [allStudents, setAllStudents] = useState([]);

  const getAllStudents = async() =>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/students`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            setAllStudents(response.data.payload)
            let allGrades = response.data.payload.map((student)=>{
              return student.gradeLevel
          }).sort((a,b)=>a-b);
            setGradeLevels([... new Set(allGrades)])
          }
        })
        .catch((error)=>{
          console.log(error)
        })
    }
    useEffect(()=>{
        getAllStudents();
    },[])

    const handleChecked = (e)=>{
        const entireGrade = allStudents.filter((student)=>{
            return student.gradeLevel === + e.target.value;
        });
        if(e.target.checked){
          dispatch({
            type:"inviteStudents",
            name:attributeName,
            content:entireGrade
          })
        }else{
          dispatch({
            type:"unInviteStudents",
            name:attributeName,
            content:entireGrade
          })
        }
        console.log(eventFormState)
    }

    const removeOneStudent = (student)=>{
      dispatch({
        type:"unInviteStudents",
        name:attributeName,
        content:[student]
      })
    }
  
  const checkedOrNot = (gradeLevel)=>{
    const selectedStudents = eventFormState.selectedStudents;
    return selectedStudents.some(student=>student.gradeLevel === gradeLevel)
  }

  const gradeCount = (gradeLevel)=>{
    let count = 0;
    allStudents.forEach((student)=>{
      if(student.gradeLevel === gradeLevel){
        count ++
      }
    })
    return count;
  }

  return (
    <>
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
        <label htmlFor= {attributeName} className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            {inputName}
            <p className='text-blue-600'>Total: {eventFormState.selectedStudents.length}</p>
        </label>
        <form className='my-1 col-span-2'>
          <div className='flex flex-wrap text-xs font-semibold '>
          {
            gradeLevels.map((grade,index)=>{
              return (
                  grade && 
                    <div key={index} className='flex space-x-1 py-2 mx-2'>
                        <input 
                          type='checkbox' 
                          onChange={handleChecked} 
                          value={grade} 
                          checked={checkedOrNot(grade)}
  // if Context contains this grade value? checked
                          label={`Grade ${grade}`}
                          className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        <label className='text-sm'> Grade {grade} 
                          <span className='text-blue-500'>
                          ({gradeCount(grade)})
                          </span>
                        </label>
                    </div>
                  )
            })
          }
          </div>
        </form>
      </div>
      <div className='pb-3 pt-1'>
      {/* add a progress bar, indication % of students of the grade? or 4/15 */}
        {
          eventFormState.selectedStudents.map((student)=>{
            return (
              <div key={student.id} className='mx-1 my-1 px-2 py-1 rounded inline-flex bg-neutral-100 shadow-sm border-1' >
                <div className='flex px-2 content-center items-center space-x-2 '>
                  <p className='text-xs font-semibold ' >{student.firstName} {student.lastName}</p>
                  <p className='text-xs font-semibold'>({student.gradeLevel})</p>
                  <XCircleIcon className='w-6 h-6 hover:cursor-pointer' onClick={()=>{removeOneStudent(student)}}/>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
    </>
  )
}
