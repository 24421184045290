import {React,useState,useEffect,useContext} from 'react'
import Layout from '../../components/Layout'
import {useParams} from 'react-router-dom';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { useNavigate, useLocation } from 'react-router-dom';
import { SchoolEditEventFormContext, SchoolEditEventFormDispatchContext } from './providers/SchoolEditEventFormContext';
import SchoolEditEventCategory from './components/SchoolEditEventCategory';
import SchoolEditEventInputBox from './components/SchoolEditEventInputBox';
import SchoolEditEventDatePicker from './components/SchoolEditEventDatePicker';
import SchoolEditEventEndDatePicker from './components/SchoolEditEventEndDatePicker';
import SchoolEditEventParentToggle from './components/SchoolEditEventParentToggle';
import SchoolEditEventOpenToAll from './components/SchoolEditEventOpenToAll';
import SchoolEditEventGoogleToggle from './components/SchoolEditEventGoogleToggle';
import SchoolEditEventDeadlinePicker from './components/SchoolEditEventDeadlinePicker';
import SchoolEditEventStudentSelector from './components/SchoolEditEventStudentSelector';
import { Link } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useBanner } from '../../../services/SystemBannerMessage';

export default function SchoolAllEditEvent() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const {eventId} = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const {showBanner} = useBanner();

  const [emptyFields, setEmptyFields] = useState([]);

  const eventFormState = useContext(SchoolEditEventFormContext);
  const eventFormDispatch = useContext(SchoolEditEventFormDispatchContext);

  const checkState =()=>{
    console.log(eventFormState)
  }

  const handleSubmit = async ()=>{

    let formHasEmptyValues = true;
    let copyEmptyField = [...emptyFields];
    for(const [key,value] of Object.entries(eventFormState)){
      formHasEmptyValues = false;
      if(value === "" && (key !== "selectedUniversities" && key !== "selectedStudents")){
        formHasEmptyValues = true;

        if(!copyEmptyField.includes(key)){
          copyEmptyField.push(key)
        }
        setEmptyFields(copyEmptyField)
        break;

      } else {
        if(copyEmptyField.includes(key)){
          let reducedFields = copyEmptyField.filter((field)=>field !== key);
          setEmptyFields(reducedFields);
        }
        formHasEmptyValues = false;
      }
    }
    // console.log("form has empty values: "+formHasEmptyValues)

    if(!formHasEmptyValues){
      const form = new FormData();
      for(const [key,value] of Object.entries(eventFormState)){
          if(key ==="selectedUniversities" || key ==="selectedStudents"){
              let ids = value.map((item)=>{
                  return item.id
              })
              form.append(key,ids)
          } else{
              form.append(key,value)
          }
      }
      form.forEach((value,key)=>{
          console.log(key+" : "+value)
      })
      await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/events/${eventId}/edit`,form,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
              const eventId = response.data.payload.id;
              navigate(`/school/events/${eventId}`,{state:{from: location},replace:true});
            }
            showBanner("Event Updated Successfully","bg-sky-400")
        })
        .catch((error)=>{
            console.log(error)
        })
      }
  }

  useEffect(()=>{
    document.title = "Schoolley | Edit Event"
  },[])

  return (
    <Layout>
<div className='md:w-full w-full '>
      <div className='bg-opacity-75 px-1 lg:mx-5'>
        <div className='mb-2 py-1 flex justify-center bg-white'>
          <Link to={`/school/events/${eventId}`} >
            <div className="group flex w-36 items-center px-2 py-1 text-sm font-medium hover: cursor-pointer ">
              <XCircleIcon className='w-10 h-10 text-orange-400 transition delay-50 group-hover:rotate-90 group-hover:text-red-500' />
              <span className="ml-1 text-sm font-semibold text-grey-600">Cancel</span>
            </div>
          </Link>
          <div className='w-full flex justify-center items-center'>
             <p className='text-lg font-semibold'>Edit {eventFormState.eventName}</p>
          </div>
        </div>

        <div className="h-fit lg:flex space-y-2 lg:space-y-0">
          <div className="space-y-2 border-b bg-white border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0 shadow-sm rounded-2xl px-3 lg:w-3/5 lg:mr-3">
              <SchoolEditEventInputBox inputName={"Event Name"} inputType={"text"} attributeName={"eventName"} value={eventFormState?.eventName} />
              <SchoolEditEventInputBox inputName={"Remark"} inputType={"text"} attributeName={"remark"} value={eventFormState?.remark} row={4} />
              {/* <SchoolEventType inputName={"Event Type"} attributeName={"eventType"} /> */}
              <SchoolEditEventInputBox inputName={eventFormState?.eventType ==="in person"?"Location":"Zoom Link or Url"} inputType={"text"} attributeName={"location"} value={eventFormState?.location} />
              <SchoolEditEventCategory />
              {/* date picker  */}
              <SchoolEditEventDatePicker />
            {/* end date picker */}
              <SchoolEditEventEndDatePicker />
            {/* sign up deadline datepicker */}
              {/* <SchoolEventFormDeadlinePicker /> */}
              <SchoolEditEventDeadlinePicker />
              {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 "> Duration </label>
                {
                  eventFormState.eventEndTime !== "" && eventFormState.eventDateTime !== "" ? (
                    <p className='font-semibold text-teal-500 py-1'> {getDuration(eventFormState.eventDateTime, eventFormState.eventEndTime)} </p>
                    ) : (
                      <></>
                    )
                } 
              </div> */}
              {/* Google Calendar */}
              <SchoolEditEventGoogleToggle />
              {/* Invite Parents */}
              <SchoolEditEventParentToggle />
              {/* Event Openness */}
              <SchoolEditEventOpenToAll />
          </div>

          <div className='rounded-2xl bg-white px-3 py-3 lg:w-2/5 shadow-sm'>
            <p className='font-semibold text-center'>Attendance</p>
            <div id='student-selector'>
                {/* <SchoolEventStudentSelector 
                  attributeName={"selectedStudents"} 
                  inputName={"Invite Students"} /> */}
                <SchoolEditEventStudentSelector attributeName={"selectedStudents"} />
            </div>
          </div>
        </div>
        <div className="space-y-6 sm:space-y-1 py-2 ">
          {
            <button 
            onClick={handleSubmit} 
            className='transition delay-50 w-64 font-semibold px-5 py-3  border bg-yellow-300 hover:bg-yellow-400 shadow-sm hover:shadow' 
            >UPDATE EVENT</button>
          }
        </div>
        {/* <button className='w-64 font-semibold px-5 py-2 rounded-sm border hover:bg-green-500 shadow-sm' onClick={checkState}>Check State</button> */}
      </div>
    </div>
    </Layout>
  )
}
