import React, { useEffect } from 'react'
import { useState } from 'react'
import { Switch } from '@headlessui/react';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function StatusToggle({invitation,handleStatusChange}) {

    const [enabled, setEnabled] = useState(false);

    const handleToggle = (e)=>{

        if(enabled){
            // handleStatusChange(invitation.id,"declined");
            setTimeout(() => {
                handleStatusChange(invitation.id, "declined");
            }, 300);
        }else {
            // handleStatusChange(invitation.id,"accepted")
            setTimeout(() => {
                handleStatusChange(invitation.id, "accepted");
            }, 300);
        }
        setEnabled(!enabled)
    }

    useEffect(()=>{
        if(invitation.status ==="accepted"){
            setEnabled(true)
        }else{
            setEnabled(false)
        }
    },[])

    return (
        <Switch.Group as="div" className="flex items-center justify-center">
            <Switch
                checked={enabled}
                onChange={handleToggle}
                className={classNames(
                enabled ? 'bg-green-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2'
                )}
            >
                <span
                aria-hidden="true"
                className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
                />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
                {
                    invitation.status ==="accepted"?<span className="font-medium text-green-600">Accepted</span>:invitation.status ==="declined"?
                    <span className="font-medium text-red-600">Declined</span>:
                    <span className="font-medium text-amber-500">Pending</span>
                }
            </Switch.Label>
        </Switch.Group>
    )
}
