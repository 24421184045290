import React from 'react'

export default function ProfileAvatar({user,size}) {

    // const image64 = `data:image/png;base64,${user?.profilePicture}`;
    return (
    <>
    {
        user?.profilePicture? 
            <img
            className={`inline-block h-${size} w-${size} object-cover rounded-full shadow-md border-2 border-teal-50`}
            src={user?.profilePicture}           
            alt=""
            />
        :
        <img
            className={`inline-block h-${size} w-${size} object-cover rounded-full shadow-md border-2 border-teal-50`}
            src="/placeholders/SchoolPlaceholder.png"
            alt=""
            />
    }
    </>
  )
}
