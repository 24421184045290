import React from 'react';
import { Outlet } from 'react-router-dom';
import useAuthenticationContext from '../hooks/authStateHook';
import NotFound404 from '../pages/404';

export default function SchoolStaffAccess() {
    const { authState } = useAuthenticationContext();
  return (
    Array.isArray(authState.role) && (authState.role.includes("counselor")|| authState.role.includes("teacher")||authState.role.includes("administrator"))?<Outlet/> :<NotFound404 />
    
  )
}
