import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './custom.css'
import App from './App';
import { AuthProvider } from './services/AuthContextModule';
import {SystemBannerProvider} from './services/SystemBannerMessage';
import { SystemCornerWarningProvider } from './services/SystemCornerWarning';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <SystemBannerProvider>
        <SystemCornerWarningProvider>
          <App />
        </SystemCornerWarningProvider>
      </SystemBannerProvider>
    </AuthProvider>
  </React.StrictMode>
);
