import React, { useContext, useState, Fragment, useRef} from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useBanner } from '../../../../services/SystemBannerMessage';
import { useParams } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/24/solid';

export default function Counselor_UploadUniversityLogo({refreshSchool}) {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const {showBanner} = useBanner();
    const {universityId} = useParams();

    const [open, setOpen] = useState(false);
    const [file,setFile] = useState(null);

    const handleFile = (e)=>{
        setFile(e.target.files[0]);
      }
    const handleClose = ()=>{
        setFile(null);
        setOpen(false);
    }
    
    const handleUpload = async ()=>{
      if(file){
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const form = new FormData();

        reader.onload=(e)=>{
            var base64Img = "";
            base64Img = e.target.result;
            form.append('profilePicture',base64Img);
            // logic for axios
            useAxiosWithInterceptor.put(`/api/user/:userId/school/:schoolId/counselor/universities/${universityId}/image`,form,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    showBanner("Image Uploaded","bg-green-500");
                    refreshSchool();
                }
            })
            .catch((e)=>{
                showBanner("Image Upload Failed","bg-yellow-500");
            })
        }
        setOpen(false);
      }
    }

  return (
    <>
    <button className='rounded bg-indigo-50 px-3 h-10 py-0 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100' onClick={()=>setOpen(true)}>Edit Photo</button>

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-center shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
              <div>
                <div className="col-span-full">
                  <div className="mt-2 grid grid-cols-1 ">
                    <div className="col-span-full">
                      <label htmlFor="cover-photo" className="block text-xl font-semibold leading-6 text-gray-900 mb-2">
                        Upload School Logo
                      </label>
                      {file && file.name ? <p className='break-all'>{file.name}</p> : null}
                      
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ">
                        <form>
                          <div className="text-center">
                              {
                                  file ? 
                                  <img src={URL.createObjectURL(file)} alt="logo" className="mt-2 h-30 w-30 rounded-lg" /> 
                                  : 
                                  <PhotoIcon className="mx-auto h-30 w-30 text-gray-300" aria-hidden="true" />
                              }
                              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 px-2 text-center">
                                  Upload a file
                                </label>
                                <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFile} />
                              </div>
                            </div>
                        </form>
                        </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="mt-5 sm:mt-6 grid grid-cols-2 space-x-2">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleUpload}>Save </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleClose}>Cancel</button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}
