import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { AuthenticationContext } from '../../../services/AuthContextModule';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import SchoolAvatar from '../../components/SchoolAvatar';
import CounselorEditSchoolPhoto from '../counselor/counselorComponents/Counselor.EditSchoolPhoto';
import { Link } from 'react-router-dom';
import Setting_UploadSchoolLogo from '../administrator/administratorComponents/Setting_UploadSchoolLogo';
import SeparatorLine from '../schoolComponents/SeparatorLine';

export default function SchoolAllMySchool() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const [schoolInfo, setSchoolInfo] = useState({});
    const [students, setStudents] = useState([]);
    const [events, setEvents] = useState([]);

    const getMySchool = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/my-school`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setSchoolInfo(response.data.payload);
                setStudents(response.data.payload.students);
                setEvents(response.data.payload.events)
            }
        })
        .catch((e)=>{
            console.log(e);
        })
    }
    
    const getStudentsNumber = (inputStudents,grade)=>{
        let currentGrade = inputStudents.filter((student)=>{
            return student.gradeLevel === grade
        });
        return currentGrade.length;
    }

    useEffect(()=>{
        document.title = "Schoolley | My School"
        getMySchool();
    },[]);
    
  return (
    <>
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
        <div className='grid lg:grid-cols-5'>
          <div id="grid-one" className='col-span-3'>
            <div className='grid lg:grid-cols-1 md:grid-cols-2 sm:grid-cols-1 px-3 py-2 rounded-2xl shadow-md my-2 lg:mr-4 bg-white'>
                <div className='grid grid-cols-2 '>
                    <div className='flex flex-col justify-center h-full text-center space-y-5'> 
                      <div>
                        <p className='text-3xl font-bold my-3'>{schoolInfo?.schoolName}</p>
                        <p className='text-lg italic'>{schoolInfo?.description}</p>
                      </div>
                      <div>
                        <p className='text-sm font-semibold'>{schoolInfo?.address}</p>
                        <p className='text-sm font-semibold'>{schoolInfo?.city}, {schoolInfo?.state}, {schoolInfo?.country}</p>
                      </div>
                    </div>
                    <div className='grid grid-cols-1'>
                        <div className='mb-2 '>
                            <div className='flex justify-center my-2'>
                                <SchoolAvatar school={schoolInfo} />
                            </div>
                            <div className='text-center'>
                                <Setting_UploadSchoolLogo refreshSchool={getMySchool} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* students numbers overall tab */}
            <div className='grid grid-cols-1 space-y-3 py-2 lg:mr-4 '>
              <p>Students</p>
              <div className='rounded-2xl shadow-md bg-white '>
                <div className="grid grid-cols-2 gap-y-10 text-center md:grid-cols-3 lg:grid-cols-5 ">
                    <div className="flex max-w-xs flex-col gap-y-2 py-2 rounded-l-2xl border-r border-gray-100">
                        <dt className="text-xs leading-7 text-gray-600">TOTAL</dt>
                        <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 ">
                            {students?.length}
                        </dd>
                    </div>
                    <Link to={`/school/students/grade/9`}>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-2 hover:bg-gray-100 py-2 border-r border-gray-100">
                            <dt className="text-xs leading-7 text-gray-600">Grade 9</dt>
                            <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 ">
                                {getStudentsNumber(students,9)}
                            </dd>
                        </div>
                    </Link>
                    <Link to={`/school/students/grade/10`}>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-2 hover:bg-gray-100 py-2 border-r border-gray-100">
                            <dt className="text-xs leading-7 text-gray-600">Grade 10</dt>
                            <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 ">
                                {getStudentsNumber(students,10)}
                            </dd>
                        </div>
                    </Link>

                    <Link to={`/school/students/grade/11`}>
                        <div className="mx-auto flex max-w-xs flex-col gap-y-2 hover:bg-gray-100 py-2 border-r border-gray-100">
                            <dt className="text-xs leading-7 text-gray-600">Grade 11</dt>
                            <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 ">
                            {getStudentsNumber(students,11)}
                            </dd>
                        </div>
                    </Link>

                    <Link to={`/school/students/grade/12`}>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-2 hover:bg-gray-100 py-2 rounded-r-2xl">
                        <dt className="text-xs leading-7 text-gray-600">Grade 12</dt>
                        <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 ">
                        {getStudentsNumber(students,12)}
                        </dd>
                    </div>
                    </Link>
                </div>
              </div>
            {/*  add students functionality tab */}
                {/* statistics */}
                <p className=' text-center font-bold py-1'>STATS</p>
              <div className='overflow-x-auto p-2 rounded-2xl shadow-md bg-white '>
                <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-2 lg:gap-y-2 py-2 ">
                    <div className='shadow-sm items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8'>
                        <p className=''>Popular Universities</p>
                        <p className=''></p>
                    </div>
                    <div className='shadow-sm items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8'>
                        <p>Event Hosted</p>
                        <p className=''>{events?.length}</p>
                    </div>
                    <div className='shadow-sm items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8'>
                        <p>number of Attendance in total</p>
                    </div>
                    <div className='shadow-sm items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8'>
                        <p>Students attending most events</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div id='grid-two' className='col-span-2'>
            <div className='p-2'>
                <p className='text-lg font-semibold text-center mb-5'>Statistics</p>
                <SeparatorLine margin={5} />
                <p className='my-5 text-3xl text-slate-500 text-center'>Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
    </>
  )
}
