import React, { useEffect } from 'react';
import { AuthenticationContext } from '../../services/AuthContextModule';
import { useContext } from 'react';
import axiosWithInterceptor from '../../hooks/axiosInterceptor';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react'
import {BellIcon} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';

export default function NotificationWidget() {

    const {authState, setAuthState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const userId = authState.user;
    const userRole = authState.role;

    const [notifications, setNotifications] = useState([]);
    const [notificationCounter, setNotificationCounter ] = useState(0);

    const getNotifications = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/notifications`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            setNotifications(response.data.payload);
            let counter = 0;
            response.data.payload.forEach((item)=>{
                if(item.seen === false){
                    counter = counter + 1;
                }
            });
            setNotificationCounter(counter);
          }
        })
        .catch((error)=>{
          console.log(error)
        })
      }

  const markAsRead = async ()=>{
    let unreadIds = [];
    notifications.forEach((item)=>{
        if(item.seen === false){
            unreadIds.push(item.id);
        }
    });

    if(unreadIds.length > 0){
        const form = new FormData();
        form.append("notificationIds",unreadIds);

        await useAxiosWithInterceptor.put(`/api/user/${userId}/notifications/read`,form, {withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setNotificationCounter(0)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        setNotificationCounter(0)
    }
  }

    const timeHasBeen = (time)=>{
      let timeNow = new Date();
  
      const timeBetween = timeNow - new Date(time);
      const minutes = Math.floor(timeBetween / (1000 * 60)); 
      const hours = Math.floor(timeBetween / (1000 * 60 * 60));
      const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));
  
      let timeBeen ="";
  
      if (days >= 1) {
        timeBeen = days > 1 ? days + " days ago" : "yesterday";
      } else if (hours >= 1) {
        timeBeen = hours > 1 ? hours + " hours ago" : hours + " hour ago";
      } else if (minutes >= 1) {
        timeBeen = minutes > 1 ? minutes + " minutes ago" : minutes + " minute ago";
      } else {
        timeBeen = "just now";
      }
      return  timeBeen;
    }
    
    useEffect(()=>{
      getNotifications();
    },[])

  return (
    <>
    <Menu as="div" className="relative ">
      <Menu.Button className="-m-1.5 flex items-center p-1.5" >
        <div className='relative'>
          <BellIcon className='rounded-full hover:bg-slate-100 h-10 w-10 p-2 ' aria-hidden="true" onClick={markAsRead} />
          {
            notificationCounter > 0?
              <div className='absolute bottom-6 left-5 bg-red-500 w-5 h-5 rounded-full flex items-center justify-center'>
              <p className='text-sm font-semibold text-white '>{notificationCounter}</p>
              </div>
              :null
          }
        </div>
      </Menu.Button>
 
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95" >

      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-80 max-h-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        <div className={`${userRole === "student" ? "bg-amber-500" : "bg-indigo-500"} w-full rounded-t-lg px-2 py-2 fixed`}>
          <p className='text-white font-bold text-center'>NOTIFICATIONS</p>
        </div>
        <div className='pt-12 h-96 overflow-auto'>
          {
            notifications.map((notification) => (
              <Link key={notification.id} to={`/student/school-events/${notification?.event_id}`}>
                <Menu.Item className='w-full py-2 border-b hover:bg-slate-100'>
                  <div className='px-3'>
                    <p className='text-sm break-words '>{notification.content}</p>
                    <p className='text-gray-700 text-xs'>{timeHasBeen(notification.createdAt)}</p>
                  </div>
                </Menu.Item>
              </Link>
            ))
          }
        </div>
      </Menu.Items>

      </Transition>
      </Menu>
    </>
  )
}