import React from 'react';
import UnAuthLayout from './components/UnAuthLayout';
import { useState } from 'react';
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';


export default function SignUp() {

    const navigate = useNavigate();

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [passwordWarning, setPasswordWarning] = useState("");
    const [schoolName, setSchoolName] = useState("");
    
    const handleEmail = (e)=>{
        setEmail(e.target.value);
    }

    const handlePassword = (e)=>{
        setPassword(e.target.value);
    }

    const handleConfirmPassword = (e)=>{
        setConfirmPassword(e.target.value);
        if(password !== confirmPassword){
            setPasswordWarning("Passwords does not match");
        }else{
            setPasswordWarning("");
        }
    }

    const handleFirstName = (e)=>{
        setFirstName(e.target.value);
    }

    const handleLastName = (e)=>{
        setLastName(e.target.value);
    }

    const handleSchoolName = (e)=>{
        setSchoolName(e.target.value);
    }
    
    const handleSubmit = (e)=>{
        e.preventDefault();

        const form = new FormData();

        form.append('email',email);
        form.append('password',password);
        form.append('firstName',firstName);
        form.append('lastName',lastName);
        form.append('schoolName',schoolName);

        axios.post("/api/auth/signup",form,{
           withCredentials:true
        })
        .then((response)=>{
          if(response.status === 200){
            navigate('/')
          }
        })
        .catch((err)=>{
            console.log(err)
        })
    }

  return (
   <UnAuthLayout>
   <div className="flex min-h-full flex-1 flex-col justify-center relative z-10">
      {/* <div className='absolute inset-0'>
        <SignupShape />
      </div> */}
      <div className='relative'>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 pt-4 pb-6 my-4 shadow rounded-lg sm:px-12">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Registration <span className='text-base'>(Admin Only)</span> 
          </h2>
          <p className='text-sm'>Welcome to UniVisits, </p>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-xs font-medium leading-6 text-gray-900">
                  Email address
              </label>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={email}
                onChange={handleEmail}
                />
              </div>

              <div>
                <label htmlFor="firstName" className="block text-xs font-medium leading-6 text-gray-900">
                  First Name
                </label>
                  <input
                    name="firstName"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={firstName}
                    onChange={handleFirstName}
                  />
              </div>

              <div>
                <label htmlFor="lastName" className="block text-xs font-medium leading-6 text-gray-900">
                  Last Name
                </label>
                  <input
                    name="lastName"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={lastName}
                    onChange={handleLastName}
                  />
              </div>

              <div>
                <label htmlFor="schoolName" className="block text-xs font-medium leading-6 text-gray-900">
                  Your School Name
                </label>
                <input
                    name='schoolName'
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={schoolName}
                    onChange={handleSchoolName}
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-xs font-medium leading-6 text-gray-900">
                  Password
                </label>
                <input
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={password}
                onChange={handlePassword}
                />
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-xs font-medium leading-6 text-gray-900">
                  Confirm Password
                </label>
                <input
                name="confirmPassword"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={confirmPassword}
                onChange={handleConfirmPassword}
                />
              </div>
              <div className='flex gap-x-2 '>
                <input type='checkbox'/>
                <p className='text-xs font-semibold'>Agree on our terms</p>
              </div>
              <div className='mt-5'>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
   </UnAuthLayout>
  )
}
