import React, { useEffect,useContext, useState, useRef} from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SchoolEditEventFormContext, SchoolEditEventFormDispatchContext } from '../providers/SchoolEditEventFormContext';
import { SchoolEditEventFormValidationContext, SchoolEditEventFormValidationDispatchContext } from '../providers/SchoolEditEventFormValidationContext';
import moment from 'moment';

export default function SchoolEditEventDatePicker() {

    const eventFormState = useContext(SchoolEditEventFormContext);
    const [startDate, setStartDate] = useState();
    const dispatch = useContext(SchoolEditEventFormDispatchContext);
    
    const formCheckDispatch = useContext(SchoolEditEventFormValidationDispatchContext);

    const handleContextDateTime = (dateTime)=>{
        dispatch({
            type:"dateTimeChanged",
            name:"eventDateTime",
            content: dateTime
        })
    }

    const handleSelectDate = (date)=>{
        setStartDate(date)
        handleContextDateTime(date)
    }

    const handleBlur = () => {
        if (!startDate) {
            setStartDate(moment().add(2, 'hours').toDate());
            handleContextDateTime(moment().add(2, 'hours').toDate());
        }
    }; 

    useEffect(() => {
        if (eventFormState.eventDateTime) {
            setStartDate(new Date(eventFormState.eventDateTime));
        }
    }, [eventFormState.eventDateTime]);

  return (
    <>
    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 ">
       <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            Event Date
        </label>
      <div className='col-span-3'>
        <ReactDatePicker className='px-3 py-1 font-semibold text-sky-800 rounded bg-teal-500 text-white'
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
          selected={startDate}
          onChange={(date) => handleSelectDate(date)}
          onBlur={handleBlur}
          monthsShown={2}
          minDate={new Date()}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={10}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
    </div>
    </>
  )
}
