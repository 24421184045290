import React, { useContext, useState, Fragment, useRef} from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { TrashIcon, ArchiveBoxXMarkIcon } from '@heroicons/react/20/solid'
import { set } from 'react-hook-form';

export default function ReminderConfirm({buttonName,studentCount,sendEmail,styling}) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const handleSendEmail = ()=>{
    sendEmail()
    setTimeout(() => {
        setOpen(false)
    }, 500);
  }

  return (
    <>
    <button
        onClick={()=>setOpen(true)}
        className={`transition delay-100 w-48 py-1 text-white font-semibold text-sm self-center my-2 mx-1 ${styling} shadow-sm`}>
        {buttonName} ({studentCount})
    </button>
    <Transition.Root show={open} as={Fragment}>
       <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pb-0 pt-0 text-left shadow-xl transition-all sm:my-2 w-full sm:max-w-lg sm:p-6">
                  <Dialog.Title className="text-xl px-2 py-2 mb-4 font-semibold leading-6 text-white text-center bg-sky-600">
                        Sending Reminder Email
                  </Dialog.Title>
                  <div className="flex justify-center sm:items-center ">
                    <div className=" text-center sm:mt-0 sm:text-left w-full px-4">
                        <div className=" text-left font-semibold ">
                            <div className='mb-2'>
                              <p className="text-base mb-2">An email will be send to <span className='text-blue-500'>{buttonName}</span> students </p>
                              <p className='text-base my-2 text-sky-600'>Students Count: {studentCount}</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="sm:flex sm:flex-row-reverse px-3 py-2">
                    <button
                        onClick={handleSendEmail}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        >
                        Confirm
                    </button>
                    <button
                        onClick={() => setOpen(false)}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        ref={cancelButtonRef} >
                        Cancel
                    </button>
                  </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
          </div>
        </Dialog>
    </Transition.Root>
  </>
  )
}
