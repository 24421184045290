
// fonts
const fsBold16 = {fontSize:'16px',fontWeight:'bold'}

const fsBold15={fontSize:"15px",fontWeight:'bold'};
const fsBold15Red={fontSize:"15px",fontWeight:'bold',color:'red'};

const fsBold14={fontSize:"14px",fontWeight:'bold'};
const fsBold14Red={fontSize:"14px",fontWeight:'bold',color:'red'};
const fsNoBold14 = {fontSize:"14px"};
const noUnderline = {textDecoration: 'none',fontWeight:'bold'};

const fsNoBold13={fontSize:"13px"};
const fsBold13 = {fontSize:"13px",fontWeight:'bold'};
const fsNoBold12={fontSize:"12px"};
const fsBold12 = {fontSize:"12px",fontWeight:'bold'};

const sideBarFsBold15B ={fontSize:'15px',fontWeight:'bold',color:'#2b2d42'}

// buttons

module.exports = {fsBold12,fsNoBold12,fsBold13,fsNoBold13,fsBold14,fsNoBold14,fsBold16,noUnderline,fsBold15,fsBold14Red,fsBold15Red,sideBarFsBold15B}