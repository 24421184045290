import React,{useState,useEffect,useContext}  from 'react'
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';

export default function RoleOptionBar({schoolId,staffId,staffCurrentRole,getAllTeachers}) {
    const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const userId = authState.user;
    const [allRoles,setAllRoles] = useState([]);
    
    const [roleOptions,setRoleOptions] = useState([])

    const presetRoleOptions = (currentRole)=>{
        const roleOptionsCopy = [...roleOptions];
        roleOptionsCopy.forEach((eachRole)=>{
            
            if(currentRole.includes(eachRole.value)){
                eachRole.selected = true;
            }
        })
        setRoleOptions(roleOptionsCopy);
    }

    const getAllRoles = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/all-roles`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            setAllRoles(response.data.payload)
            const filterRoles = response.data.payload.filter((role)=>{
                return role.roleName !== "student"
            })
            const setUpRoles = filterRoles.map((role,index)=>{
                return {
                    id:role.id,
                    value:role.id,
                    label:role.roleName,
                    bgColor:"bg-sky-500 text-white",selected:false
                }
            })

            const currentRoleSet = new Set(staffCurrentRole.map((role)=>role.role_id)); 
            
            setUpRoles.forEach((role)=>{
                if(currentRoleSet.has(role.id)){
                    role.selected = true;
                }
            })
            setRoleOptions(setUpRoles);
          }
        })
        .catch((e)=>{
          console.log(e);
        })
      }

    const handleRoleSelection = (e)=>{
        const refreshedOptions = [...roleOptions];
        const selectedCount = refreshedOptions.filter((option)=>option.selected === true).length;   // count the number of selected roles

        const currentlySelected = refreshedOptions.find((option)=>option.value === e.target.value).selected === true;
            // check if the current role is selected

        if(currentlySelected){
            // if the current role is selected
            if(selectedCount > 1){
                refreshedOptions.find((option)=>option.value === e.target.value).selected = false;
                // if the current role is selected and the number of selected roles is greater than 1, deselect the current role
            }
        } else {
            refreshedOptions.find((option)=>option.value === e.target.value).selected = true;
            // if the current role is not selected, select the current role
        }
        assignRole(e.target.value);
        setRoleOptions(refreshedOptions);
    }

    const assignRole = async(roleId)=>{
        await useAxiosWithInterceptor.put(`/api/super-admin/schools/${schoolId}/staff/${staffId}/assign`,{role:roleId},{withCredentials:true})
        .then((response)=>{
            if(response === 200){
                getAllTeachers();
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
    //    presetRoleOptions(staffCurrentRole)
       getAllRoles()
    },[])

  return (
    <>
    <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>
        {roleOptions.map((option,index)=>{
            return (
                <button key={option.id} onClick={handleRoleSelection} value={option.value} className={`transition ease delay-100 relative inline-flex items-center ${index === 0?"rounded-l-md":index === roleOptions.length-1?"rounded-r-md":null} px-3 py-1 my-0 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-sky-300 focus:z-10 ${option.selected?option.bgColor:""}`}>{option.label}</button>
                )
            })
        }
    </td>
    </>
  )
}
