import React,{ createContext, useContext, useReducer, useState } from "react";

export const SchoolNewEventFormContext = createContext(null);
export const SchoolNewEventFormDispatchContext = createContext(null);

export default function SchoolNewEventFormProvider({children}) {

    const [formState, dispatch] = useReducer(formReducer, initialForm)

    return (
        <SchoolNewEventFormContext.Provider value={formState}>
            <SchoolNewEventFormDispatchContext.Provider value={dispatch}>
                {children}
            </SchoolNewEventFormDispatchContext.Provider>
        </SchoolNewEventFormContext.Provider>
  )
}

export function formReducer(eventForm, action){

    switch (action.type){
        case 'changed':{
            let eventFormCopy = {...eventForm}
            eventFormCopy[action.name] = action.content
            return eventFormCopy
        }
        case 'clear':{
            let eventFormCopy = {...eventForm}
            eventFormCopy[action.name] = ""
            return eventFormCopy
        }
        case 'toggleGoogleCalendar':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['addGoogleCalendar'] =!eventFormCopy['addGoogleCalendar']
            return eventFormCopy
        }
        case 'toggleInviteParent':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['inviteParents'] = !eventFormCopy['inviteParents']
            return eventFormCopy
        }
        case 'toggleOpenToAll':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['openToAll'] = !eventFormCopy['openToAll']
            return eventFormCopy
        }

        case 'clearAll':{
            let eventFormCopy = {...eventForm}
            eventFormCopy["eventName"] = "";
            eventFormCopy["remark"] = "";
            eventFormCopy["eventType"] = "";
            eventFormCopy["location"] = "";
            eventFormCopy["eventDateTime"] = "";
            eventFormCopy["eventCategory_id"] = "";
            eventFormCopy["eventEndTime"] = "";
            eventFormCopy["signUpDeadline"] = "";
            eventFormCopy["eventEndTime"] = "";
            eventFormCopy["inviteParents"] = false;
            eventFormCopy["addGoogleCalendar"] = false;
            eventFormCopy["openToAll"] = false;
            return eventFormCopy
        }
        case 'dateTimeChanged':{
            let eventFormCopy = {...eventForm}
            let dateTimeInfo = new Date(action.content);
     
            // compare to other
            if(action.name === "eventDateTime"){
                if(dateTimeInfo > new Date()){
                    eventFormCopy[action.name] = action.content;
                    if(dateTimeInfo > eventForm.eventEndTime){
                        eventFormCopy.eventEndTime = ""
                    }
                }else{
                    console.log("visit date time not correct")
                }
            }else if(action.name === "eventEndTime"){
                if(dateTimeInfo > eventForm.eventDateTime){
                    eventFormCopy[action.name] = action.content;
                }else{
                    console.log("visit end time not right")
                }
            }else if(action.name === "signUpDeadline"){
                if(dateTimeInfo < eventForm.eventDateTime){
                    eventFormCopy[action.name] = action.content;
                }else{
                    console.log("sign up deadline time not rite")
                }
            }
            return eventFormCopy
        }
        case 'inviteStudents':{
            let eventFormCopy = {...eventForm};
            let newStudents = action.content;
            newStudents.forEach((newStudent)=>{
                if(!eventFormCopy.selectedStudents.some(prevStudent=>prevStudent.id === newStudent.id)){
                    eventFormCopy.selectedStudents.push(newStudent)
                }
            })
            return eventFormCopy
        }
        case 'unInviteStudents':{
            let eventFormCopy = {...eventForm};
            let removingStudents = action.content;

            eventFormCopy.selectedStudents = eventFormCopy.selectedStudents.filter((prevStudent)=>{
                return !removingStudents.some((student)=>{
                    return student.id === prevStudent.id
                })
            })
            return eventFormCopy
        }
        default:{
            throw Error('Unknown action '+ action.type)
        }
    }
}

const initialForm = {
    eventName:"",
    remark:"",
    eventType:"",
    location:"",
    eventDateTime:"",
    eventEndTime:"",
    signUpDeadline:"",
    inviteParents:false,
    addGoogleCalendar:false,
    openToAll:false,
    eventCategory_id:"",
    selectedStudents:[],
}