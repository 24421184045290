import React,{useContext,useState,useEffect,useCallback} from 'react'
import Layout from '../../components/Layout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { Link,useNavigate } from 'react-router-dom';
import FavoriteButtonXS from '../studentComponents/FavoriteButtonXS';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { getCode } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import { BuildingLibraryIcon } from '@heroicons/react/20/solid';

export default function StudentUniversities() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const navigate = useNavigate();

  const [favorites, setFavorites] = useState([]);
  const checkIsFavorite = (allFavorites,universityId)=>{
    const isFavorite = allFavorites.includes(universityId);
    return isFavorite?<FavoriteButtonXS />:""
  }

  const [universities, setUniversities] = useState([]);
  const [universitiesTable, setUniversitiesTable] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e)=>{
      setSearchValue(e.target.value);

      let filteredList = universities.filter((university)=>{
          const searchKey = ["schoolName","city","state","country"];
          return searchKey.some((key)=>{
            if(typeof university[key] === "string" && university[key].toLowerCase().includes(e.target.value.toLowerCase())){
              return university
            }
          });
      });
      setUniversitiesTable(filteredList)
  }

  const getUniversities = async(offset = 0, append = false)=>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/universities?offset=${offset}&limit=${limit}`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            const newUniversities = response.data.payload.allUniversities.rows;
            const updatedUniversities = append ? [...universities, ...newUniversities] : newUniversities;

            setUniversities(updatedUniversities);

            setUniversitiesTable(updatedUniversities);
            setHasMore(newUniversities.length === limit);

            setFavorites(response.data.payload.favoriteUniversityIds);
          }
        })
        .catch((error)=>{
          console.log(error)
        })
        .finally(() => {
          setLoading(false);
      });
  }

  const searchUniversities = async (searchQuery) => {
      setLoading(true);
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/universities/search?search=${searchQuery}`, { withCredentials: true })
      .then((response) => {
          if (response.status === 200) {
              const newUniversities = response.data.payload || [];
              // setUniversities(newUniversities);
              setSearchOptions(newUniversities.map(u => ({
                  value: u.schoolName,
                  label: `${u.schoolName}, ${u.city}, ${u.state}, ${u.country}`,
                  id: u.id
              })));
          }
      })
      .catch((error) => {
          console.log(error);
      })
      .finally(() => {
          setLoading(false);
      });
  };

  const debouncedSearch = useCallback(debounce(searchUniversities, 500), []);


  const loadMore = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    getUniversities(newOffset, true);
  };

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
    debouncedSearch(inputValue);
  };

  const handleSearchChange = (selectedOption) => {
    const searchQuery = selectedOption ? selectedOption.value : '';
    setSearchValue(searchQuery);
    setOffset(0);
    searchUniversities(searchQuery);
    // getUniversities(0, searchQuery);
    if (selectedOption) {
        navigate(`/student/universities/${selectedOption.id}`);
    }
};

  useEffect(()=>{
    getUniversities();
    document.title = "Universities";
  },[]);

    return (
    <>
      <Layout>
        <div className='w-full h-max bg-opacity-75'>
        <div className='bg-opacity-75 px-1 py-2 lg:mx-10'>
          <p className='text-xl font-bold mb-2'>Universities</p>
          <div className='my-2 flex align-middle'>
            <div className='flex items-center w-full lg:w-4/5 md:w-4/5 sm:w-4/5 text-sm'>
              <p className='py-2 px-3 font-semibold border-1 text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-600'>Search University</p>
              <Select
                value={{ value: searchValue, label: searchValue }}
                onInputChange={handleInputChange}
                onChange={handleSearchChange}
                options={searchOptions}
                placeholder='School Name, City or Country'
                className='shadow-sm block flex-1 border-0 bg-slate-100 text-gray-900 font-semibold placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'
                // isClearable
                />
            </div>
          </div>

{/* table */}
          <div className='bg-teal-500 rounded-2xl'>
            <div className=''>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className='text-white rounded-t-2xl'>
                <tr >
                  <th scope="col" className="px-3 py-2.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0 w-[300px]">Name</th>
                  <th scope="col" className="hidden px-3 py-2.5 text-center text-sm font-semibold lg:table-cell w-[150px]" >City</th>
                  <th scope="col" className="hidden px-3 py-2.5 text-center text-sm font-semibold lg:table-cell w-[200px]" >State</th>
                  <th scope="col" className="hidden px-3 py-2.5 text-center text-sm font-semibold lg:table-cell w-[300px]" >Country</th>
                  <th scope="col" className="px-3 py-2.5 text-center text-sm">Website</th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {universitiesTable.map((university)=>{
                    return (
                      <tr key={university.id} className='hover:bg-gray-50'>
                        <td className='text-sm pl-4 py-2 font-semibold '>
                          <div className='flex items-center space-x-2'>
                            {
                              university?.profilePicture? 
                              <img src={university?.profilePicture} className="h-10 w-10 rounded-lg mr-2" />
                              :
                              <div className='h-10 w-10'>
                                <BuildingLibraryIcon />
                              </div>
                            }
                            <Link to={`/student/universities/${university?.id}`}>
                              <p className='inline-block align-middle text-blue-600'>
                                {university?.schoolName}
                              </p>
                            </Link>
                            {checkIsFavorite(favorites,university?.id)}
                          </div>
                          <dl className="font-normal lg:hidden">
                            <dt>{university?.city}</dt>
                            <dt>{university?.state}</dt>
                            <dt>{university?.country}</dt>
                          </dl>
                        </td>
                        <td className='hidden px-3 py-2 text-sm text-gray-500 lg:table-cell text-center'>{university?.city}</td>
                        <td className='hidden px-3 py-2 text-sm text-gray-500 lg:table-cell text-center'>{university?.state}</td>
                        <td className='hidden px-3 py-2 text-sm text-gray-500 lg:table-cell text-center text-left'>
                          <div className='flex items-center'>
                            <ReactCountryFlag countryCode={getCode(university.country)} />
                            <p className='ml-4'>{university?.country}</p>
                          </div>
                        </td>
                        <td className='text-sm text-center'>
                          <a href={university?.website} className='no-underline hover:underline '>
                          {university?.website}</a>
                        </td>
                      </tr>
                    )})
                  }
                </tbody>
              </table>
              {hasMore && !loading && (
                <button onClick={loadMore} className="w-full text-blue-500 font-semibold flex justify-center py-2 hover:bg-slate-300 bg-slate-200 rounded-b-2xl ">
                  Show more
                </button>
                )}
                {loading && (
                  <div className="flex justify-center py-4 bg">
                    <p>Loading...</p>
                  </div>
              )}
            </div>
        </div>
      </div>
      </div>
      </Layout>
    </>
  )
}