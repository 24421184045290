import React,{useState,useEffect,useContext}  from 'react'
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { useBanner } from '../../../services/SystemBannerMessage';

export default function ActivationButton({studentInfo,refreshStudents}) {

  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
    // console.log(studentInfo)
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {showBanner} = useBanner();
  const [archiveOptions, setArchiveOptions] = useState([
        
    ]);

  const handleActivate = async()=>{
    if(!studentInfo.activated){
        await useAxiosWithInterceptor.put(`/api/super-admin/schools/${schoolId}/students/${studentInfo.id}/activate`,{},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                console.log("hi")
                refreshStudents();
            }
        } )
        .catch((error)=>{
            console.log(error)
        })
    } else{
        showBanner("Student is already activated","bg-green-400")
    }
}
    const handleDeactivate = async()=>{
        if(studentInfo.activated){
            await useAxiosWithInterceptor.put(`/api/super-admin/schools/${schoolId}/students/${studentInfo.id}/deactivate`,{},{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    refreshStudents();
                }
            } )
            .catch((error)=>{
                console.log(error)
            })

        } else{
            showBanner("Student is already deactivated","bg-red-400")
        }
    }

  return (
    <>
        <button 
          onClick={handleActivate}
          className={`transition ease delay-100 relative inline-flex items-center px-3 py-1 my-0 text-sm font-semibold hover:bg-green-600 focus:z-10 rounded-l-lg shadow-sm hover:pointer ${studentInfo.activated?'bg-green-500 text-white':""}`}>Activate</button>
        <button 
          onClick={handleDeactivate}
          className={`transition ease delay-100 relative inline-flex items-center px-3 py-1 my-0 text-sm font-semibold hover:bg-gray-500 hover:text-white focus:z-10 rounded-r-lg shadow-sm text-sm hover:pointer ${studentInfo.activated?'':"bg-gray-600 text-white"}`}>De-activate</button>
    </>
  )
}
