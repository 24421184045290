import React,{useState,useEffect,} from 'react'
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import Layout from '../../../../components/Layout';
import useAuthenticationContext from '../../../../../hooks/authStateHook';

import { useNavigate,useLocation } from 'react-router-dom';
import ProfileAvatar from '../../../../components/Avatar';
import DividerThin from '../../../../components/DividerThin';
import UserEditPhoto from '../../../../components/UserEditPhoto';

export default function CounselorProfile() {
  const [userInfo, setUserInfo] = useState();
  const useAxiosWithInterceptor = axiosWithInterceptor();
  
  const {authState,setAuthState} = useAuthenticationContext();
  const userId = authState.user;
  const navigateTo = useNavigate();
  const location = useLocation();
  
  const getUser = async ()=>{
        
    await useAxiosWithInterceptor.get(`/api/user/${userId}profile`,{withCredentials:true})
      .then((response)=>{
          if(response.status === 200){
            setUserInfo(response.data.payload);
          }
      }).catch((err)=>{
          console.log(err);
          setAuthState({})
          navigateTo('/login',{state:{from: location},replace:true});
      });
    }
  
  useEffect(()=>{
      getUser();
    },[]);
  
  return (
    <Layout>
      <div>
        <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'>
            <div className='p-6 rounded-md shadow-md my-2 lg:mr-4 '>
              <div className='grid grid-cols-1 '>
                <div className='mb-3 '>
                  <div className='grid justify-items-center'>
                   <ProfileAvatar user={userInfo} size={48}/>
                  </div>
                  <div className='text-center'>
                    <UserEditPhoto refreshUser={getUser} />
                  </div>
                  <div className='grid grid-cols-1 my-2'>
                    <p className='text-center font-bold'>{userInfo?.firstName} {userInfo?.lastName}</p>
                  </div>
                </div>
              </div>
              
              <DividerThin/>
  
              <div className='p-2 space-y-2'>
                  <p>Role: {userInfo?.role}</p>
                  <p>Email: {userInfo?.email}</p>
              </div>
            </div>
  
  {/* basic info */}
            <div className='p-6 rounded-md shadow-md my-2 lg:mr-3 space-y-2'>
              <p>{userInfo?.school?.schoolName}</p>
              <p>Member Since: {userInfo?.createdAt.split("T")[0]}</p>
            </div>
        </div>
        <div className='shadow-md rounded p-6 lg:mr-3'>
          <div>
            Table here
          </div>
        </div>
      </div>
    </Layout>
  )
}
