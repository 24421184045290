import React, { useContext,useReducer, useEffect, createContext } from 'react'
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';
import { useParams } from 'react-router-dom';
import moment from 'moment';

export const EditEventFormContext = createContext(null);
export const EditEventFormDispatchContext = createContext(null);

export default function EditEventFormProvider({children}) {

    const [formState, dispatch] = useReducer(formReducer, initialForm);

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {eventId} = useParams();
  
  const loadEventData = async ()=>{
     await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/events/${eventId}`,{withCredentials:true})
    .then((response)=>{
        dispatch(
            {
                type:'initialize',
                content: response.data.payload
            }
        )}
    )
    .catch((error)=>{
        console.log("error in loading existing data",error)
        }   
    )
  }
  
  useEffect(()=>{
    loadEventData()
  },[])

  return (
    <EditEventFormContext.Provider value={formState}>
        <EditEventFormDispatchContext.Provider value={dispatch}>
            {children}
        </EditEventFormDispatchContext.Provider>
    </EditEventFormContext.Provider>
  )
}

export function formReducer(eventForm, action){
    switch(action.type){
        case 'initialize':{
            let eventFormCopy = {...eventForm}
            let originalData = action.content;
            const generalInfo = originalData;
            const universities = originalData.visitingUniversities.map((university)=>{ return university.university});
            const invitations = originalData.invitations;

            eventFormCopy.eventName = generalInfo.eventName;
            eventFormCopy.remark = generalInfo.remark;
            eventFormCopy.location = generalInfo.location;
            eventFormCopy.eventType = generalInfo.eventType;
            eventFormCopy.eventCategory_id = generalInfo.eventCategory_id;
            eventFormCopy.eventDateTime = moment(generalInfo.eventDateTime).format(); 
                // Convert to local timezone
            eventFormCopy.eventEndTime = moment(generalInfo.eventEndTime).format(); 
                // Convert to local timezone
            eventFormCopy.signUpDeadline = moment(generalInfo.signUpDeadline).format(); 
                // Convert to local timezone

            eventFormCopy.selectedStudents = invitations.map((invitation)=>{return invitation.student});

            eventFormCopy.selectedUniversities = universities;

            eventFormCopy.addGoogleCalendar = generalInfo.addGoogleCalendar;
            eventFormCopy.openToAll = generalInfo.openToAll;
            eventFormCopy.inviteParents = generalInfo.inviteParents;

            return eventFormCopy
        }

        case 'changed':{
            let eventFormCopy = {...eventForm}
            eventFormCopy[action.name] = action.content
            return eventFormCopy
        }
        case 'clear':{
            let eventFormCopy = {...eventForm}
            eventFormCopy[action.name] = ""
            return eventFormCopy
        }
        case 'toggleGoogleCalendar':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['addGoogleCalendar'] =!eventFormCopy['addGoogleCalendar']
            return eventFormCopy
        }
        case 'toggleInviteParent':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['inviteParents'] = !eventFormCopy['inviteParents']
            return eventFormCopy
        }
        case 'toggleOpenToAll':{
            let eventFormCopy = {...eventForm}
            eventFormCopy['openToAll'] = !eventFormCopy['openToAll']
            return eventFormCopy
        }
        case 'dateTimeChanged':{
            let eventFormCopy = {...eventForm}
            let dateTimeInfo = moment(action.content).format();
            if(action.name === "eventDateTime"){
                if(dateTimeInfo > moment().format()){
                    eventFormCopy[action.name] = action.content;
                    if(dateTimeInfo > eventForm.eventEndTime){
                        eventFormCopy.eventEndTime = ""
                    } 
                }else{
                    console.log("visit date time not correct")
                }
            }else if(action.name === "eventEndTime"){
                if(dateTimeInfo > eventForm.eventDateTime){
                    eventFormCopy[action.name] = action.content;
                }else{
                    console.log("visit end time not right")
                }
            }else if(action.name === "signUpDeadline"){
                if(dateTimeInfo < eventForm.eventDateTime){
                    eventFormCopy[action.name] = action.content;
                }else{
                    console.log("sign up deadline time not rite")
                }
            }
            return eventFormCopy
        }
        case 'selectUniversity':{
            let eventFormCopy = {...eventForm}
            let newAddedUniversity = action.content.content;
            const universityExist = eventFormCopy.selectedUniversities.some((university)=>university.id === newAddedUniversity.id);
            if(!universityExist){
                eventFormCopy.selectedUniversities = [...eventFormCopy.selectedUniversities,newAddedUniversity]
            }
            return eventFormCopy
        }
        case 'deSelectUniversity':{
            let eventFormCopy = {...eventForm};
            let universityId = action.content;
            eventFormCopy.selectedUniversities = eventFormCopy.selectedUniversities.filter((university)=>university.id !==universityId)
            return eventFormCopy
        }
        case 'inviteStudents':{
            let eventFormCopy = {...eventForm};
            let newStudents = action.content;
            newStudents.forEach((newStudent)=>{
                if(!eventFormCopy.selectedStudents.some(prevStudent=>prevStudent.id === newStudent.id)){
                    eventFormCopy.selectedStudents.push(newStudent)
                }
            })
            return eventFormCopy
        }
        case "unInviteStudents":{
            let eventFormCopy = {...eventForm};
            let removingStudents = action.content;

            eventFormCopy.selectedStudents = eventFormCopy.selectedStudents.filter((prevStudent)=>{
                return !removingStudents.some((student)=>{
                    return student.id === prevStudent.id
                })
            })
            return eventFormCopy
        }
        default:{
            throw Error('Unknown action '+ action.type)
        }
    }
}

const initialForm = {
    eventName:"",
    remark:"",
    eventType:"",
    location:"",
    eventDateTime:"",
    eventEndTime:"",
    signUpDeadline:"",
    eventCategory_id:"",
    addGoogleCalendar:false,
    inviteParents:false,
    openToAll:false,
    selectedUniversities:[],
    selectedStudents:[],
}