import React, { useEffect } from 'react';
import UnAuthLayout from './components/UnAuthLayout';
import { Link } from 'react-router-dom';

export default function TermsOfService() {

    useEffect(() => {
        document.title = "Schoolley | Terms of Service";
      }, []);
      
  return (
    <UnAuthLayout>
    <div className="md:w-full w-full">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg mt-6">
        <h1 className="text-3xl font-bold text-gray-900 mb-4 text-center">Terms of Service</h1>
        <p className="text-sm text-gray-600 mb-2"><strong>Last Updated:</strong> 2024-09-01</p>

        {/* Definitions Section */}
        <div>
            <p>Welcome to Schoolley! These Terms of Service govern your access to and use of our international educational platform. By accessing or using Schoolley, you agree to be bound by these terms. If you do not agree to all the terms and conditions, you must not use our services.
            </p>
            <h2 className="text-xl font-semibold mt-4">1. Definitions</h2>
            <p>The following terms apply to these Terms and Conditions, Privacy Statement and Disclaimer Notice, and all Agreements:
            <ul className="list-disc pl-6">
                <li><strong>Client, You, and Your</strong> refers to you, the person accessing this service and compliant to the terms and conditions of the Company.</li>
                <li><strong>The Company, Ourselves, We, Our, and Us</strong>, refers to our Company, Schoolley.</li>
                <li><strong>Party, Parties, or Us</strong>, refers to both the client and ourselves collectively.</li>
            </ul>
            </p>
        </div>

        {/* License Section */}
        <div>
            <h2 className="text-xl font-semibold mt-4">2. License</h2>
            <p>Unless otherwise stated, Schoolley and/or its licensors own the intellectual property rights for all material on Schoolley. All intellectual property rights are reserved. You may access this from Schoolley for your own personal use subjected to restrictions set in these terms and conditions.</p>
        </div>

        {/* User Responsibilities Section */}
        <div>
            <h2 className="text-xl font-semibold mt-4">3. User Responsibilities</h2>
            <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.</p>
        </div>

        {/* Content Section */}
        <div>
            <h2 className="text-xl font-semibold mt-4">4. Content</h2>
            <p>Users can post and exchange opinions and information in certain areas of the website. Schoolley does not filter, edit, publish, or review comments prior to their presence on the website. Comments do not reflect the views and opinions of Schoolley, its agents, or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions.</p>
        </div>
        {/* Hyperlinking Section */}
        <div>
            <h2 className="text-xl font-semibold mt-4">5. Hyperlinking to Our Content
            </h2>
            <p>Organizations may link to our home page, to publications, or to other website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">6. iFrames
            </h2>
            <p>Without prior approval and written permission, you may not create frames around our webpages that alter in any way the visual presentation or appearance of our website.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">7. Privacy and Data Protection
            </h2>
            <p>We take your privacy seriously. Refer to our <Link to={'/privacy-policies'} className='text-blue-500'>Privacy Policy</Link> for information on how we collect, use, and protect your data.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">8. Reservation of Rights
            </h2>
            <p>We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our website upon request.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">9. Removal of links from our website
            </h2>
            <p>If you find any link on our website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to do so or to respond to you directly.</p>
        </div>
        {/* Disclaimer Section */}
        <div>
            <h2 className="text-xl font-semibold mt-4">10. Disclaimer
            </h2>
            <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">11. Limitation of Liability
            </h2>
            <p>In no event shall Schoolley, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. Schoolley, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website..</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">12. Governing Law & Jurisdiction
            </h2>
            <p>These terms and conditions will be governed by and interpreted in accordance with the laws of the jurisdiction in which Schoolley operates, and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Jurisdiction] for the resolution of any disputes.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">13. Dispute Resolution
            </h2>
            <p>Disputes will be resolved through final and binding arbitration, except where prohibited by law. The arbitration will be conducted in [City, State], and the laws of [Jurisdiction] will apply.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">14. Changes to Terms
            </h2>
            <p>We reserve the right to modify these terms at any time. If we make changes, we will notify you by revising the date at the top of these terms and, in some cases, provide more prominent notice. Your continued use of the services after such notice will constitute your agreement to the changes.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">15. Termination and Suspension
            </h2>
            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, a breach of these Terms.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">16. Content Ownership and Use
            </h2>
            <p>All content displayed on Schoolley, whether provided by us or created by users, remains the intellectual property of the respective owners. You are permitted to use the content as expressly authorized by Schoolley and/or its users, provided that you do not:</p>
            <ul className="list-disc pl-6">
                <li>Copy, modify, or distribute any content without permission</li>
                <li>Use the content for any commercial purposes</li>
                <li>Remove any copyright or proprietary notices</li>
            </ul>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">17. Modifications to the Service and Prices
            </h2>
            <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">18. Errors, Inaccuracies, and Omissions
            </h2>
            <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times, and availability. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
        </div>
        <div>
            <h2 className="text-xl font-semibold mt-4">19. Prohibited Uses
            </h2>
            <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
        </div>

        <div>
            <h2 className="text-xl font-semibold mt-4">
            Contact Us
            </h2>
            <p className="text-gray-800">
                If you have any questions or concerns about our Terms of Service, please contact us at:
            </p>
            <p className="text-gray-800">
                Email: <span className='font-semibold text-blue-500'>contact@schoolley.com</span><br/>
                Website: <a href="https://schoolley.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">https://www.schoolley.com</a>
            </p>
        </div>
        {/* Additional sections can be added as needed */}
        </div>
    </div>
    </UnAuthLayout>
  )
}
