import React, { useEffect,useContext, useState, useRef} from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { EditEventFormContext, EditEventFormDispatchContext } from '../providers/EditEvent_FormContext';
import moment from 'moment';

export default function EditEventEndDatePicker() {
    const editEventFormState = useContext(EditEventFormContext);
    const dispatch = useContext(EditEventFormDispatchContext);

    const [startDate, setStartDate] = useState();
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date());

    const handleSelectDate = (date)=>{
      try {
        setStartDate(date)
        handleContextDateTime(date)
      } catch (error) {
        console.log(error)
      }
    }
      
    const handleContextDateTime = (dateTime)=>{
        dispatch({
            type:"dateTimeChanged",
            name:"eventEndTime",
            content: dateTime
        })
        // formCheckDispatch({
        //     type:"submitCheckEmpty",
        //     currentField: "eventDateTime"
        // })
        // console.log(dateTime)
        // console.log(editEventFormState.eventDateTime)
      }
    // preload event end time if it exists

    // set min max dates for time picker
    useEffect(() => {
      console.log(editEventFormState.eventDateTime)
      if (editEventFormState.eventDateTime) {
          const minDate = new Date(editEventFormState.eventDateTime);
          setMinTime(new Date(minDate));
          setMaxTime(new Date(minDate).setHours(23, 59, 59, 999));
      } 
  }, [editEventFormState.eventDateTime]);

  useEffect(() => {
      if (editEventFormState.eventEndTime) {
          // if the new updated eventDateTime is later than the current eventEndTime, update the eventEndTime to be 1 hour after the eventDateTime
          
          setStartDate(new Date(editEventFormState.eventEndTime));
      }
  }, [editEventFormState.eventEndTime]);

  return (
    <>
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2 ">
       <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            Event Date Time
        </label>
      <ReactDatePicker className='px-3 py-1 font-semibold text-sky-800 rounded-2xl bg-red-500 text-white'
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
          selected={startDate}
          onChange={(date) => handleSelectDate(date)}
          monthsShown={2}
          minDate={new Date(editEventFormState.eventDateTime)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={10}
          timeCaption="time"
          dateFormat="MMM d, yyyy h:mm aa"
          minTime={minTime} 
          maxTime={maxTime}
        />
    </div>
    </>
  )
}
