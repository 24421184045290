import React,{ createContext, useContext, useReducer, useState } from "react";

import { EditEventFormContext } from "./EditEvent_FormContext";

export const EditEventFormRequiredFieldsContext = createContext(null);
export const EditEventFormRequiredFieldsDispatchContext = createContext(null);

export default function EditEventFormValidationProvider({children}) {

    const eventForm = useContext(EditEventFormContext);
    
    const [requiredFieldsState, dispatch] = useReducer((state,action)=> editEventFormValidationReducer(state, action, eventForm), initialFormValidationState);

  return (
    <EditEventFormRequiredFieldsContext.Provider value={requiredFieldsState}>
      <EditEventFormRequiredFieldsDispatchContext.Provider value={dispatch}>
        {children}
      </EditEventFormRequiredFieldsDispatchContext.Provider>
    </EditEventFormRequiredFieldsContext.Provider>
  )
}

export function editEventFormValidationReducer(eventFormState, action, editEventForm){
    switch(action.type){
        case "submitCheckEmpty":{
            let eventFormStateCopy = {...eventFormState};

            // console.log(action.currentField)
            // console.log(typeof editEventForm[action.currentField])
            // console.log(editEventForm[action.currentField].length)

            if(!editEventForm[action.currentField].length >0 || editEventForm[action.currentField] === null || editEventForm[action.currentField] === undefined){
                eventFormStateCopy[action.currentField] = false;
            }else{
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        case "inputLevelCheckEmpty": {
            let eventFormStateCopy = {...eventFormState};
            if(editEventForm[action.currentField].length >0){
                
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        default:{
            throw Error('Unknown action '+action.type)
        }
    }
}

let initialFormValidationState = {
  eventName:true,
  remark:true,
  // eventType:true,
  location:true,
  eventDateTime:true,
  eventEndTime:true,
  signUpDeadline:true,
  // selectedUniversities:true,
  // selectedStudents:true,
  // inviteParents:false,
}