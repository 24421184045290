import React from 'react'
import { Outlet } from 'react-router-dom';
import useAuthenticationContext from '../hooks/authStateHook';
import NotFound404 from '../pages/404';
import UserSubscriptionWarning from '../pages/UserSubscriptionWarning'

export default function SubscriptionCheck() {
    const {authState} = useAuthenticationContext();
  return (
    authState.subscriptionStatus ==="active"?<Outlet />
    :<UserSubscriptionWarning />
  )
}
