import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { Dialog, Transition} from '@headlessui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBanner } from '../../../../../services/SystemBannerMessage';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import ImportanceOptions from './ImportanceOptions';

export default function AdminEditAcademicBasis({refreshData}) {
  const [open, setOpen] = useState(false);
    const navigateTo = useNavigate();
    const location = useLocation();
    const {showBanner} = useBanner();

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {universityId} = useParams();

    const [originalData, setOriginalData] = useState({});

    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');

    const [formObj, setFormObj] = useState({
      rigorOfRecord: '',
      classRank: '',
      academicGPAImportance: '',
      satImportance: '',
      applicationEssay: '',
      recommendations: '',
    });

    const handleFormChange = (e)=>{
        const { name, value } = e.target;
        setFormObj({
        ...formObj,
        [e.target.name]: e.target.value
        });
    }

    const handleOpen = ()=>{
        setOpen(true);
        getAcademicBasis();
    }
    const handleClose = ()=>{
        setOpen(false);
        setFormObj(originalData);
    }

    const getAcademicBasis = async ()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/universities/${universityId}/requirements`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
              let originalInfo = response.data.payload;
              let setFormData ={
                rigorOfRecord: originalInfo.rigorOfRecord ||"",
                classRank: originalInfo.classRank ||"",
                academicGPAImportance: originalInfo.academicGPAImportance ||"",
                satImportance: originalInfo.satImportance ||"",
                applicationEssay: originalInfo.applicationEssay ||"",
                recommendations: originalInfo.recommendations ||""
              }
                setFormObj(setFormData);
                setOriginalData(setFormData);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const modifyIfUpdated = (originalData, updatedData)=>{
      let modifiedData = {};
      Object.keys(updatedData).forEach((key)=>{
          if(updatedData[key] !== originalData[key]){
            modifiedData[key] = updatedData[key];
          }
      });
      return modifiedData;
  }

    const handleUpdateUniversity = async ()=>{
      let modifiedData = modifyIfUpdated(originalData,formObj);
      
      await useAxiosWithInterceptor.put(`/api/super-admin/universities/${universityId}/requirements`,modifiedData,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          showBanner("Academic Basis updated successfully","bg-green-500");
          setOpen(false);
          refreshData()
        }
      })
    }


  return (
    <>
    <button 
      onClick={handleOpen}
      className='mx-1'>
      <PencilSquareIcon className='h-6 w-6 '/>
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:p-6 w-fit sm:w-full">
                <div className='lg:w-[42rem] md:w-[38rem] sm:w-[38rem] w-[28rem] font-semibold'>
                {/* content */}
                  <div className='lg:grid grid-cols-4 my-3 space-x-3 md:space-y-2 sm:space-y-2 xs:space-y-2 items-center'>
                      <label htmlFor="rigorOfSecondarySchoolRecord" className="col-span-1 block text-sm font-medium text-gray-900 ">Rigor Of Secondary School Record</label>
                      <div className='col-span-3'>
                        <ImportanceOptions 
                        name='rigorOfRecord' 
                        initialValue={formObj.rigorOfRecord} 
                        handleFormChange={handleFormChange}/>
                      </div>
                  </div>

                  <div className='lg:grid grid-cols-4 my-3 space-x-3 md:space-y-2 sm:space-y-2 xs:space-y-2 items-center'>
                      <label htmlFor="classRank" className="col-span-1 block text-sm font-medium text-gray-900 ">Class Rank</label>
                      <div className='col-span-3'>
                        <ImportanceOptions 
                        name='classRank' 
                        initialValue={formObj.classRank} 
                        handleFormChange={handleFormChange}/>
                      </div>
                  </div>
                  <div className='lg:grid grid-cols-4 my-3 space-x-3 md:space-y-2 sm:space-y-2 xs:space-y-2 items-center'>
                      <label htmlFor="academicGPAImportance" className="col-span-1 block text-sm font-medium text-gray-900 ">Academic GPA</label>
                      <div className='col-span-3'>
                        <ImportanceOptions 
                        name='academicGPAImportance' 
                        initialValue={formObj.academicGPAImportance} 
                        handleFormChange={handleFormChange}/>
                      </div>
                  </div>

                  <div className='lg:grid grid-cols-4 my-3 space-x-3 md:space-y-2 sm:space-y-2 xs:space-y-2 items-center'>
                      <label htmlFor="satImportance" className="col-span-1 block text-sm font-medium text-gray-900 ">Standardized Test Scores</label>
                      <div className='col-span-3'>
                        <ImportanceOptions 
                        name='satImportance' 
                        initialValue={formObj.satImportance} 
                        handleFormChange={handleFormChange}/>
                      </div>
                  </div>

                  <div className='lg:grid grid-cols-4 my-3 space-x-3 md:space-y-2 sm:space-y-2 xs:space-y-2 items-center'>
                      <label htmlFor="applicationEssay" className="col-span-1 block text-sm font-medium text-gray-900 ">Application Essay</label>
                      <div className='col-span-3'>
                        <ImportanceOptions 
                        name='applicationEssay' 
                        initialValue={formObj.applicationEssay} 
                        handleFormChange={handleFormChange}/>
                      </div>
                  </div>
                  <div className='lg:grid grid-cols-4 my-3 space-x-3 md:space-y-2 sm:space-y-2 xs:space-y-2 items-center'>
                      <label htmlFor="recommendations" className="col-span-1 block text-sm font-medium text-gray-900 ">Recommendations</label>
                      <div className='col-span-3'>
                        <ImportanceOptions 
                        name='recommendations' 
                        initialValue={formObj.recommendations} 
                        handleFormChange={handleFormChange}/>
                      </div>
                  </div>
                </div>
                <div>
                  <p className='text-green-500 font-semibold'>{message}</p>
                  <p className='text-red-500 font-semibold'>{warning}</p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={handleUpdateUniversity}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Save
                  </button>
                </div>
                </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  )
}
