import React,{useContext,useEffect,useState} from 'react';
import Layout from '../../../components/Layout'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import Setting_AddDivisionButton from '../administratorComponents/Setting_AddDivisionButton';
import moment from 'moment';
import Setting_AddCategoryButton from '../administratorComponents/Setting_AddCategoryButton';
import DeleteDivisionButton from '../administratorComponents/Setting_DeleteDivisionButton';
import EditDivisionButton from '../administratorComponents/Setting_EditDivisionButton';
import DeleteCategoryButton from '../administratorComponents/Setting_DeleteCategoryButton';
import EditCategoryButton from '../administratorComponents/Setting_EditCategoryButton';
import { useBanner } from '../../../../services/SystemBannerMessage';

export default function AdministratorSettings() {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const {showBanner} = useBanner();

    const [existingDivisions, setExistingDivisions] = useState([]);
    const [existingCategories, setExistingCategories] = useState([]);
    const [existingEventTypes, setExistingEventTypes] = useState([]);

    const getExistingEventTypes = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/settings/event-types`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            setExistingEventTypes(response.data.payload)
          }
        })
        .catch((error)=>{
          console.log(error)
        })
      }

    const getDivisions = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/settings/divisions`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    // console.log(response.data.payload)
                    setExistingDivisions(response.data.payload);
                }
            }).catch((error)=>{
                console.log(error)
            })
    }

    const getEventCategories = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/settings/event-categories`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setExistingCategories(response.data.payload);
                }
            }).catch((error)=>{
                console.log(error)
            })
    }

    const handleDeleteDivision = async(divisionId) =>{
        await useAxiosWithInterceptor.delete(`/api/user/${userId}/school/${schoolId}/administrator/settings/divisions/${divisionId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    getDivisions();
                }
            }).catch((error)=>{
                console.log(error)
            })
    }

    const handleDeleteCategory = async(categoryId) =>{
        await useAxiosWithInterceptor.delete(`/api/user/${userId}/school/${schoolId}/administrator/settings/event-categories/${categoryId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    getEventCategories();
                    showBanner("category successfully deleted","bg-green-400")
                }
            }).catch((error)=>{
                console.log(error.response.data.message);
                showBanner("Canot not delete Event Category because it is in use","bg-yellow-400")
            })
    }
    const handleEditDivision = async(divisionId,formObj) =>{
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/settings/divisions/${divisionId}`,formObj,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    getDivisions();
                }
            }).catch((error)=>{
                console.log(error)
            })
    }
    const handleEditCategory = async(categoryId,formObj) =>{
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/settings/event-categories/${categoryId}`,formObj,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    getEventCategories();
                }
            }).catch((error)=>{
                console.log(error)
            })
    }

    useEffect(()=>{
        // getDivisions();
        getEventCategories();
        getExistingEventTypes()
    },[]);

  return (
   <Layout>
    <div className='md:w-full w-full'>
        {/* <div className='bg-opacity-75 px-2 py-2'>
          <p className='text-3xl font-bold py-1 px-2'>School Divisions</p>
            <div className="px-2">
                <div className="sm:flex sm:items-center">
                    <div className="w-96">
                        <p className="my-2 text-sm font-semibold text-gray-500">
                            List of divisions in your school, you can add and remove divisions here.
                        </p>
                    </div>
                    <div className="my-2 sm:ml-4 sm:mt-0 sm:flex-none">
                        <Setting_AddDivisionButton refreshData={getDivisions} />
                    </div>
                </div>
                <div className="mt-1 flow-root">
                <div className=" ring-opacity-5 overflow-x-auto ">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className=" divide-y divide-gray-300">
                        <thead>
                            <tr className="divide-x divide-gray-200">
                                <th scope="col" className="py-3.5 px-4 text-sm font-semibold text-gray-900 sm:pl-0">
                                    Division
                                </th>
                                <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900">
                                    Description
                                </th>

                                <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                                    Date Modified
                                </th>
                                <th scope='col' className='px-4 text-center text-sm font-semibold text-gray-900 sm:pr-0'>
                                    Edit
                                </th>
                                <th scope='col' className='px-4 text-center text-sm font-semibold text-gray-900 sm:pr-0'>
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {existingDivisions.map((division) => (
                            <tr key={division.id} className="divide-x divide-gray-200">
                                <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900 sm:pl-0">
                                {division.divisionName}
                                </td>
                                <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">{division.divisionDescription}</td>
                                <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500 sm:pr-0">{moment(division.updatedAt).format('LLLL')}</td>
                                <td className='whitespace-nowrap px-4 text-sm text-gray-500 sm:pr-0 '>
                                    <EditDivisionButton
                                        division = {division}
                                        editDivision={handleEditDivision}
                                     />
                                </td>
                                <td className='whitespace-nowrap px-4 text-sm text-gray-500 sm:pr-0 '>
                                    <DeleteDivisionButton 
                                        division = {division}
                                        deleteDivision={handleDeleteDivision}
                                        />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div> */}
        
        {/* ----------------- separator ----------------- */}
        <div className="w-full border-t border-gray-300" /> 
        {/* ----------------- separator ----------------- */}
        <div className='bg-opacity-75 px-2 py-2 my-2'>
          <p className='text-3xl font-bold py-1 px-2'>Event Categories</p>
            <div className="px-2">
                <div className="sm:flex sm:items-center">
                    <div className="w-96">
                        <p className="my-2 text-sm font-semibold text-gray-500">
                            List of Categories of Events, you can add and remove events here.
                        </p>
                    </div>
                    <div className="my-2 sm:ml-4 sm:mt-0 sm:flex-none">
                        <Setting_AddCategoryButton 
                            refreshData={getEventCategories}
                            category={existingCategories}
                            defaultEventTypes={existingEventTypes}
                            />
                    </div>
                </div>
                <div className="mt-1 flow-root">
                <div className="ring-opacity-5 overflow-x-auto ">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className=" divide-y divide-gray-300">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th scope="col" className="py-3.5 px-4 text-sm font-semibold text-gray-900 sm:pl-0">
                                Category Name
                            </th>
                            
                            <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900">
                                Description
                            </th>
                            <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900">
                                Event Types
                            </th>

                            <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                                Date Modified
                            </th>
                            <th scope='col' className='px-4 text-center text-sm font-semibold text-gray-900 sm:pr-0'>
                                Edit
                            </th>
                            <th scope='col' className='px-4 text-center text-sm font-semibold text-gray-900 sm:pr-0'>
                                Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {existingCategories.map((category) => (
                            <tr key={category.id} className="divide-x divide-gray-200">
                                <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900 sm:pl-0">
                                {category.categoryName}
                                </td>
                                <td className="whitespace-nowrap py-3 px-2 text-sm text-gray-500">{category.categoryDescription}</td>
                                <td className="flex flex-wrap py-3 px-1 text-gray-500 ">
                                    {category.categoryAssociation.map((association)=>{
                                        return(
                                            <p key={association.id} className='flex whitespace-nowrap items-center px-2 py-0.5 bg-sky-500 text-white text-sm rounded-xl font-semibold border-1 mx-0.5'>{association.eventType.typeName}</p>
                                        )
                                      }
                                    )}
                                </td>
                                <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500 sm:pr-0">{moment(category.updatedAt).format('LLLL')}</td>
                                <td className='whitespace-nowrap px-4 text-sm text-gray-500 sm:pr-0 '>
                                    <EditCategoryButton 
                                        category={category} 
                                        editCategory={handleEditCategory} 
                                        defaultEventTypes={existingEventTypes}
                                        />
                                </td>
                                <td className='whitespace-nowrap px-4 text-sm text-gray-500 sm:pr-0'>
                                    <DeleteCategoryButton category={category} deleteCategory={handleDeleteCategory} />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </Layout>
  )
}