import React,{useState,useEffect,useContext}  from 'react'
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';

export default function CreateStaffRoleOption({getSelectedRoles}) {

    const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const userId = authState.user;
    const [allRoles,setAllRoles] = useState([]);
    const schoolId = authState.schoolId;

    const [roleOptions,setRoleOptions] = useState([])

    const getAllRoles = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/all-roles`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            const filterRoles = response.data.payload.filter((role)=>{
                return role.roleName !== "student"
            })
            const setUpRoles = filterRoles.map((role,index)=>{
                return {
                    id:role.id,
                    value:role.id,
                    label:role.roleName,
                    bgColor:"bg-sky-500 text-white",selected:false
                }
            })
            setRoleOptions(setUpRoles);
          }
        })
        .catch((e)=>{
          console.log(e);
        })
      }

    const handleRoleSelection = (e)=>{
        e.preventDefault();
        const copyRoleOptions = [...roleOptions]; // copy the role options

        const currentlySelected = copyRoleOptions.find((option)=>option.value === e.target.value).selected === true;
        // if the current role is selected

        if(currentlySelected){  // if the current role is selected
            copyRoleOptions.find((option)=>option.value === e.target.value).selected = false;
            // if the current role is selected and the number of selected roles is greater than 1, deselect the current role    
        } else {
            copyRoleOptions.find((option)=>option.value === e.target.value).selected = true;
            // if the current role is not selected, select the current role
        }
        setRoleOptions(copyRoleOptions);
        getSelectedRoles(e.target.value);   
    }

    useEffect(()=>{
        getAllRoles()
    },[])

  return (
    <>
    <div className='whitespace-nowrap py-2 pr-3 text-sm font-medium text-gray-900 flex space-x-3'>
        <p className='py-1 font-semibold text-sm'>User Role:</p>
        <div className='lg:grid lg:grid-cols-4 '>
        {roleOptions.map((option,index)=>{
            return (
                <button 
                  key={option.id} 
                  onClick={handleRoleSelection} 
                  value={option.value} 
                  className={`transition ease delay-100 
                    relative inline-flex justify-center items-center ${index === 0?"rounded-l-md":index === roleOptions.length-1?"rounded-r-md":null} px-2 py-1 my-0 h-fit text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-sky-300 focus:z-10 ${option.selected?option.bgColor:""}`}>
                    {option.label}
                  </button>
                )
            })
        }
        </div>

    </div>
    </>

  )
}
