import React,{useContext} from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
  XMarkIcon,NewspaperIcon,ClipboardDocumentCheckIcon,CalendarIcon,StarIcon,BuildingLibraryIcon,SparklesIcon,UserGroupIcon,UsersIcon,UserIcon,ArchiveBoxIcon
} from '@heroicons/react/24/outline';

import { AuthenticationContext } from '../../../services/AuthContextModule';

export default function SchoolStaffSidebar({sidebarOpen, setSidebarOpen}) {

  const {authState} = useContext(AuthenticationContext);
  const userRole = authState.role;

  const roleIsCounselor = userRole.includes("counselor");
  const roleIsAdmin = userRole.includes("administrator");
  const roleIsTeacher = userRole.includes("teacher");

  return (
   <>
  <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed inset-0 bg-gray-900/80" />
      </Transition.Child>
      <div className="fixed inset-0 flex">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            {/* Phone Screen Side Bar*/}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-600 px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <img className="h-8 w-auto"
                  src="/logos/appLogo.png"
                  alt="Schoolley"
                />
                <p className='font-semibold text-white mx-2'>SCHOOLLEY</p>

                <NavLink to="/" >
                  <div className='text-white fw-bold'>UniVisits</div>
                </NavLink>
              </div>
              <div className="text-xs font-semibold leading-6 text-indigo-200">Menu</div>
              <ul role="list" className="flex flex-1 flex-col gap-y-1">
                <li>
                  <NavLink to={`/school/events`} className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                    <NewspaperIcon className="w-6 h-6"/>
                    <p>School Events</p>
                  </NavLink>
                </li>
              {
                roleIsCounselor && (
                  <>
                  <li>
                  <NavLink to="/school/counselor/events" className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                    <SparklesIcon className="w-6 h-6"/>
                    <p>UCC Events</p>
                  </NavLink>
                </li>
                
                <li>
                  <NavLink to='/school/counselor/universities' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                    <BuildingLibraryIcon className="w-6 h-6"/>
                    <p>Universities</p>
                  </NavLink>
                </li>
                </>
                )}

                <li>
                  <NavLink to='/school/students' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                    <UsersIcon className="w-6 h-6"/>
                      <p>Students</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to='/school/calendar' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                    <CalendarIcon className="w-6 h-6"/>
                      <p>School Calendar</p>
                  </NavLink>
                </li>
                {
                roleIsAdmin && (
                  <>
                    <li>
                      <NavLink to='/school/administrator/staff' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <UserGroupIcon className="w-6 h-6"/>
                          <p>Staff</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to='/school/administrator/archive' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <ArchiveBoxIcon className="w-6 h-6"/>
                        <p>Archive</p>
                      </NavLink>
                    </li>
                  </>
                  ) }
              </ul>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>

{/* ---------mStatic sidebar for desktop ------------ */}

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col shadow-sm">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-600 px-6 pb-4">
            <Link to="/" >
              <div className="flex h-16 shrink-0 items-center flex">
                <img className="h-8 w-auto"
                  src="/logos/appLogo.png"
                  alt="Schoolley" />
                <p className='font-semibold text-white mx-2'>SCHOOLLEY</p>
              </div>
              </Link>
            <div className="text-xs font-semibold leading-6 text-indigo-200">Menu</div>
              <ul role="list" className="mt-2 space-y-1">
                <li>
                  <NavLink to={`/school/events`} className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                    <NewspaperIcon className="w-6 h-6"/>
                    <p>School Events</p>
                  </NavLink>
                </li>
               
                {
                  roleIsCounselor && (
                    <>
                    <li>
                      <NavLink to="/school/counselor/events" 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                        <SparklesIcon className="w-6 h-6"/>
                        <p>UCC Events</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to='/school/counselor/universities' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                       <BuildingLibraryIcon className="w-6 h-6"/>
                        <p>Universities</p>
                      </NavLink>
                    </li>
                    </>
                    )}

                    <li>
                      <NavLink to='/school/students' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold `}>
                        <UsersIcon className="w-6 h-6"/>
                        <p>Students</p>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to='/school/calendar' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <CalendarIcon className="w-6 h-6"/>
                        <p>School Calendar</p>
                      </NavLink>
                    </li>
                    {
                      roleIsAdmin && (
                      <>
                        <li>
                          <NavLink to='/school/administrator/staff' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                            <UserGroupIcon className="w-6 h-6"/>
                            <p>Staff</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to='/school/administrator/archive' className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-gray-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                            <ArchiveBoxIcon className="w-6 h-6"/>
                            <p>Archive</p>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
              </ul>
          </div>
      </div>
   </>
  )
}
