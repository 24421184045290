import React from 'react';
import moment from 'moment';

export default function EventCalendarIcon({date}) {
  const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const month = months[moment(date).month()];

  return (
    <div className='rounded text-center w-16'>
        <div className='rounded-t bg-red-500 text-white font-semibold px-2 py-0 text-lg'>{month}</div>
        <div className='bg-white px-2 py-1 rounded-b border-2 text-2xl font-semibold'>{moment(date).format('D')}</div>
    </div>
  )
}
