import React, { useEffect,useContext, useState, useRef} from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SchoolNewEventFormContext, SchoolNewEventFormDispatchContext } from '../providers/SchoolEventFormContext';
import { SchoolNewEventFormRequiredContext, SchoolNewEventFormRequiredDispatchContext } from '../providers/SchoolEventFormValidationContext';
import moment from 'moment';



export default function SchoolEventEndDatePicker() {
    const eventFormState = useContext(SchoolNewEventFormContext);
    const [startDate, setStartDate] = useState();
    const dispatch = useContext(SchoolNewEventFormDispatchContext);

    const formCheckDispatch = useContext(SchoolNewEventFormRequiredDispatchContext);

  const handleSelectDate = (date)=>{
    setStartDate(date)
    handleContextDateTime(date)
  }
  const handleContextDateTime = (dateTime)=>{
    dispatch({
        type:"dateTimeChanged",
        name:"eventEndTime",
        content: dateTime
    })
    formCheckDispatch({
        type:"submitCheckEmpty",
        currentField: "eventEndTime"
    })
  }
    const handleBlur = () => {
      if (!startDate) {
          setStartDate(moment().add(2, 'hours').toDate());
          handleContextDateTime(moment().add(2, 'hours').toDate());
      }
  };

  const minDate = eventFormState.eventDateTime ? new Date(eventFormState.eventDateTime) : new Date();

  const minTime = new Date();
  minTime.setHours(minDate.getHours());
  minTime.setMinutes(minDate.getMinutes());
  minTime.setSeconds(0);
  minTime.setMilliseconds(0);
  
  const maxTime = new Date(minDate);
  maxTime.setHours(23);
  maxTime.setMinutes(59);
  maxTime.setSeconds(59);
  maxTime.setMilliseconds(999);

  useEffect(() => {
      if (eventFormState.eventEndTime) {
        const endTime = moment(eventFormState.eventEndTime);
        if (endTime.isValid()) {
          setStartDate(endTime.toDate());
        }
      } else if (eventFormState.eventDateTime) {
        const newStartDate = moment(eventFormState.eventDateTime).add(1, 'hours');
        if (newStartDate.isValid()) {
          setStartDate(newStartDate.toDate());
          handleContextDateTime(newStartDate.toDate());
        }
      }
  }, [eventFormState.eventEndTime, eventFormState.eventDateTime]);

  return (
    <>
      <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
       <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            Event End Date Time
      </label>
      <div className='col-span-3'>
        <ReactDatePicker className="px-3 py-1 font-semibold rounded-2xl bg-red-500 text-white"
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                onClick={decreaseMonth}
              >x
                <span
                  className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  } >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
            selected={startDate}
            onChange={(date) => handleSelectDate(date)}
            onBlur={handleBlur}
            monthsShown={1}
            minDate={new Date(eventFormState.eventDateTime)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={10}
            timeCaption="time"
            dateFormat="MMM d, yyyy h:mm aa"
            minTime={minTime} 
            maxTime={maxTime}
        />
      </div>

    </div>
    </>
  )
}
