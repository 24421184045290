import React, { useContext, useEffect, useState,useCallback } from 'react'
import Layout from '../../../../components/Layout';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import { noUnderline } from '../../../../components/componentCSS';
import { Link,useNavigate } from 'react-router-dom';
import CounselorCreateUniversityModal from '../../counselorComponents/Counselor_CreateUniversityModal';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { getCode } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import { BuildingLibraryIcon } from '@heroicons/react/20/solid';

export default function CounselorUniversities() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const navigate = useNavigate();

    const [userInfo,setUserInfo] = useState();
    const [universities, setUniversities] = useState([]);
    const [universitiesTable, setUniversitiesTable] = useState([]);
    const [searchOptions, setSearchOptions] = useState([]);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(30);
    
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [searchValue, setSearchValue] = useState("");

    const getUserInfo = async ()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/profile`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setUserInfo(response.data.payload)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
}

    const getUniversities = async (offset = 0, append = false) => {
        setLoading(true);
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities?offset=${offset}&limit=${limit}`, { withCredentials: true })
        .then((response) => {
            if (response.status === 200) {
                const newUniversities = response.data.payload.rows;
                const updatedUniversities = append ? [...universities, ...newUniversities] : newUniversities;
                setUniversities(updatedUniversities);

                setUniversitiesTable(updatedUniversities);
                setHasMore(newUniversities.length === limit);
                // console.log(updatedUniversities);
            }
        }) 
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const searchUniversities = async (searchQuery) => {
        setLoading(true);
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/universities/search?search=${searchQuery}`, { withCredentials: true })
        .then((response) => {
            if (response.status === 200) {
                const newUniversities = response.data.payload || [];
                // setUniversities(newUniversities);
                setSearchOptions(newUniversities.map(u => ({
                    value: u.schoolName,
                    label: `${u.schoolName}, ${u.city}, ${u.state}, ${u.country}`,
                    id: u.id
                })));
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const debouncedSearch = useCallback(debounce(searchUniversities, 500), []);

    useEffect(()=>{
        document.title = "Schoolley | Universities"
        getUserInfo();
        getUniversities();
    },[])

    const loadMore = () => {
        const newOffset = offset + limit;
        setOffset(newOffset);
        getUniversities(newOffset, true);
    };

    const handleInputChange = (inputValue) => {
        setSearchValue(inputValue);
        debouncedSearch(inputValue);
    };

    const handleSearchChange = (selectedOption) => {
        const searchQuery = selectedOption ? selectedOption.value : '';
        setSearchValue(searchQuery);
        setOffset(0);
        searchUniversities(searchQuery);
        // getUniversities(0, searchQuery);
        if (selectedOption) {
            // console.log(selectedOption)
            navigate(`/school/counselor/universities/${selectedOption.id}`);
        }
    };

  return (
    <Layout>
    <div className='w-full h-full'>
    <div className='bg-opacity-75 lg:mx-10'>
      <div className='font-semibold px-2 '>
        <p className='text-2xl font-bold '> Universities</p>
      </div>
      <div className=' lg:flex align-middle'>
        <div className='flex items-center w-full lg:w-4/5 md:w-4/5 sm:w-4/5 text-sm'>
          <p className='py-2 px-3 font-semibold border-1 text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-600'>Search University</p>
          <Select
            value={{ value: searchValue, label: searchValue }}
            onInputChange={handleInputChange}
            onChange={handleSearchChange}
            options={searchOptions}
            placeholder='School Name, City or Country'
            className='shadow-sm block flex-1 border-0 bg-slate-100 text-gray-900 font-semibold placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-lg'
          />
        </div>
        <div className='sm:mt-0 sm:flex-none lg:w-1/4 lg:flex justify-end lg:ml-2 my-2'>
          <CounselorCreateUniversityModal refreshData={getUniversities} />
        </div>
      </div>
      <div className='rounded-2xl'>
        <table className="min-w-full divide-y divide-gray-300 ">
          <thead className='text-white '>
            <tr className='font-semibold '>
                <th scope="col" className="sticky top-0 bg-indigo-500 rounded-tl-2xl px-3 py-2.5 pl-4 pr-3 text-left text-xs sm:pl-0">Name</th>
                <th scope="col" className="sticky top-0 bg-indigo-500 hidden px-3 py-2.5 text-left text-xs lg:table-cell" > City</th>
                <th scope="col" className="sticky top-0 bg-indigo-500 hidden px-3 py-2.5 text-left text-xs lg:table-cell" > State</th>
                <th scope="col" className="sticky top-0 bg-indigo-500 hidden px-3 py-2.5 text-left text-xs lg:table-cell" >Country</th>
                <th scope="col" className="sticky top-0 bg-indigo-500  px-3 py-2.5 text-left text-xs lg:table-cell xs:rounded-tr-2xl">Website </th>
                {/* <th scope="col" className="ticky top-0 bg-indigo-500 hidden px-3 py-2.5 text-left text-xs lg:table-cell rounded-tr-2xl">Data % </th> */}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white ">
            {universitiesTable.map((university,index)=>{
            return (
                <tr key={university.id} className={`hover:bg-gray-50 `}>
                    <td className={`text-sm pl-4 py-1 font-semibold `}>
                    <div className='flex items-center space-x-2'>
                    {
                        university?.profilePicture? 
                        <img src={university?.profilePicture} className="h-10 w-10 rounded-lg mr-2" />
                        :
                        <div className='h-10 w-10'>
                        <BuildingLibraryIcon />
                        </div>
                    }
                    <Link to={`/school/counselor/universities/${university.id}`} >
                        <p className='text-blue-600'>
                        {university?.schoolName}
                        </p>
                    </Link>
                    </div>
                        <dl className="font-normal lg:hidden">
                        <dt>{university?.state}</dt>
                        <dt>{university?.country}</dt>
                        </dl>
                    </td>
                    <td className='hidden px-3 py-1 text-sm text-gray-500 lg:table-cell'>{university?.city}</td>
                    <td className='hidden px-3 py-1 text-sm text-gray-500 lg:table-cell'>{university?.state}</td>
                    <td className='hidden px-3 py-1 text-sm text-gray-500 lg:table-cell'>
                        <div className='flex items-center'>
                            <p className='mx-2'>{university?.country}</p>
                            <ReactCountryFlag countryCode={getCode(university.country)} />
                        </div>
                    </td>
                    <td className={`text-sm px-3 `}><a href={university?.website} target="_blank" style={noUnderline}>{university?.website}</a>
                    </td>
                    {/* <td className="hidden px-3 py-1 text-sm text-gray-500 lg:table-cell">{university?.universityDataPercentage?.completionPercentage}</td> */}
                </tr>
                    )
                })
            }
          </tbody>
        </table>
          {hasMore && !loading && (
            <button onClick={loadMore} className="w-full text-blue-500 font-semibold flex justify-center py-2 hover:bg-slate-300 bg-slate-200 rounded-b-2xl ">
                    Show more
            </button>
            )}
            {loading && (
                <div className="flex justify-center py-4">
                    <p>Loading...</p>
                </div>
            )}
      </div>
    </div>
    </div>
    </Layout>
  )
}
