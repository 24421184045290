import React,{useState,useEffect,useContext} from 'react';
import { Link} from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import Layout from '../../../components/Layout';

export default function AdministratorArchivedStudents() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;

    const [students,setStudents] = useState([]);
    const [searchValue,setSearchValue] = useState("");
    const [studentsTable,setStudentsTable] = useState([]);

    const handleSetStudentsTable = (studentsData)=>{
        setStudentsTable(studentsData)
      }
    
    const handleSearch =(e)=>{
        setSearchValue(e.target.value);
    
        let filteredList = students.filter((student)=>{
          const searchKeys = ["firstName","lastName","email"];
          return searchKeys.some((key)=>{
            if(typeof student[key] ==="string" && student[key].toLowerCase().includes(e.target.value.toLocaleLowerCase())){
              return student
            }
          })
        });
    
        handleSetStudentsTable(filteredList);
    }

    const getArchivedStudents = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/counselor/students/archived`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setStudents(response.data.payload);
                handleSetStudentsTable(response.data.payload);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        document.title = "Archived Students";
        getArchivedStudents();
    }
    ,[])

  return (
    <Layout>
    <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl bg-white p-3'>

        <div className='font-semibold p-2 '>
            <p className='text-xl font-bold mb-2'>Archived Students</p>
        </div>
        <div className='my-2 py-2  flex align-middle'>
            <div className='flex items-center w-full lg:w-3/5 md:w-4/5 sm:w-4/5'>
            <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Student</p>
            <input 
            value={searchValue}
            onChange={handleSearch}
            placeholder='find student' 
            className='shadow-sm block flex-1 border-0 bg-slate-100 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
            </div>
        </div>
            
        <div className="mt-4 flow-root hover:shadow hover:rounded-2xl">
            <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-8 ">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                    <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl ">
                        <table className='min-w-full divide-y divide-gray-700 rounded-2xl' >
                        <thead className='bg-indigo-500 text-white text-sm'>
                            <tr className='text-center'>
                            <th scope="col"  className="py-2 px-3 font-semibold sm:pl-6 w-48">Name</th>
                            <th scope="col" className="px-3 py-2 font-semibold w-48">Email</th>
                            <th scope="col"  className="px-3 py-2 font-semibold w-44">School</th>
                            <th scope="col" className="px-3 py-2 font-semibold w-44">Grade</th>
                            <th scope="col"  className="px-3 py-2 font-semibold w-44">Activated</th>
                            </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-300 '>
                            {
                            studentsTable.map((student)=>{
                                return (
                                <tr key={student.id} className='hover:bg-gray-100 text-center' >
                                    <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm text-blue-600 font-semibold'>
                                    <Link to={`/counselor/students/${student.id}/archived`} >
                                    {student.firstName} {student.lastName}
                                    </Link>
                                    </td>
                                    <td className='whitespace-nowrap px-3 text-sm'>{student.email}</td>
                                    <td className='text-sm whitespace-nowrap px-3'>
                                    {student.school?.schoolName}
                                    </td>
                                    <td className='text-sm whitespace-nowrap px-3'>
                                    {student.gradeLevel}
                                    </td>
                                    <td className='text-sm whitespace-nowrap px-3'>{student.activated?"Active":"Inactive"}</td>
                                </tr>
                                )})
                            }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </Layout>
  )
}
