import React from "react";
import { Outlet } from "react-router-dom";
import useAuthenticationContext from "../hooks/authStateHook";
import UserPendingActivationSetup from "../pages/UserPendingActivation";
import UserArchiveWarning from "../pages/UserArchivedWarning";

export default function ArchiveCheck(){

    const {authState} = useAuthenticationContext();
    
    return (
        authState.isArchived === false ? <Outlet/> : <UserArchiveWarning/>
    )
}