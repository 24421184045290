import React, { useEffect } from 'react';
import UnAuthLayout from './components/UnAuthLayout';
import { Link } from 'react-router-dom';

export default function CoppaTerms() {
    useEffect(() => {
        document.title = "Schoolley | COPPA Disclosure";
      }, []);
  return (
    <UnAuthLayout>
    <div className="md:w-full w-full">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg mt-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4 text-center">COPPA Disclosure</h1>
            <p className="text-sm text-gray-600 mb-2"><strong>Last Updated:</strong> 2024-09-01</p>

            {/* Contact Information Section */}
            <div>
                <h2 className="text-xl font-semibold mt-4">Contact Information</h2>
                <p>Schoolley Inc.<br />
                [Your Address, e.g., 1234 Education Ave.]<br />
                [City, State, Zip Code, e.g., San Francisco, CA 94104 USA]<br />
                Email: <a href="mailto:legal@schoolley.com" className="text-blue-500 hover:underline">legal@schoolley.com</a><br />
                Telephone: <a href="tel:+18001234567" className="text-blue-500 hover:underline">+1 (800) 123-4567</a><br />
                Fax: <a href="fax:+18007654321" className="text-blue-500 hover:underline">+1 (800) 765-4321</a>
                </p>
            </div>

            {/* Purpose Section */}
            <div>
                <h2 className="text-xl font-semibold mt-4">Purpose</h2>
                <p>This COPPA Compliance Policy is designed to afford children under the age of 13 the protections mandated by the Child Online Privacy Protection Act ("COPPA"). This policy applies only to users under the age of 13 and complements our general Privacy Policy, which is available at <a href="https://www.schoolley.com/privacy-policy/" className="text-blue-500 hover:underline">https://www.schoolley.com/privacy-policy/</a>.</p>
            </div>

            {/* Information Collected Section */}
            <div>
                <h2 className="text-xl font-semibold mt-4">Information Collected</h2>
                <p>We respect the privacy of children and collect only the personal information necessary to enable them to participate in the activities offered on our Website. We collect the following types of personal information directly from children online:
                    <ul className="list-disc pl-6">
                        <li>E-mail address</li>
                        <li>First and last name</li>
                        <li>Identifiers such as cookies that may uniquely identify their device</li>
                    </ul>
                </p>
            </div>

            {/* Use and Sharing of Information Section */}
            <div>
                <h2 className="text-xl font-semibold mt-4">Use and Sharing of Information</h2>
                <p>The information we collect from children is used:
                    <ul className="list-disc pl-6">
                        <li>For record keeping</li>
                        <li>To enable them to participate in certain functions of our site, such as use of chat and messaging functions between students and between teachers and students.</li>
                    </ul>
                    We do not share children’s personal information with anyone other than those who provide support for the internal operations of the website, and our agents who have agreed to maintain the security and integrity of personal information.
                </p>
            </div>

            <div>
                <h2 className="text-xl font-semibold mt-4">Contact Us</h2>
                <p>If you have any questions or concerns about our COPPA Disclosure, please contact us at:
                    <br />Email: <span className='font-semibold text-blue-500'>contact@schoolley.com</span><br/>
                    Website: <a href="https://schoolley.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">https://www.schoolley.com</a>
                </p>
            </div>
        </div>
    </div>
    </UnAuthLayout>
  )
}
