import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';

export default function FavoriteButtonSM({universityId,refreshFavorites}) {

    const [favorite,setFavorite] = useState(false);
    const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    // const {universityId} = useParams();
    const userId = authState.user;
    const manageFavorite = async()=>{
        await useAxiosWithInterceptor.post(`/api/user/${userId}/student/favorites/${universityId}`)
        .then((response)=>{
            if(response.status === 200){
                // turn the button to orange to solid, change text
                setFavorite(response.data.payload);
                refreshFavorites();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return (
        <>
        <button onClick={manageFavorite}>
            <div className='flex'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24" strokeWidth={1.5} stroke="none" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            </div>
        </button>
        </>
    )
}
