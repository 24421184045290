import React, { useContext, useState, Fragment, useRef, useEffect} from 'react';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { Dialog, Transition } from '@headlessui/react';
import { useBanner } from '../../../services/SystemBannerMessage';

export default function DeActivateSubscription({schoolId,subscriptionId,refreshSubscriptions}) {
    const [open, setOpen] = useState(false)
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const {showBanner} = useBanner();

    const handleOpen = ()=>{
        setOpen(true);
    }

    const handleClose = ()=>{
        setOpen(false);
    }

    const handleActivation = async()=>{
        await useAxiosWithInterceptor.put(`api/super-admin/schools/${schoolId}/subscriptions/${subscriptionId}/deactivate`,{},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                refreshSubscriptions();
                handleClose();
                showBanner("the Subscription has been De-activated","bg-green-400")
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }


  return (
    <>
    <button 
      onClick={handleOpen}
      className='transition delay-50 bg-red-500 rounded-2xl shadow-sm text-white px-3 py-1 hover:bg-red-600'
      >
      DeActivate
    </button>
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={handleClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10  overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 sm:w-full h-[480px] ">
                <div>
                  <div className="mt-1 text-center sm:mt-5 ">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      <p className='text-center text-2xl text-red-500'>Suspend Subscription</p>
                    </Dialog.Title>
                  </div>
                </div>
                <div className='mt-4 w-96'>
                  <p className='my-3 font-semibold text-blue-500 text-wrap'>This will Deactivate the subscription, and might block access to the organization if it's current subscription year.</p>
                </div>
                <div className="mt-5 sm:mt-6 flex space-x-5">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline"
                    onClick={handleActivation}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline "
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  )
}
