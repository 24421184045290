import React, { useEffect,useContext, useState, useRef} from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SchoolEditEventFormContext, SchoolEditEventFormDispatchContext } from '../providers/SchoolEditEventFormContext';
import { SchoolEditEventFormValidationContext, SchoolEditEventFormValidationDispatchContext } from '../providers/SchoolEditEventFormValidationContext';
import moment from 'moment';

export default function SchoolEditEventEndDatePicker() {

    const eventFormState = useContext(SchoolEditEventFormContext);
    const dispatch = useContext(SchoolEditEventFormDispatchContext);

    const formCheckDispatch = useContext(SchoolEditEventFormValidationDispatchContext);

    const [startDate, setStartDate] = useState();
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date());

    const handleSelectDate = (dateTime)=>{
        let formattedDate = moment(dateTime).format();
        setStartDate(dateTime)
        handleContextDateTime(formattedDate)
      }
    
    const handleContextDateTime = (dateTime)=>{
        dispatch({
            type:"dateTimeChanged",
            name:"eventEndTime",
            content: dateTime
        })
    }

    const handleBlur = () => {
        if (!startDate) {
            setStartDate(moment().add(2, 'hours').toDate());
            handleContextDateTime(moment().add(2, 'hours').toDate());
        }
    }; 

    useEffect(() => {
        if (eventFormState.eventDateTime) {
            const minDate = new Date(eventFormState.eventDateTime);
            setMinTime(new Date(minDate));
            setMaxTime(new Date(minDate).setHours(23, 59, 59, 999));
        } 
    }, [eventFormState.eventDateTime]);

    useEffect(() => {
        if (eventFormState.eventEndTime) {
            
            setStartDate(new Date(eventFormState.eventEndTime));
        }
    }, [eventFormState.eventEndTime]);

    useEffect(()=>{

    },[])

  return (
    <>
    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 ">
       <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            Event Date Time
        </label>
      <div className='col-span-3'>
        <ReactDatePicker className='px-3 py-1 font-semibold text-sky-800 rounded bg-red-500 text-white'
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div>
                <button
                  aria-label="Previous Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--previous"
                  }
                  style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                  onClick={decreaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                    }
                  >
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    "react-datepicker__navigation react-datepicker__navigation--next"
                  }
                  style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                    }
                  >
                    {">"}
                  </span>
                </button>
              </div>
            )}
            selected={startDate}
            onChange={(date) => handleSelectDate(date)}
            monthsShown={2}
            minDate={new Date(eventFormState.eventDateTime)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={10}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            minTime={minTime} 
            maxTime={maxTime}
          />
      </div>
    </div>
    </>
  )
}
