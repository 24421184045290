import React,{ createContext, useContext, useReducer, useState } from "react";
import { SchoolNewEventFormContext } from "./SchoolEventFormContext";

export const SchoolNewEventFormRequiredContext = createContext(null);
export const SchoolNewEventFormRequiredDispatchContext = createContext(null);

export default function SchoolNewEventFormValidationProvider({children}) {
    const eventForm = useContext(SchoolNewEventFormContext);

    const [requiredFieldsState, dispatch] = useReducer((state,action)=> eventFormValidationReducer(state,action, eventForm), initialFormValidationState);

    return (
        <SchoolNewEventFormRequiredContext.Provider value={requiredFieldsState}>
            <SchoolNewEventFormRequiredDispatchContext.Provider value={dispatch}>
                {children}
            </SchoolNewEventFormRequiredDispatchContext.Provider>
        </SchoolNewEventFormRequiredContext.Provider>
    )
}

export function eventFormValidationReducer(eventFormState, action, newEventForm){
    switch(action.type){
        case "submitCheckEmpty":{
            let eventFormStateCopy = {...eventFormState};

            if(!newEventForm[action.currentField].length >0 || newEventForm[action.currentField] === null || newEventForm[action.currentField] === undefined){
                eventFormStateCopy[action.currentField] = false;
            }else{
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        case "inputLevelCheckEmpty": {
            let eventFormStateCopy = {...eventFormState};
            if(newEventForm[action.currentField].length >0){
                
                eventFormStateCopy[action.currentField] = true;
            }
            return eventFormStateCopy
        }
        default:{
            throw Error('Unknown action '+action.type)
        }
    }
}


let initialFormValidationState = {
    eventName:true,
    remark:true,
    eventType:"",
    location:true,
    eventDateTime:true,
    eventEndTime:true,
    signUpDeadline:true,
    openToAll:false,
    eventCategory_id:"",
    inviteParents:false,
}

