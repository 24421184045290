import React from 'react'
import SkeletonLoader from './SkeletonLoader'

export default function SkeletonTableRow({colNumber,rowNumber}) {
  return (
    <>
    {
        Array(rowNumber).fill().map((_,i)=>(
            <tr key={i}>
                {Array(colNumber).fill().map((_,i)=>(
                    <td 
                        key={i} 
                        className={`w-full text-start max-w-xs py-1 px-3 text-sm  font-semibold text-blue-500 sm:w-auto sm:max-w-none sm:pl-0`}>
                    <SkeletonLoader height={8} style={"bg-slate-100"} />
                    </td>
                ))}
            </tr>
        ))
    }
  </>
  )
}
