import React from 'react';
import UnAuthLayout from './components/UnAuthLayout';
import Layout from './components/Layout';

export default function UserArchiveWarning() {

  return (
    <>
    <Layout>
      <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <p>This account is currently archived, </p>
              <p>please contact your administrator for more information.</p>
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          
          </div>
      </div>
    </Layout>
    </>
  )
}
