import React, { useEffect, useState } from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { useNavigate, Link } from 'react-router-dom';
import Layout from '../../../components/Layout';

export default function SuperAdminPendingTeachers() {

    const [pendingTeachers,setPendingTeachers] = useState([])

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const navigateTo = useNavigate();

    const handleVerification = (userId)=>{
      useAxiosWithInterceptor.put("/api/super-admin/users/verify/teacher",{userId:userId},{withCredentials:true})
          .then((response)=>{
              if(response.status === 200){
                  getPendingCounselors()
              }   
          })
          .catch((error)=>{
              console.log(error)
          })
      }
  
    const getPendingCounselors = async ()=>{
      await useAxiosWithInterceptor.get("/api/super-admin/users/pending/counselors",{withCredentials:true})
          .then((response)=>{
              if(response.status === 200){
                setPendingTeachers(response.data.payload)
              }else if(response.status === 403){
                  navigateTo(-1)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    useEffect(()=>{
      getPendingCounselors();
    },[])

  return (
    <>
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
          <p className='text-3xl font-bold py-1 px-2'>Pending</p>
          <div className='my-2 py-1 flex align-middle'>
              <div className='flex items-center w-full lg:w-3/5 md:w-4/5 sm:w-4/5'>
                <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Counselor</p>
              <input 
                // value={searchValue}
                // onChange={handleSearch}
                placeholder='find counselors' 
                className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
              </div>
          </div>

          <div className="flow-root hover:shadow hover:rounded-2xl">
            <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-8 ">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl ">
                    <table className='min-w-full divide-y divide-gray-700 rounded-2xl' >

                      <thead className='bg-green-500 text-white'>
                        <tr className='text-center text-sm'>
                          <th scope="col"  className="px-3 py-2 font-semibold sm:pl-6 w-48">Name</th>
                          <th scope="col" className="px-3 py-2 font-semibold w-48">Email</th>
                          <th scope="col"  className="px-3 py-2 font-semibold w-44">School</th>
                          <th scope="col"  className="px-3 py-2 font-semibold w-44">Role</th>
                          <th scope="col" className="px-3 py-2 font-semibold w-44">Status</th>
                          <th scope="col"  className="px-3 py-2 font-semibold w-44">Verified</th>
                        </tr>
                      </thead>

                      <tbody className='divide-y divide-gray-300'>
                        {
                          pendingTeachers.map((teacher)=>{
                            return (
                              <tr key={teacher.id} className='hover:bg-gray-100 text-center bg-white' >
                                <td className='whitespace-nowrap py-2 pl-4 pr-3 text-xs text-blue-600 font-semibold '>
                                <Link to={`/admin/teachers/${teacher.id}`} >
                                {teacher.firstName} {teacher.lastName}
                                </Link>
                                </td>
                                <td className='whitespace-nowrap px-3 text-xs'>{teacher.email}</td>
                                <td className='text-sm whitespace-nowrap px-3'>
                                  {teacher.school?.schoolName}
                                </td>
                                <td className='text-sm whitespace-nowrap px-3'>
                                  {teacher?.role}
                                </td>
                                <td className={`text-xs font-semibold ${teacher.verified?"text-green-600":"text-amber-500"}`}>{teacher.verified?"Verified":"Not Verified"}
                                </td>
                                <td className='text-xs whitespace-nowrap px-3'>
                                  <button
                                    onClick={()=>handleVerification(teacher.id)}
                                    className='px-3 py-1 border-2 border-green-600 rounded-lg text-xs my-1 hover:bg-green-600 hover:text-white font-semibold'>Verify</button>
                                </td>
                              </tr>
                            )})
                        }
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </Layout>
    </>
  )
}
