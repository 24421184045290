import React,{useState,useEffect,useContext}  from 'react'
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';

export default function StaffArchiveOptionBar({staffId,refreshStaff}) {
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const useAxiosWithInterceptor = axiosWithInterceptor();

  const handleArchiveUser = async()=>{
    await useAxiosWithInterceptor.put(`/api/super-admin/schools/${schoolId}/staff/${staffId}/archive`,{staffId},{withCredentials:true})
    .then((response)=>{
        if(response.status === 200){
            refreshStaff();
        }
    } )
    .catch((error)=>{
        console.log(error)
    })
  }
  const handleUnArchiveUser = async()=>{
    await useAxiosWithInterceptor.put(`/api/super-admin/schools/${schoolId}/staff/${staffId}/unarchive`,{staffId},{withCredentials:true})
    .then((response)=>{
        if(response.status === 200){
            refreshStaff();
        }
    } )
    .catch((error)=>{
        console.log(error)
    })
  }

  return (
    <>
        <button 
          onClick={handleArchiveUser}
          className={`transition ease delay-100 relative inline-flex items-center px-3 py-1 my-0 text-sm font-semibold bg-red-500 text-white hover:bg-sky-300 focus:z-10 rounded-l-lg shadow-sm`}>Archive</button>
        <button 
          onClick={handleUnArchiveUser}
          className={`transition ease delay-100 relative inline-flex items-center px-3 py-1 my-0 text-sm font-semibold bg-green-600 text-white hover:bg-green-500 focus:z-10 rounded-r-lg shadow-sm`}>Un-Archive</button>
    </>
  )
}
