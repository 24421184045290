import React,{useEffect,useState,useContext} from 'react';
import Layout from '../../components/Layout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import Calendar from '../../components/Calendar';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import { useNavigate } from 'react-router-dom';
import CalendarModal from '../../components/CalendarModal';


export default function SchoolAllCalendar() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const navigate = useNavigate(); // Use useNavigate hook

  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [collegeVisits, setCollegeVisits]= useState([]);
  const [events, setEvents] = useState([]);
  const [allSchoolEvents, setAllSchoolEvents] = useState([]);

  const [formattedEvents, setFormattedEvents] = useState([]);
  const handleFormatEvent = (events)=>{
    let tempFormattedEvents = [];
    events.forEach((event)=>{
        let newFormat = {
            title: event.eventName,
            start: new Date(event.eventDateTime).toISOString(),
            url: `/school/events/${event.id}`
        }
        tempFormattedEvents.push(newFormat);
    });
    setFormattedEvents(tempFormattedEvents);
}

  const getAllEvents = async()=>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/events`,{withCredentials:true})
      .then((response)=>{
          if(response.status === 200){
              setEvents(response.data.payload);
              handleFormatEvent(response.data.payload)
          }
      })
      .catch((error)=>{
          console.log("something wrong ")
          console.log(error)
      })
  }

  useEffect(()=>{
    document.title = "Schoolley | Calendar"
    getAllEvents();
},[]);
  return (
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
          {/* <Calendar allEvents={allSchoolEvents}/> */}
          <FullCalendar 
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={formattedEvents}
            eventContent={renderEventContent}
            
            // eventClick={(info)=>{
            //   navigate(info.event.url); 
            // }}
            // eventDisplay='block'
          />
        </div>
      </div>
    </Layout>
  )
}

function renderEventContent(eventInfo) {
  return (
    <>
        <CalendarModal item={eventInfo.event} />
    </>
  )
}