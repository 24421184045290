import React, { useEffect, useState } from 'react'

export default function ImportanceOptions({name,initialValue,handleFormChange}) {

    const [options,setOptions] = useState(
        [
            {id:"option1",value:"Very Important", label:"Very Important",selected:false,style:"bg-red-500 text-white"},
            {id:"option2",value:"Important", label:"Important",selected:false,style:"bg-blue-500 text-white"},
            {id:"option3",value:"Considered", label:"Considered",selected:false,style:"bg-green-500 text-white"},
            {id:"option4",value:"Not Considered", label:"Not Considered",selected:false,style:"ring-2 ring-slate-500"},
        ]
    )

    const handleSelected = (optionId)=>{
        const optionsCopy = [...options];
        optionsCopy.forEach((option)=>{
            if(optionId === option.id){
                option.selected = true;
            }else{
                option.selected = false;
            }
        });
        setOptions(optionsCopy)

        let selectedOption = optionsCopy.find(option => option.selected).value;

        handleFormChange({target:{name:name,value:optionsCopy.find(option => option.selected).value}})
    }

    useEffect(()=>{
        let copyOptions = [...options];
        copyOptions.forEach((option)=>{
            if(option.value === initialValue){
                option.selected = true;
            }
        })
        setOptions(copyOptions);
    },[initialValue])

  return (
    <>
    <div className='grid grid-cols-4 mx-0 justify-center items-center'>
      {options.map((option,index)=>{
        return(
            <button 
            key={option.id} 
            onClick={()=>handleSelected(option.id)} 
            className={`transition delay-50 cursor-pointer text-sm py-1 ${index===0? "rounded-l-lg":""} ${index === options.length-1?"rounded-r-lg":""} ${option.selected ? option.style : 'bg-gray-200 text-black'} hover:bg-indigo-200 `}>
            {option.label}
            </button>
            )
        })
    }
    </div>
    </>
  )
}
