import React, { useEffect,useContext, useState, useRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { SchoolNewEventFormContext, SchoolNewEventFormDispatchContext } from '../providers/SchoolEventFormContext';
import { SchoolNewEventFormRequiredContext, SchoolNewEventFormRequiredDispatchContext } from '../providers/SchoolEventFormValidationContext';
import { Switch } from '@headlessui/react'

export default function SchoolEventOpenToAll() {
    const [enabled, setEnabled] = useState(null);

    const eventFormState = useContext(SchoolNewEventFormContext);
    const dispatch = useContext(SchoolNewEventFormDispatchContext);
    const requiredFields = useContext(SchoolNewEventFormRequiredContext);
    const formCheckDispatch = useContext(SchoolNewEventFormRequiredDispatchContext);

    const handleToggle = ()=>{
        setEnabled(!enabled)
        handleInputChange(!enabled)
      }

      const handleInputChange = ()=>{
        dispatch({
            type:"toggleOpenToAll",
            name:"openToAll",
            content:enabled
          })
      }

      useEffect(()=>{
        if(eventFormState.openToAll){
            setEnabled(true)
        }
      })
  return (
    <>
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2 ">
      <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
        Open To All / Allow to Join
      </label>
      <div className='px-3 py-1 font-semibold rounded flex space-x-5'>
        <Switch
        checked={enabled}
        onChange={handleToggle}
        className={classNames(
            enabled ? 'bg-green-600 focus:ring-2 focus:ring-green-600 focus:ring-offset-2' : 'bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none '
        )}
        >
        <span className="sr-only">Use setting</span>
        <span
            aria-hidden="true"
            className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
        </Switch>
            <p className={`font-semibold text-md transition duration-100 ${enabled?"text-green-600":"text-red-500"}`}>{enabled?"YES":"NO"}</p>
      </div>
      
    </div>
    </>
  )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }